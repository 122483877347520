@import '../global-variables.scss';

/*-------------------------------------------------------------
  Fonts
-------------------------------------------------------------*/

/* PRINT FONTS */
.font-roboto {
  font-family: 'Roboto' !important;
}

.font-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-abril {
  font-family: 'Abril Fatface', cursive;
}

.font-gravitas {
  font-family: 'Gravitas One', cursive;
}

.font-old-standard {
  font-family: 'Old Standard TT', serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-pt-mono {
  font-family: 'PT Mono', monospace;
}

.font-pt-serif {
  font-family: 'PT Serif', serif;
}

.font-ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

.font-vollkorn {
  font-family: 'Vollkorn', serif;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extra-bold {
  font-weight: 800;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs23 {
  font-size: 23px;
}

.fs24 {
  font-size: 24px;
}

.fs25 {
  font-size: 25px;
}

.fs26 {
  font-size: 26px;
}

.fs27 {
  font-size: 27px;
}

.fs28 {
  font-size: 28px;
}

.fs29 {
  font-size: 29px;
}

.fs30 {
  font-size: 30px;
}

.fs34 {
  font-size: 34px;
}

.fs38 {
  font-size: 38px;
}

.fs44 {
  font-size: 44px;
}

.fs45 {
  font-size: 45px;
}

.fs48 {
  font-size: 48px;
}

.fs50 {
  font-size: 50px;
}

.fs76 {
  font-size: 76px;
}

.fs114 {
  font-size: 114px;
}

.lh-15 {
  line-height: 1.5;
}

.lh-14 {
  line-height: 1.4;
}

.lh-13 {
  line-height: 1.3;
}

i,
em,
.italic {
  font-style: italic;
}