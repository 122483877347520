.donation-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    flex: 1;
    margin-top: 60px;
    background: white;

    @media (max-width: 799.98px) {
        flex-direction: column;
    }
    @media (min-width: 800px) {
        padding: 0 2em;
    }
}

.donation-main {
    @media (max-width: 799.98px) {
        padding: 2em;
    }
    @media (min-width: 800px) {
        padding: 3em;
    }
}

.donation-content__message {
    h1 {
        font-size: 2em;
        margin: 0 0 1em;
        letter-spacing: -0.01em;
    }

    .skip-to-donate {
        display: inline-block;
        background: rgb(0, 100, 255);
        color: white;
        margin: 0 0 1.5em;

        &:hover {
            filter: brightness(1.15);
        }

        @media (min-width: 800px) {
            display: none;
        }
    }

    p {
        font-size: 1.1em;
        line-height: 1.5;
        font-weight: 300;
        color: #212529;
        margin: 0 0 1em;
    }

    img {
        height: auto;
    }

    .img__right {
        float: right;
        margin: 0 0 1em 1em;
        width: 50%;
    }

    .img__left {
        float: left;
        margin: 0 1em 1em 0;
        width: 30%;
    }
}

.donation-aside {
    flex: none;
    position: relative;

    @media (max-width: 799.98px) {
        width: 100%;
        padding: 0 2em 2em;
    }
    @media (min-width: 800px) {
        width: 300px;
        padding: 3em 0;
    }
}

@media (min-width: 800px) {
    .widget-sticky {
        position: fixed;
        top: 10px;
        width: inherit;
    }
}

.dbtn {
    border-radius: 50px;
    line-height: 44px;
    height: 44px;
    font-weight: 500;
    text-align: center;
    border: none;
    font-size: 1em;
    padding: 0 1.5em;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover {
        transition: all 0.2s ease-out;
        filter: brightness(1.15);
    }
}

.div-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#donations {
    h2 {
        margin: 0 0 1em;
        font-size: 1.2em;
    }
}

.tabs {
    box-shadow: 0 0 0 1px #0003, 0 5px 10px #0003;
    border-radius: 4px;
    overflow: hidden;

    .tabs__title-wrapper {
        display: flex;
        position: relative;
        margin-bottom: -1px;

        .tabs__title {
            white-space: nowrap;
            cursor: pointer;
            padding: 0 1em;
            margin: 0;
            height: 50px;
            font-size: 1em;
            position: relative;
            z-index: 1;
            border: none;
            background: transparent;
            outline: none;
            color: rgba(0, 0, 0, 0.6);
            flex: 1;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), transparent);
        }
    }

    .tabs__content-wrapper {
        padding: 16px 0;
        border-top: 1px solid #ccc;

        > div {
            &:first-child {
                display: block;
            }
        }

        .tabs__content {
            display: none;
            padding: 0.5em 1.5em;

            ul {
                margin: 0 0 1em;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style: none;
                    width: 50%;
                    height: 35px;
                    display: flex;
                    align-items: center;

                    input[type='radio'] {
                        margin-right: 0;
                    }

                    input[type='text'],
                    input[type='number'] {
                        width: 100%;
                        margin-left: 5px;
                        padding: 4px 5px;
                        font-size: 0.9em;
                        border: 1px solid gray;
                    }
                }
            }
        }
    }

    .spacer {
        width: 1px;
        background: #ccc;
    }
}

.tabs--light {
    background: white;

    .tabs__title {
        text-align: center;
        &:hover {
            color: rgb(0, 100, 255);
        }

        &.active {
            font-weight: 600;
            color: black;
            background: white;
        }
    }

    .tabs__title--once {
        &.active {
            border-top: 3px solid rgb(0, 100, 255);
        }
    }

    .tabs__title--month {
        &.active {
            border-top: 3px solid rgb(130, 40, 210);
        }
    }

    .tabs__bottom-line {
        background: rgba(0, 0, 0, 0.2);
    }
}

.donations__btn {
    width: 100%;
    margin: 8px 0;
    color: white;
}

.donations__btn--once {
    background: rgb(0, 100, 255);
}

.donations__btn--month {
    background: rgb(130, 40, 210);
}

.tabs__info {
    padding: 1em 1.5em 0.3em;

    h3 {
        margin: 0 0 0.5em;
        font-size: 1em;
        font-weight: 500;
    }

    p {
        margin: 0;
        font-size: 0.8em;
        color: #555;
        font-weight: 300;
        line-height: 1.5;
    }
}

.bold-font {
    font-weight: bold !important;
}

.donate-modal {
    .donate-modal-card {
        border-radius: 0.3125rem;
        background-color: white;
        text-align: center;
        padding: 0;

        .close-btn {
            text-align: right;
            padding: 0;
        }
    }
}
