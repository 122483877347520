@import '../global-variables.scss';

/*-------------------------------------------------------------
  Memories
-------------------------------------------------------------*/

.suggested-memories-section > .container {
    max-width: 73.75rem;
}

.suggested-memories-content {
    width: 100%;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    padding-left: 1.5625rem;
    padding-top: 1.5625rem;
    padding-bottom: 1.875rem;
    padding-right: 0;
    margin-bottom: 1.25rem;
    position: relative;
}

.memories-section-title {
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.36;
    color: #000000;
    margin-bottom: 0;
}

.memories-section-title > span {
    color: #05416a;
    padding-right: 0.625rem;
}

.memories-section-title > span::before {
    color: #1164fb;
}

.memories-link {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.67;
    color: #05416a;
}

.memories-link:hover {
    color: #05416a;
    text-decoration: underline;
}

.memories-link > span {
    padding-left: 0.625rem;
}

.memories-link > span::before {
    color: #1164fb;
}

.empty-my-memories-content {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.5;
    color: #000000;
}

.empty-my-memories-content > span {
    font-weight: 500;
}

.empty-suggested-memories-content {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.5;
    color: #000000;
    padding-right: 2.5rem;
}

.middle-suggested-memories-content {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    color: #000000;
    padding-bottom: 8.5rem;
}

.middle-suggested-memories-img {
    position: absolute;
    top: -2rem;
    height: 31.4375rem;
    right: 2.5rem;
}

.my-memories-section {
    margin-bottom: 1.25rem;
}

.my-memories-section > .container {
    max-width: 73.75rem;
}

.my-memories-content {
    width: 100%;
    height: 21.5rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    padding-left: 1.5625rem;
    padding-top: 1.5625rem;
    padding-bottom: 1.875rem;
    padding-right: 0;
}

.my-map-header,
.suggested-memories-header,
.my-memories-header {
    padding-right: 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.memories-list {
    display: flex;
    overflow: scroll;
    padding-right: 1.5625rem;
}

.memories-list > li {
    width: 21.4375rem;
    height: 15.25rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    display: flex;
    flex-direction: column;
    margin-right: 1.25rem;
}

.memories-card-show {
    width: 21.4375rem;
    height: 11.25rem;
    border-radius: 0.3125rem;
    background-color: #eaeaea;
}

.memories-card-show-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
}

.btn-add-memory {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #05416a;
    position: relative;
    opacity: 0.5;
}

.btn-remove-memory {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ed0f0f;
    opacity: 0.5;
}

.btn-add-memory:hover > span,
.btn-remove-memory:hover > span {
    padding-right: 0.625rem;
}

.btn-add-memory:hover,
.btn-remove-memory:hover {
    width: 12.5rem;
    height: 2.125rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    opacity: 1;
}

.btn-content {
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.88;
    color: #ffffff;
    display: none;
}

.btn-remove-memory:hover > .btn-content,
.btn-add-memory:hover > .btn-content {
    display: flex;
}

.btn-add-memory > span,
.btn-remove-memory > span {
    color: #ffffff;
    font-size: 1.25rem;
}

.memories-card-action {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.memories-card-action > .icon-arrow-right::before {
    color: #1164fb;
}

.memories-card-content-title {
    font-size: 1.125rem;
    font-weight: normal;
    color: #000000;
    line-height: 1.67;
    margin-bottom: 0.3125rem;
}

.memories-card-content-info {
    font-size: 0.9375rem;
    font-weight: 300;
    color: #000000;
}

.my-map-section > .container {
    max-width: 73.75rem;
}

.my-map-content {
    width: 100%;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    padding-left: 0;
    padding-top: 1.5625rem;
    padding-bottom: 0;
    padding-right: 0;
    margin-bottom: 1.25rem;
}

.my-map-header {
    padding-left: 1.5625rem;
}

.btn-back-memories {
    width: 10.875rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    background-color: #00416b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #ffffff;
}

.btn-back-memories > span {
    padding-right: 0.625rem;
    color: #ffffff;
}

.btn-back-memories > span::before {
    color: #ffffff;
}

.my-map-canvas-middle,
.my-map-canvas {
    border-radius: 0.3125rem;
    position: relative;
}

.my-map-canvas > img {
    width: 73.75rem;
    height: 54.4375rem;
}

.my-map-canvas-middle > img {
    width: 73.75rem;
    height: 26.875rem;
    object-fit: cover;
}

.map-photo-tag {
    top: 21.875rem;
    left: 20.1875rem;
    position: absolute;
    width: 4.55rem;
    height: 4.375rem;
    background-color: #05416a;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.1875rem;
}

.map-photo-tag:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 0.4375rem 0.5rem;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
    border-top-color: #05416a;
    bottom: -0.85rem;
}

.map-photo-tag > img {
    width: 4.125rem;
    height: 2.6875rem;
    margin-bottom: 0.25rem;
    border-radius: 0.3125rem;
}

.no-indicator-memory > img {
    width: 100%;
    height: 100%;
}

.no-indicator-memory {
    right: 3.125rem;
    top: 1.25rem;
}

.memory-indicator {
    font-size: 1rem;
    font-weight: normal;
    color: #ffffff;
    margin-bottom: 0;
}

.suggested-memories-list {
    overflow: unset;
    flex-wrap: wrap;
    padding-left: 1.25rem;
    padding-right: 0.5625rem;
}

.suggested-memories-list > li {
    margin-bottom: 1.875rem;
    margin-right: 1.875rem;
}

/* LOCATION LIST */
.location-list-section {
    margin-top: 1.25rem;
}

.location-list-section > .container {
    max-width: 73.75rem;
}

.location-list-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 6.25rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-left: 2.1875rem;
    padding-right: 1.875rem;
}

.location-list-content {
    display: flex;
    align-items: center;
}

.location-list-content > img {
    width: 4.4375rem;
    height: 4.4375rem;
    object-fit: cover;
    margin-right: 1.25rem;
    border-radius: 50%;
}

.location-list-info-title {
    font-size: 1.3125rem;
    font-weight: normal;
    line-height: 1.14;
    color: #000000;
    margin-bottom: 0.3125rem;
}

.location-list-info-title > span {
    padding-right: 0.625rem;
    color: #05416a;
}

.location-list-info-desc {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.19;
    color: #000000;
    margin-bottom: 0;
}

.btn-back-map {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 8.625rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    background-color: #00416b;
}

.btn-back-map > span::before {
    color: #ffffff;
}

.btn-back-map > span {
    padding-right: 0.625rem;
}

.location-gallery-list {
    margin-top: 1.25rem;
    max-width: 73.75rem !important;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.memories-viewer-section {
    display: none;
    position: fixed;
    /* Stay in place */
    z-index: 9999;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(38, 38, 38, 0.9);
    /* Black w/ opacity */
    border-radius: 0.325rem;
}

.memories-viewer-section > .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.memories-fullscreen {
    margin-left: 7.75rem;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    overflow-y: auto;
    max-width: 73.875rem;
    height: 100vh;
}

.memories-gallery-list {
    columns: 2;
}

.memories-fullscreen > img {
    margin-bottom: 1.25rem;
    border-radius: 0.625rem;
    width: 73.625rem;
}

.memories-gallery-list > li {
    width: 36.2188rem;
    margin-bottom: 1.25rem;
    border-radius: 0.625rem;
    position: relative;
}

.memories-gallery-list > li > .btn-memory-position {
    display: none;
}

.memories-gallery-list > li:hover > .btn-memory-position {
    display: flex;
}

.btn-memory-position {
    position: absolute;
    right: 1.25rem !important;
    top: 1.25rem !important;
}

.memories-gallery-list > li > img {
    border-radius: 0.625rem;
}

.memories-fullscreen::-webkit-scrollbar {
    width: 0.3125rem;
    margin-right: 0.7188rem;
}

.memories-fullscreen::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.25rem;
    margin: 0.625rem;
}

.memories-fullscreen::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 0.25rem;
}

.memories-fullscreen::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.75);
}

.memories-sidebar {
    max-width: 31.25rem;
    height: 67.4375rem;
    border: solid 0.0625rem #707070;
    background-color: #ffffff;
    border-radius: 0.625rem 0 0 0.625rem;
    padding-right: 1.5625rem;
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
    overflow: scroll;
}

.memories-sidebar-header-content {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.memories-sidebar-title {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.11;
    color: #000000;
    margin-bottom: 0;
}

.memories-sidebar-subtitle {
    font-size: 0.875rem;
    font-weight: normal;
    color: #000000;
    line-height: 1.11;
    margin-bottom: 0;
}

.memories-map {
    width: 28.125rem;
    height: 12.5rem;
    border-radius: 0.625rem;
}

.memories-desc-section {
    margin-bottom: 1.875rem;
}

.memories-desc-content {
    font-size: 1.0625rem;
    font-weight: normal;
    color: #000000;
}

.memories-location-section,
.memories-people-section {
    margin-bottom: 1.25rem;
}

.memories-people-list {
    display: flex;
}

.memories-people-list > li {
    margin-right: 1.4375rem;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.memories-people-list > li > img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.625rem;
    border-radius: 50%;
}

.memories-people-contact-name {
    font-size: 0.9375rem;
    font-weight: 500;
    margin-bottom: 0;
}

.memories-people-list > li:last-child {
    margin-left: 1.0625rem;
}

.memories-people-list > li > span {
    font-size: 2rem;
    color: #ff6f76;
    position: absolute;
    display: none;
    top: 1.125rem;
    left: 31%;
    z-index: 99;
}

.memories-people-list > li:hover > span {
    display: flex;
}

.memories-people-list > li:hover > span::after {
    content: '';
    width: 4rem;
    height: 4rem;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 50%;
    top: -1.0625rem;
    left: -1.25rem;
    z-index: -1;
}

.memories-people-list > li:hover > img {
    /* opacity: 0.5; */
    background-color: rgba(0, 0, 0, 0.5) #000000;
}

.memories-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.memories-actions-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-remove-memory-2 {
    width: 13.125rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ed0f0f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.btn-add-memory-2 {
    width: 13.125rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #05416a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.btn-remove-memory-2 > span,
.btn-add-memory-2 > span {
    padding-right: 0.625rem;
}

.memories-info-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.3125rem;
}

.memories-info-text {
    font-size: 1.0625rem;
    font-weight: 300;
    line-height: 1.76;
    margin-bottom: 0;
}

.memories-info-content {
    margin-bottom: 1.25rem;
}

.memories-actions-info-content {
    margin-bottom: 1.875rem;
}

.memories-input {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000000;
    padding-bottom: 0.3125rem;
    border-bottom: 0.0625rem solid #000000 !important;
    max-width: 27.5rem;
    width: 100% !important;
}

.memories-input::placeholder {
    font-size: 1.25rem;
    font-weight: bold;
    color: #000000;
}

.memories-input-light {
    opacity: 0.8;
    font-size: 1.125rem;
    font-weight: 300;
    color: #000000;
    padding-bottom: 0.3125rem;
    border-bottom: 0.0625rem solid #000000 !important;
    max-width: 27.5rem;
    width: 100% !important;
}

.memories-input-ligh::placeholder {
    opacity: 0.8;
    font-size: 1.125rem;
    font-weight: 300;
    color: #000000;
}

.btn-remove-memory-gallery {
    width: 16.1875rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #ed0f0f;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.btn-remove-memory-gallery > span {
    padding-right: 0.625rem;
}

.draftjs-content video {
    width: 100%;
}
.draftjs-content img {
    max-width: 100% !important;
    max-height: 100% !important;
}
.checkout-free-modal,
.checkout-paid-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.325rem;
}

.checkout-plans-content {
    margin-top: 20%;
    max-width: 46.875rem;
    width: 100%;
    border-radius: 0.9375rem;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    padding: 2.5rem;
    display: flex;
}

.checkout-plans__form {
    padding-right: 2.5rem;
    border-right: 0.0625rem solid #e9e9e9;
}

.checkout-plans__form-group {
    margin-bottom: 0.625rem;
    position: relative;
}

.checkout-plans__form > h3 {
    font-size: 1.5rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
    margin-bottom: 1.3125rem;
}

.checkout-plans__form-label {
    padding: 0;
    display: flex;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000f32;
    margin-bottom: 0.1875rem;
    padding-left: 0;
}

.checkout-plans__form-input {
    width: 17.5rem !important;
    height: 2.1875rem;
    border-radius: 0.5rem;
    border: solid 0.0625rem rgba(0, 100, 255, 0.5) !important;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
    position: relative;
    padding-left: 0.9375rem;
}

.validation-graphic-valid::before {
    content: '\f058';
    font-family: 'icomoon' !important;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    position: absolute;
    right: 15px;
    z-index: 99;
    bottom: 11px;
    color: #0064ff;
}

.validation-graphic-not-valid::before {
    content: '\e949';
    font-family: 'icomoon' !important;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    position: absolute;
    right: 15px;
    z-index: 99;
    bottom: 11px;
    color: #ff8080;
}

.checkout-plans__form-input .checkout-plans__form-input:focus {
    border: solid 0.0625rem rgba(0, 100, 255, 1);
}

.checkout-plans__form-input::-webkit-input-placeholder,
.checkout-plans__form-input::-moz-placeholder,
.checkout-plans__form-input::placeholder {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
}

.checkout-plans__checkmark {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3125rem;
    opacity: 0.5;
    border: solid 0.0625rem #0064ff;
    position: relative;
}

.checkout-plans__checkmark:checked {
    opacity: 1;
    background-color: #0064ff;
}

.checkout-plans__checkmark:checked::after {
    position: absolute;
    content: '\f00c';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    left: 0.1rem;
}

.checkmark-spacing {
    margin-bottom: 1.25rem !important;
    margin-top: 0.3125rem !important;
}

.checkout-plans__checkmark-info {
    font-size: 0.8125rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
    margin-bottom: 0;
    margin-left: 0.625rem;
    max-width: 13.75rem;
    width: 100%;
    padding: 0;
}

.checkout-plans__checkmark-info > span {
    text-decoration: underline;
}

.btn-checkout-plans {
    width: 17.5rem;
    height: 2.8125rem;
    border-radius: 0.625rem;
    background-color: #0064ff;
    color: #ffffff;
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: 0rem;
    color: #ffffff;
    margin-bottom: 0.9375rem;
}

.btn-checkout-plans.disabled {
    opacity: 0.5;
}

.checkout-plans__information {
    padding-left: 2.5rem;
    width: 100%;
}

.checkout-plans__information-header {
    position: relative;
}

.btn-close-checkout {
    position: absolute;
    font-size: 1.125rem;
    color: #000000;
    top: -15px;
    right: -15px;
}

.btn-close-checkout:hover {
    color: #000000;
}

.checkout-plans__information-header > h3,
.checkout-plans__information > h3 {
    font-size: 1.25rem !important;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
}

.checkout-plans__information-header > h3 > span,
.checkout-plans__information > h3 > span {
    padding-left: 1.5625rem;
    color: #0064ff;
}

.checkout-plans__information-list-title {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000000;
    margin-bottom: 0.6875rem;
}

.checkout-plans__information-list > li {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000000;
    margin-bottom: 0.6875rem;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 1.125rem;
}

.checkout-plans__information-list > li::before {
    content: '\f111';
    position: absolute;
    left: 0;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #0064ff;
    font-size: 0.5rem;
}

.checkout-plans_link {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    color: #0064ff;
}

.checkout-plans__form-group-card-info {
    display: flex;
}

.checkout-plans__form-group-card-info > div:first-child {
    margin-right: 1.875rem;
}

.cvv-input-w {
    width: 4.375rem !important;
}

.expiry-date-input {
    width: 6.25rem !important;
    height: 2.1875rem;
    border-radius: 0.5rem;
    border: solid 0.0625rem rgba(0, 100, 255, 0.5) !important;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expiry-date-input:focus {
    border: solid 0.0625rem rgba(0, 100, 255, 1);
}

.expiry-date-input > input {
    width: 1.875rem;
}

.expiry-date-input > input::-webkit-input-placeholder,
.expiry-date-input > input::-moz-placeholder,
.expiry-date-input > input::placeholder {
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #000f32;
}

.expiry-date-input > span {
    color: #80b2ff;
}

.checkout-plans__order-receipt {
    margin-bottom: 1.25rem;
}

.checkout-plans__order-summary {
    margin-bottom: 0.625rem;
}

.order-title {
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000f32;
    margin-bottom: 0.3125rem;
}

.checkout-package-summary {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0rem;
    color: #0064ff;
}

.order-cost {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000f32;
    margin-bottom: 0.3125rem;
}

.next-payment {
    font-size: 0.8rem;
    font-weight: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: 0rem;
    color: #000f32;
}

.figure-icon {
    color: var(--primary-color);
    position: absolute;
    bottom: 0;
    right: 3px;
    transform: rotate(90deg);
    font-size: 1rem !important;
}

.redirect-login,
.redirect-login:hover {
    color: white;
}

.btn-reset {
    text-transform: uppercase;
    background-color: rgb(224, 224, 224);
    margin-bottom: 1rem;
    color: rgb(166, 166, 166);
    width: 100%;
    cursor: not-allowed;
}
#forgot_pass:hover {
    color: white !important;
}

.cloudsponge-contacts {
    display: none;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: none !important;
}

.loader-section {
    display: flex;
    justify-content: center;
}

.loader {
    z-index: 999999999999999;
    position: absolute;
    width: 10rem;
    top: 30%;
    height: 10rem;
}
.bg-smooth {
    z-index: 999999999;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
}
#audio .figure-action button {
    top: 1.8rem !important;
}
.import-accounts-no-contact {
    line-height: 22px;
}
.c-gray {
    color: gray !important;
}
.post-shared-entry {
    left: 0;
    width: 0;
    height: 0;
    border-top: 60px solid orange;
    border-right: 60px solid transparent;
    top: 0;
    position: absolute;
    z-index: 1;
}

.icon-shared-alt-arrow {
    position: absolute;
    left: 5px;
    top: 12px;
    z-index: 2;
    color: white;
}
.icon-shared-alt-arrow:before {
    font-size: 1.3rem;
}
.annotationLayer {
    display: none !important;
}

.dropdown-toggle {
    cursor: pointer;
}

.c-primary {
    color: var(--primary-color);
}
.bgc-primary {
    background-color: var(--primary-color);
}

.document-file img {
    min-width: auto !important;
}
.draftjs-content figcaption {
    margin-top: 0.625rem;
    margin-bottom: 0.9375rem;
    opacity: 0.8;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #3e3e3e;
    line-height: 1.33;
}
