/* DATA DELETION CARD */
.data-deletion-content {
    padding-bottom: 3.125rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-top: 2.6875rem;
    background: #fffdff;
    box-shadow: 0rem 0.9375rem 0.9375rem #00000017;
    border-radius: 0.3125rem;
    max-width: 59.3125rem;
    width: 100%;
}

.data-deletion-title {
    font-family: 'Cabin';
    font-weight: medium;
    font-size: 1.25rem;
    letter-spacing: 0rem;
    color: #000000;
    margin-bottom: 1.25rem;
}

.data-deletion-text {
    font-size: 1rem;
    line-height: 1.3125rem;
    margin-bottom: 1.25rem;
}

.data-deletion-link {
    text-decoration: underline;
    letter-spacing: 0px;
    font-weight: medium;
    font-size: 1rem;
    color: #1164fb !important;
}
