@import '../global-variables.scss';

/*-------------------------------------------------------------
  Books
-------------------------------------------------------------*/

.book-preview-status {
    position: relative;

    .book-preview-status__title {
        display: flex;
        align-items: center;
        padding: 0 16px 16px;
        width: 100%;

        span {
            font-size: 14px;
            line-height: 1.3;
            flex: auto;
        }

        div:last-child {
            flex: none;
            width: auto;
            position: relative;
        }
    }
}

/*-------------------------------------------------------------
  Book forms
-------------------------------------------------------------*/

.new-book-step {
    display: none;
}

.new-book-step.active-form {
    display: block !important;
}

.new-book-step-2,
.new-book-step-3,
.new-book-step-4,
.new-book-step-5,
.new-book-step-6,
.new-book-step-7,
.new-book-step-8,
.new-book-step-9,
.new-book-step-10,
.new-book-step-11,
.new-book-step-12 {
    display: none;
}

/*-------------------------------------------------------------
  Book preview panel
-------------------------------------------------------------*/

.book-preview-panel {
    width: 100%;
    background-color: $black1;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 0 1px $black2;

    @include x-md {
        flex-direction: column;
    }
    @include md-x {
        max-height: 700px;
    }
}

.book-preview {
    display: flex;
    flex-direction: column;
    flex: auto;
    width: 100%;
    min-height: 700px;

    .book-preview__viewer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: auto;
        padding: 24px;

        .react-pdf__Document {
            .react-pdf__Page {
                display: flex;
                justify-content: center;

                .react-pdf__Page__canvas {
                    box-shadow: 0 2px 6px $black3;
                    width: auto !important;

                    @include ss {
                        height: 300px !important;
                    }
                    @include xs {
                        height: 400px !important;
                    }
                    @include sm {
                        height: 460px !important;
                    }
                    @include md-x {
                        height: 600px !important;
                    }
                }
            }
        }
    }

    .book-preview__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        @include md-x {
            padding-bottom: 16px;
        }

        .panel-pagination {
            .panel-pagination__title {
                padding: 0;
                margin-right: 8px;
            }

            input {
                width: 40px;
                text-align: center;
                border: 1px solid $black3;
                border-radius: 4px;
                padding: 2px 0;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            .panel-pagination__page-total {
                padding: 0;
                margin-left: 4px;
            }
        }

        .panel-arrows {
            display: flex;

            .panel-arrows__btn {
                @extend .brand-gradient;
                border: none;
                outline: none;
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;

                img {
                    width: 16px;
                    height: 16px;
                    filter: var(--filter-white);
                }

                &:hover {
                    filter: var(--hover);
                }
            }
        }
    }
}

/*---------------------------------
  Thumbnails
----------------------------------*/

.book-preview-panel__thumbnails {
    flex: none;
    padding: 0 8px;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        background: $black1;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $black3;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary-color);
    }

    @include x-md {
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;

        &::-webkit-scrollbar {
            height: 6px;
        }
    }
    @include md-x {
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 6px;
        }
    }

    .thumbnail {
        margin: 16px 8px;
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 2px 6px $black3;
        transition: $trans1;

        @include x-md {
            display: inline-block;
        }
        @include md-x {
            width: 122px;
        }

        .thumbnail__img {
            width: auto;
            display: block;

            @include x-sm {
                height: 80px;
            }
            @include sm {
                height: 100px;
            }
        }
    }

    .thumbnail:hover {
        box-shadow: 0 0 0 2px var(--primary-color), 0 2px 6px $black3;
        transition: $trans2;
    }

    .thumbnail.thumbnail-active {
        box-shadow: 0 0 0 3px black, 0 2px 6px $black3;
    }
}

.book-preview-options {
    .book-preview-list {
        padding-left: 0;

        li {
            font-size: 1rem;
            font-weight: 600;
            margin: 8px 0;

            img {
                width: 16px;
                height: 16px;
                filter: var(--filter-green);
                margin-right: 8px;
            }
        }
    }
}

.book-preview-form-group {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;
}

.book-checkmark {
    width: 0.9375rem;
    height: 0.9375rem;
    position: relative;
    background-color: #bbc7ce !important;
    margin-right: 0.5rem;
}

.book-checkmark:checked {
    background-color: var(--primary-color) !important;
}

.book-checkmark::before {
    position: absolute;
    content: '\f00c';
    font-family: FontAwesome;
    color: #ffffff;
    left: 0;
    bottom: 0;
}

.label-book {
    padding: 0;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.69;
    color: #000000;
}

.book-preview-explanation {
    font-size: 0.875rem !important;
    font-weight: normal;
    line-height: 1.21;
    color: #000000;
    padding-left: 1.75rem;
}

.panel-cancel {
    text-decoration: underline;
}

.panel-cancel:hover {
    color: black;
    text-decoration: underline;
}
