@import '../global-variables.scss';

.uploading-photos-modal,
.generate-book-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.325rem;
}
.uploading-photos-content,
.generate-book-content {
    margin-top: 20%;
    width: 100%;
    max-width: 36.25rem;
    border-radius: 0.3125rem;
    background-color: #ffffff;
    padding: 2.5rem 1.875rem;
    text-align: center;
}
.uploading-photos-content,
.generate-book-content {
    > img {
        width: 6.3125rem;
        height: 6rem;
        margin-bottom: 0.625rem;
    }
}
.uploading-photos-title {
    margin-bottom: 1.875rem;
    font-size: 1rem;
    font-family: 'Roboto';
}

.uploading-photos-content > .progress-meter-upload,
.generate-book-content > .progress-meter-upload {
    margin-bottom: 0.625rem;
    border-radius: 0.625rem;
    overflow: hidden;
    height: 0.375rem;
    background-color: rgba(0, 100, 255, 0.3);
    position: relative;
    display: flex;
    align-items: center;
}
.progress-meter-upload > span {
    height: 0.375rem;
    background-color: #0064ff;
    position: relative;
    overflow: hidden;
    border-radius: 0.625rem;
}
.loading-info {
    font-family: 'Roboto';
    font-size: 0.875rem;
}
