@import '../global-variables.scss';

/*-------------------------------------------------------------
  Photos modal
-------------------------------------------------------------*/

.photo-grid {
    @extend .grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    margin-top: 16px;

    @include ss {
        grid-template-columns: repeat(2, 1fr);
    }
    @include xs {
        grid-template-columns: repeat(3, 1fr);
    }
    @include sm-x {
        grid-template-columns: repeat(5, 1fr);
    }

    .dz-preview {
        box-shadow: 0 0 0 1px $black2;
    }
}

.photos-action {
    .btn {
        width: 130px;
        height: 32px;
        margin: 1em auto;
        .filepicker {
            padding: 0;
        }
    }
}

.photo-details {
    p {
        font-size: 0.8em;
        color: $black6;
        margin: 0 0 0.5em;
    }
}

.single-photo {
    position: relative;

    img {
        width: 100%;
    }
}

.file__check {
    background: var(--green-color2);
    border-radius: 100px;
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    bottom: 8px;
    left: 8px;
    box-shadow: 0 0 0 2px white;

    img {
        width: 12px;
        height: 12px;
        filter: var(--filter-white);
    }
}

.single-photo .fa-exclamation-circle {
    color: red;
    position: absolute;
    bottom: 10%;
    left: 0.5rem;
}

.single-photo .rejected-photo-tooltip {
    visibility: hidden;
    background-color: white;
    color: black;
    box-shadow: 0 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    border-radius: 0.3125rem;
    border: 1px solid rgb(208, 208, 208);
    font-size: 0.8rem;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 2.125rem;
    width: 18rem;
    left: 1.5rem;
}

.single-photo:hover .rejected-photo-tooltip {
    visibility: visible;
}

.rejected-photo:hover > img {
    opacity: 0.5;
}

.uploading-photo > img {
    opacity: 0.5;
}

/* zhang's code */
.progress-bar {
    background-color: white;
    border-radius: 0.3125rem;
    width: 5rem;
    height: 0.3rem;
    position: absolute;
    bottom: 10%;
    left: 0;
}

/* zhang's code */
.progress-bar-fill {
    background-color: var(--primary-color);
    height: 0.3rem;
    transition: width 3s;
    width: 0%;
}

.select-photos {
    display: block;
    color: var(--primary-color) !important;
    text-decoration: underline;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
}

.select-photos:hover {
    color: var(--primary-color) !important;
}

.border-simple {
    border: 0.0625rem solid rgb(167, 167, 167);
}

.photos-modal-header {
    display: flex;
    justify-content: space-between;
    padding-top: 1.25rem;
    padding-bottom: 1.3125rem;
}

.photos-close {
    float: right;
    font-size: 1rem;
    cursor: pointer;
}

.box-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.box-actions > .btn-cancel {
    width: 6.5625rem;
    height: 2.3125rem;
    padding: 0;
    font-size: 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
}

.box-actions > .btn-cancel:hover {
    color: black;
}

.box-actions > .btn-publish {
    padding: 0;
    font-size: 0.9375rem;
    font-weight: 500;
    width: 8.6875rem;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.box-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.box-input {
    border: 1px dashed var(--primary-color);
    border-radius: 4px;
    text-align: center;
}

/*-------------------------------------------------------------
  File styles
-------------------------------------------------------------*/

.file .indicator {
    z-index: 10;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 25px;
    height: 25px;
    padding: 0;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    background: #ffffff;
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNGY0ZjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #ffffff 0%, #f4f4f5 100%);
    /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #ffffff),
        color-stop(100%, #f4f4f5)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ffffff 0%, #f4f4f5 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ffffff 0%, #f4f4f5 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #f4f4f5 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #f4f4f5 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f4f4f5', GradientType=0);
    /* IE6-8 */
}

.file .indicator i {
    font-size: 15px;
    line-height: 23px;
}

.file .indicator:hover {
    color: white;
    background: #ac0000;
}

.file .thumb-icon {
    margin: 16px auto 0;
    height: 48px;
    width: auto;
    display: block;
}

.file .file-info {
    position: absolute;
    width: 98px;
    text-align: center;
    font-size: 9px;
    bottom: 0;
    z-index: 10;
    background: #f4f4f5;
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #f4f4f5 0%, #ffffff 100%);
    /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, #f4f4f5),
        color-stop(100%, #ffffff)
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f4f4f5 0%, #ffffff 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f4f4f5 0%, #ffffff 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f4f4f5 0%, #ffffff 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f4f4f5 0%, #ffffff 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f4f5', endColorstr='#ffffff', GradientType=0);
    /* IE6-8 */
    padding: 4px 0;
}

.file .file-info .divider {
    opacity: 0.2;
    padding: 0 4px;
}

.file.uploaded {
    background-color: black;
}

.file.uploaded .indicator {
    color: white;
    background: #72ce00;
}

.file .thumb {
    width: 98px;
    height: 98px;
    display: block;
}

.file .status {
    text-align: center;
    position: absolute;
    width: 98px;
}

.file .status .loading {
    display: block;
    margin-bottom: 4px;
    font-size: 28px;
}

.file .status .message {
    font-size: 10px;
    line-height: 14px;
    display: block;
    color: #7c7986;
}

.file.uploading .status {
    top: 25px;
}

.file.upload-error .status {
    top: 18px;
}

.file.upload-error .status .loading {
    margin-bottom: 4px;
    font-size: 28px;
    color: #7c7986;
}

.dz-preview {
    display: inline-block;
}

.dz-preview .dz-progress,
.dz-preview .dz-progress .dz-upload,
.dz-preview.dz-error .dz-error-message,
.dz-preview.dz-error .dz-error-mark,
.dz-preview.dz-error .file.upload-error,
.dz-preview.dz-success .dz-success-mark,
.dz-preview.dz-success.dz-complete .file.uploaded,
.dz-preview.dz-processing .file.uploading {
    display: block;
}

.dz-preview .dz-error-message,
.dz-preview .dz-error-mark,
.dz-preview .dz-success-mark,
.dz-preview .file.uploaded,
.dz-preview .file.upload-error,
.dz-preview.dz-processing.dz-complete .file.uploading,
.dz-preview.dz-error.dz-complete .file.uploading {
    display: none;
}
