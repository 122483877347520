@import '../global-variables.scss';

@mixin header-sm {
    @media (max-width: 767.98px) {
        @content;
    }
}
@mixin header-lg {
    @media (min-width: 768px) {
        @content;
    }
}

//-------------------------------------------------------------
//  Logged-in Header
//-------------------------------------------------------------

.navbar-app {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1299;
    height: 60px;
    box-shadow: $black2 0px 0 2px, $black1 0px 0px 6px;
    @include header-sm {
        height: 50px;
    }
}

.button-height {
    @include header-sm {
        height: 50px;
    }
    @include header-lg {
        height: 60px;
    }
}

.header-nav {
    width: 100%;

    > ul {
        margin: 0;
        display: flex;

        @include header-sm {
            justify-content: space-between;
            width: 100%;
        }
        @include header-lg {
            justify-content: center;
        }

        .spacer {
            @include md-x {
                flex-grow: 1;
            }
            @include header-sm {
                display: none;
            }
        }

        li {
            flex: none;
            position: relative;
        }

        button {
            background: transparent;
            border: none;
            outline: none;
        }

        .dropdown-menu {
            background: white;

            @include header-sm {
                top: 46px;
            }
            @include header-lg {
                top: 56px;
            }
        }

        .header-nav__title {
            font-size: 16px;
        }
    }
}

.header-logo {
    @include header-sm {
        display: none;
    }
    @include header-lg {
        width: 155px;
    }

    button {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        @extend .button-height;

        @include header-sm {
            display: none;
        }

        a {
            display: block;
            margin-left: 10px;

            img {
                display: block;
                width: auto;
                height: 28px;
            }
        }
    }
}

.header-nav__item {
    &.active {
        .header-nav__link {
            &:after {
                display: none;
            }
        }

        img {
            transition: $trans2;
        }
    }
}

.header-nav {
    > ul > .header-nav__item {
        .header-nav__link {
            border-width: 3px 0;
        }
    }
}

.header-nav__link {
    position: relative;
    display: flex;
    align-items: center;
    border-style: solid;
    border-color: transparent;
    @extend .button-height;

    @include header-sm {
        padding: 0 10px;
    }
    @include header-lg {
        margin: 0 8px;
        padding: 0 8px;
    }

    &:after {
        position: absolute;
        transition: $trans2;
        content: '';
    }

    &:hover {
        img {
            opacity: 1;
        }

        &:after {
            content: '';
            left: 0;
            right: 0;
            width: 100%;
            height: 30px;
            border-radius: 4px;

            @media (max-width: 1095.98px) {
                height: 38px;
            }
        }
    }

    img {
        position: relative;
        z-index: 1;
        transition: $trans1;

        @media (max-width: 1095.98px) {
            width: 24px;
            height: 24px;
        }
        @media (min-width: 1096px) {
            width: 22px;
            height: 22px;
            margin-right: 8px;
        }
    }

    .header-nav__title {
        position: relative;
        z-index: 1;

        @media (max-width: 1096px) {
            display: none;
        }
    }
}

.header-nav__item--notifications {
    @include header-sm {
        order: 9;
    }

    button {
        .badge {
            background: orangered;
            color: white;
            border-radius: 3px;
            font-size: 11px;
            line-height: 16px;
            padding: 0 3px;

            @include x-md {
                top: 7px;
            }
            @media (min-width: 768px) and (max-width: 1095.98px) {
                top: 12px;
            }
            @media (max-width: 1095.98px) {
                position: absolute;
                right: -2px;
            }
            @media (min-width: 1096px) {
                margin-left: 6px;
            }
        }
    }

    .dropdown-notifications {
        @include xs-x {
            width: 100vw;
            max-width: 300px;
        }
    }
}

.header-nav__item--more,
.header-nav__item--notifications {
    @include x-xs {
        position: inherit !important;
    }
    @include xs-x {
        position: relative;
    }

    .dropdown-menu {
        max-height: 400px;
        overflow-y: auto;
    }
}

.header-nav__item--more {
    @extend .button-height;

    @include header-sm {
        order: 10;
        margin-right: 6px;
    }

    .header-nav__link {
        img {
            margin: 0;
            width: 24px;
            height: 24px;
        }
    }

    .dropdown--more {
        min-width: 190px;

        ul {
            flex-direction: column;

            li {
                background: none !important;
                padding: 0 !important;
                margin: 2px 0;
                max-width: inherit;
                border-width: 0 3px !important;
                border-style: solid !important;
                border-color: transparent !important;
                border-radius: 0;
                width: 100%;

                .header-nav__link {
                    color: black;
                    height: 34px;
                    margin: 0;
                    width: 100%;

                    img {
                        filter: var(--filter-black);
                        opacity: 0.6;
                        margin-right: 12px;
                        width: 22px;
                        height: 22px;
                    }

                    &:hover {
                        img {
                            filter: var(--filter-primary);
                        }

                        &:after {
                            background: var(--primary1);
                        }
                    }

                    .header-nav__title {
                        display: block;
                    }
                }

                &.active {
                    border-left-color: var(--primary-color) !important;

                    img {
                        filter: var(--filter-primary);
                        opacity: 1;
                    }
                }
            }

            .header-nav__item--settings {
                @include header-lg {
                    display: none;
                }

                img {
                    filter: none !important;
                    opacity: 1 !important;
                    border-radius: 100px;
                }
            }
        }
    }
}

.nav__tools {
    display: flex;
    align-items: center;

    button {
        position: relative;
        padding: 0 8px;
        @extend .button-height;

        @include header-lg {
            margin: 0 4px;
        }

        img {
            width: 24px;
            height: 24px;
            transition: $trans1;
        }

        &:hover {
            img {
                transition: $trans2;
            }
        }
    }
}

.nav__tools--search {
}

.nav__tools--add {
    button {
        img {
            width: 24px;
            height: 24px;
        }
    }

    .dropdown-menu--add {
        @include x-md {
            left: -240% !important;
            width: 100vw;
            max-width: 230px;
        }
        @include xs-x {
            min-width: 230px;
        }

        li {
            display: flex;
            align-items: center;
            min-width: 100%;

            img {
                filter: var(--filter-black);
                opacity: 0.6;
            }

            &:hover {
                img {
                    filter: var(--filter-primary);
                    opacity: 1;
                }
            }
        }
    }
}

.nav__tools--settings {
    @include header-sm {
        display: none;
    }

    .header-profile {
        display: block;

        @include x-sm {
            padding: 0 8px 0 2px;
        }
        @include sm-x {
            padding: 0 8px;
            margin: 0 4px;
        }

        img {
            border-radius: 100px;

            @include header-sm {
                width: 30px;
                height: 30px;
            }
            @include header-lg {
                width: 36px;
                height: 36px;
            }
        }
    }
}
