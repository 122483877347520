@import '../global-variables.scss';

/*-------------------------------------------------------------
  Content box modal
-------------------------------------------------------------*/

.new-book-content {
    margin-top: 20%;
    border-radius: 0.325rem;
    background-color: white;
    max-width: 36.25rem;
    width: 36.25rem;
    overflow-y: visible;
    box-shadow: $modal-shadow;
}

.new-book-modal-close {
    font-size: 3rem;
    cursor: pointer;
}

.new-book-timeline {
    margin-bottom: 1.5em;
    background: var(--primary1);
    border-bottom: 2px solid white;
    border-left: 2px solid white;

    .new-book-timeline-steps {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0;

        li {
            font-size: 0;
            height: 5px;
            min-width: 10px;
            width: 100%;
            border-right: 2px solid white;

            &.done-step {
                background: var(--primary-color);
            }
        }
    }
}

.new-book-timeline::before {
    position: absolute;
    top: 9px;
    width: 100%;
}

.new-book-timeline-steps {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    list-style-type: none;
}

.new-book-timeline-steps > li {
    position: relative;
}

.new-book-title {
    display: flex;
    align-items: center;
}

.new-book-title > p {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
}

.new-book-title > span {
    padding-right: 0.875rem;
    font-size: 1.75rem;
    font-weight: 600;
}

.photos-options {
    margin: 0 0 24px;
}

.new-book-input {
    width: 100% !important;
    max-width: inherit !important;
}

.book-dimension {
    font-weight: 600;
    font-size: 0.9375rem;
    color: black;
    padding: 0;
}
