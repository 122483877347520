@import '../global-variables.scss';

/*-------------------------------------------------------------
  Step verification
-------------------------------------------------------------*/

.step-verification-modal {
    display: block;
    /*  Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.step-verification-card {
    max-width: 25.4375rem !important;
    width: 100%;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    margin-top: 25%;
    background-color: white;
    border-radius: 0.325rem;
    text-align: center;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 2.25rem;
    position: relative;
}

.step-verification-form {
    display: flex !important;
}

.step-verification-enable-code,
.step-verification-enabled,
.step-verification-disable-code,
.step-verification-disabled,
.step-verification-change-pass {
    display: block;
}

.step-verification-enabled > .step-verification-desc {
    margin-bottom: 1.4375rem;
}

.step-verification-close {
    font-size: 1rem;
    color: #000000;
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    cursor: pointer;
}

.step-verification-title {
    padding-top: 2rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: rgb(32, 32, 32);
    margin-bottom: 0.8125rem;
}

.step-verification-desc {
    font-size: 0.875rem;
    color: rgb(32, 32, 32);
    line-height: 1.2;
    margin-bottom: 0.875rem;
}

.intl-tel-input .country-list {
    width: 20rem;
}

.intl-tel-input > .form-control {
    border: solid 0.0313rem #979797;
    border-radius: 0.1875rem;
    width: 100%;
    height: 2.5rem !important;
}

.intl-tel-input > .form-control:focus {
    outline: none;
    box-shadow: none;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    padding-left: 5px;
}

.pd-telephone-input {
    margin: 0;
}

.pd-telephone-input > .form-control {
    border: solid 0.0313rem #979797;
    border-radius: 0.1875rem;
    width: 100%;
    height: 2.5rem !important;
}

.pd-telephone-input > .form-control:focus {
    outline: none;
    box-shadow: none;
}

.step-verification-img-phone {
    margin-top: 0.3125rem;
    margin-bottom: 1rem;
}

.step-verification-select-msg {
    margin-top: 0.375rem;
    margin-bottom: 1.25rem;
    text-align: right;
    font-size: 0.75rem;
    color: rgba(32, 32, 32, 0.804);
}

.settings-secturity-code-input {
    text-transform: uppercase;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 2rem;
    width: 100% !important;
    border: 0.0625rem solid rgb(151, 151, 151) !important;
    border-radius: 0.1875rem;
}

.settings-step-verified {
    border-top: 0.0625rem solid #d3d3d3;
    padding-top: 1.25rem;
}

.settings-step-verified > .settings-toggle-desc {
    display: block;
}

.settings-step-phone {
    display: flex;
    align-items: center;
}

.settings-step-phone > .icon-mobile {
    font-size: 2.5rem;
    margin-right: 1rem;
}

.settings-step-phone > .icon-mobile::before {
    color: var(--primary-color);
}

.settings-step-phone > span {
    text-decoration: none;
    font-size: 1.5rem;
    padding: 1rem;
}

.settings-step-phone > span > .fa-pencil {
    color: rgb(0, 180, 204);
}

.settings-step-phone > span > .fa-trash {
    color: rgba(255, 0, 0, 0.38);
}

.settings-step-phone-content > p {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
}

.settings-phone-number {
    font-size: 1rem;
    color: rgb(32, 32, 32);
    border-bottom: none !important;
    margin-bottom: 0;
}
.settings-phone-number input {
    background-color: white !important;
}
.settings-phone-number .PhoneInputCountrySelectArrow {
    display: none;
}

.settings-phone-number > img {
    width: 1.125rem;
    height: 0.875rem;
    margin: 0.5rem;
}

.settings-verification-date {
    font-size: 0.8125rem;
    color: rgb(32, 32, 32);
}

.step-verification-change-pass {
    text-align: left;
}

.settings-change-password-form > label {
    margin-top: 1.25rem;
    font-size: 0.9375rem;
    font-weight: normal;
    opacity: 0.7;
    width: 100%;
    text-align: left;
    padding: 0;
}

.password-input {
    margin-top: 0.375rem;
    /* width: 100%; */
    border-bottom: 0.0625rem solid #d3d3d3;
    padding-bottom: 0.3125rem;
    padding-right: 6.5313rem;
}

.btn-form-actions {
    padding-top: 1.9063rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btn-cancel-form {
    width: 5.875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem var(--primary-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin-right: 0.625rem;
}

.btn-cancel-form:hover {
    color: #000000;
}

.btn-save-form {
    width: 9.9375rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-save-form > span {
    padding-right: 0.625rem;
}

/*-------------------------------------------------------------
  Verification banner
-------------------------------------------------------------*/

.verification-banner {
    min-width: 42.9375rem;
    width: 100%;
    height: 13.75rem;
    border-radius: 0.3125rem;
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: 10%;
    padding-left: 2.875rem;
    padding-right: 2.5rem;
}

.verification-banner-img > img {
    max-width: 223px;
    height: 10.125rem;
}

.verification-banner-content {
    padding-left: 2.5rem;
}

.verification-banner-content > h3 {
    font-size: 1.625rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    width: 100%;
}

.verification-banner-content > p {
    margin-bottom: 0.9375rem;
    font-size: 1.0625rem;
    font-weight: normal;
    padding-right: 0.75rem;
}

/* VERIFICATION MEDIA QUERIES */

@media (max-width: 1199.98px) {
    .verification-banner {
        margin-top: 20%;
    }
}
