@import '../global-variables.scss';

/*-------------------------------------------------------------
  Add media modal
-------------------------------------------------------------*/

.add-media {
    margin: 0 0 20px;
    position: relative;
    display: flex;
    box-shadow: $dropdown-shadow;
    padding: 8px;
    border-radius: 4px;
}

.add-media__icon {
    width: 16px;
    height: 16px;
    opacity: 0.6;
    margin-right: 10px;
    margin-top: 2px;
}

.add-media__content {
    display: flex;
    flex-direction: column;
}

.add-media__title {
    margin-bottom: 0.3em;

    @include x-sm {
        width: calc(100vw - 95px);
    }
    @include sm {
        width: 80vw;
    }
    @include md-x {
        max-width: 500px;
    }
}

.add-media__subtitle {
    font-size: 0.8em;
    color: $black6;

    img {
        width: 10px;
        height: 10px;
        opacity: 0.6;
        position: relative;
        top: -1px;
        transition: $trans2;
    }

    span {
        cursor: pointer;
        display: inline-block;

        &:hover {
            color: var(--primary-color);
            transition: $trans1;

            img {
                filter: var(--filter-primary);
                transition: $trans1;
                opacity: 1;
            }
        }
    }
}
