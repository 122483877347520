@import '../global-variables.scss';

/*-------------------------------------------------------------
  Alert card
-------------------------------------------------------------*/

.alert-cards {
    .alert-card__item {
        display: flex;
        align-items: center;
        padding: 6px 20px 6px 6px !important;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: #eee;
        }
    }

    .alert-card__img {
        flex: none;
        margin-right: 12px;

        .MuiAvatar-circle {
            width: 30px;
            height: 30px;
        }

        img {
            width: 30px;
            height: 30px;
            border-radius: 100px;
            margin-right: 0;
        }
    }

    .alert-card__img--icon {
        @extend .brand-gradient;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;

        img {
            width: 16px;
            height: 16px;
            filter: var(--filter-white);
        }
    }

    .alert-card__content {
        line-height: 1.3;
        font-size: 13px;
        color: $black8;

        .alert-card__name {
            font-weight: 600;
            color: black;
        }

        .alert-card__actions {
            margin-top: 5px;

            .alert-card__btn {
                font-size: 11px;
                color: $black5;
                transition: $trans1;
                margin-right: 6px;
                font-weight: 500;
                box-shadow: 0 0 0 1px $black2;
                padding: 1px 6px;
                border-radius: 100px;
                border: none;
                background: none;

                &:hover {
                    color: var(--primary-color);
                    transition: $trans2;
                    box-shadow: 0 0 0 1px var(--primary-color);
                }
            }
        }
    }

    .alert-card__item--buttons {
        cursor: default;

        &:hover {
            background: transparent;
        }
    }
}

/*-------------------------------------------------------------
  Notifications
-------------------------------------------------------------*/

.page--notifications {
    .page__header {
        .dropdown-menu {
            width: fit-content;
            transform: none !important;
            right: 10px;
            left: auto !important;
            top: 20px !important;
        }
    }
}

.notifications-list {
    padding: 0 10px 10px;
}
