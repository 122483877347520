@import '../global-variables.scss';

/*-------------------------------------------------------------
  Post
-------------------------------------------------------------*/

.post {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    @extend .card-styles;

    .posted-date {
        display: flex;
        align-items: center;
    }

    .last-edited {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 0.75em;
        color: $black4;
        position: relative;
        margin-left: 4px;
        transition: $trans1;

        .last-edited__date {
            opacity: 0;
            transition: $trans1;
            position: absolute;
            width: max-content;
            background-color: $black8;
            color: white;
            box-shadow: 0 0 0 1px white;
            padding: 3px 8px;
            border-radius: 100px;
            top: -16px;
            left: -90%;
        }

        &:hover {
            color: var(--primary-color);
            transition: $trans2;
        }

        &:focus {
            .last-edited__date {
                opacity: 1;
                transition: $trans2;
                top: -24px;
            }
        }
    }

    .separator-in {
        color: $black4;
        font-size: 13px;
        padding: 0 4px;
        flex: none;
    }

    .post-avatar {
        color: transparent;
        width: 40px;
        height: 40px;
        object-fit: cover;
        text-align: center;
        text-indent: 10000px;
        border-radius: 50%;
    }

    .post-title-section {
        .post-avatar {
            border-radius: 100%;
        }
    }

    .post—aam {
        .post-avatar {
            width: 40px;
            height: 40px;
            border-radius: unset !important;
        }
    }

    .posted-title-section {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 16px 10px 0 16px;

        @include ss {
            font-size: 90%;
        }

        .posted-title {
            display: flex;
            position: relative;

            .post-image {
                position: relative;
                min-width: 40px;
            }

            .entry-title {
                padding: 1px 0 1px 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .title-date-header {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    margin-bottom: 0.3em;

                    .date {
                        color: black;
                        transition: $trans1;
                        font-weight: 600;
                        font-size: 1em;

                        @include xs-x {
                            width: initial;
                        }

                        &:hover {
                            color: var(--primary-color);
                            transition: $trans2;
                        }
                    }
                }

                .journal-name {
                    line-height: 1.3;
                    font-size: 0.85em;
                    color: $black6;
                }
            }

            .category-post-wrapper {
                padding-left: 1em;

                .category-title {
                    color: black;
                    line-height: 1.3;
                    font-size: 1em;

                    .posted-date {
                        margin-top: 3px;

                        .date {
                            flex: none;
                            @extend .small-gray;
                        }

                        .category-name {
                            @extend .small-gray;

                            @include x-sm {
                                max-width: 100px;
                            }

                            @include sm {
                                max-width: 200px;
                            }

                            @include md-x {
                                max-width: 280px;
                            }
                        }
                    }
                }
            }
        }

        > .extra-info {
            display: flex;
            align-items: center;
            flex: none;
            width: auto;
            font-size: 0.9rem;
            padding-left: 8px;
            height: 20px;

            .days-passed {
                min-width: 3rem;
                font-size: 13px;
                margin-bottom: 0;
                text-align: right;
                color: rgba(0, 0, 0, 0.4);
            }

            .fa-eye {
                width: 16px;
                color: var(--primary-color);
            }

            .dropdown {
                z-index: 1;

                .material-dropdown-menu {
                    div:nth-child(2) {
                        right: 0;
                        left: inherit !important;
                        transform: translate3d(0, 24px, 0) !important;

                        div.MuiPaper-root {
                            transform-origin: inherit !important;
                            transition: none !important;
                        }
                    }
                }
            }
        }
    }
}

#mood-dropdown {
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100%;
    line-height: 1;
    justify-content: center;
}

.post.all-about-me {
    .MuiAvatar-root {
        border-radius: 0;
    }
}

/*-------------------------------------------------------------
  Post body
-------------------------------------------------------------*/

.type {
    line-height: 1.4;
    height: auto;

    .entry-title {
        color: black;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0;
        display: block;

        .icon-shared-alt-arrow {
            padding-right: 0.4375rem;
        }
    }

    p,
    ul,
    ol,
    blockquote,
    > figure {
        margin: 0 0 1rem;
        line-height: 1.5;
    }

    > figure {
        max-width: 100% !important;

        figure {
            > img:not(.image_full_screen) {
                cursor: pointer !important;
            }
        }
    }

    ul,
    ol {
        padding-left: 24px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    blockquote {
        padding-left: 16px;
        border-left: 4px solid $black2;
    }

    figcaption {
        font-size: 0.8em;
        line-height: 1.3;
        color: $black6;
        margin: 2px 0 0;
    }
}

.post-body {
    video {
        max-width: 100%;
    }
}

.posted-details {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 12px 16px;

    .posted-tags-comments {
        display: flex;
        align-items: center;

        .posted-tags-comments__btn {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: black;

            img {
                margin-right: 6px;
            }
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn-closed-comments {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            @extend .small-gray;
            transition: $trans2;

            .icon-comments {
                padding-right: 5px;

                &::before {
                    color: $black6;
                }
            }

            > .icon-arrow-right {
                padding-left: 0.625rem;
                font-size: 0.5rem !important;

                &::before {
                    color: $black6;
                }
            }

            &:hover {
                color: var(--primary-color);
                transition: $trans2;

                .icon-comments:before {
                    color: var(--primary-color);
                    transition: $trans2;
                }
            }
        }
    }

    .posted-details__right {
        display: flex;

        > div {
            margin-left: 12px;
        }

        .entry-location {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            top: 1px;

            .entry-location__title {
                font-size: 0.75em;
                line-height: 1.2;
                color: $black4;
                margin-right: 4px;
                transition: $trans1;

                & > span {
                    text-align: right;
                }

                @include x-xs {
                    max-width: 120px;
                    font-size: 0.65em;
                }

                @include xs {
                    max-width: 200px;
                    font-size: 0.7em;
                }

                @include sm-x {
                    max-width: 250px;
                }
            }

            &:hover {
                .entry-location__title {
                    color: var(--primary-color);
                    transition: $trans2;
                }

                img {
                    filter: var(--filter-primary);
                    opacity: 1;
                }
            }
        }

        .post-tags {
            position: relative;
            top: 2px;
        }
    }
}

.btn-close-comments {
    .icon-comments:before {
        color: var(--primary-color);
        display: none;
    }

    .fa-close {
        margin-left: 8px;
        margin-top: 2px;
    }
}

.document-file img {
    margin-top: 0 !important;
}

/*-------------------------------------------------------------
  Queries
-------------------------------------------------------------*/

.icon-shared {
    font-size: 0.8rem;
    line-height: 1.2;
}

.post-info {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.posted-tags {
    margin-right: 0.5rem;
}

.journal-used,
.date {
    font-weight: 500;
}

.tags-badge {
    color: rgba(0, 0, 0, 0.4);
}

.journals-badge {
    font-weight: 600;
    color: #00416b;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0;
}

/* MOOD DROPDOWN */

.mood-dropdown-menu {
    left: 0 !important;

    @include ss {
        max-width: 85vw;
    }
}

.emoticons-gallery {
    padding-bottom: 10px;

    .emoticon-used {
        margin: 0;
        display: inline;

        img {
            width: 16px;
            height: 16px;
            margin: 0;
        }
    }
}

.mood-content {
    flex: auto;
    padding: 0 6px 6px;

    .mood-title {
        font-size: 1.1em;
        line-height: 1.3;
        font-weight: 600;
        margin: 0 0 0.3em;
    }

    .mood-text {
        color: $black7;
        font-size: 0.9em;
        line-height: 1.3;
    }
}

.avatar-emoticons {
    display: none;
    box-shadow: $dropdown-shadow;
    background-color: white;
    position: absolute;
    border-radius: 100px;
    left: 7px;

    .avatar-emoticons__container {
        padding: 4px;
        display: flex;
        align-items: center;

        .avatar-emoticons__avatar {
            img {
                width: 40px;
                height: 40px;
            }
        }

        .avatar-emoticons__item {
            display: inline-block;
            opacity: 0.6;
            cursor: pointer;
            transition: $trans1;
            padding: 0 5px;

            img {
                width: 24px;
                height: 24px;
            }

            &:hover,
            &.active {
                opacity: 1;
                transition: $trans2;
                transform: scale(1.05);
            }
        }

        .avatar-emoticons__close {
            padding: 0 8px 0 4px;
        }
    }

    &.show {
        display: block !important;
    }
}

.emoji-avatar-mood {
    position: absolute;
    right: -3px;
    top: 24px;

    img {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}

.posted-title > .fa-check-circle {
    font-size: 3rem;
}

.posted-title > .fa-check-circle.active {
    color: var(--primary-color);
}

.bulk-select {
    font-size: 26px;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px $black3;
    color: $black3;
    transition: $trans1;

    &:hover {
        box-shadow: inset 0 0 0 2px var(--primary-color);
        color: var(--primary-color);
        transition: $trans2;
    }

    &.active {
        box-shadow: inset 0 0 0 100px var(--primary-color);
        color: white !important;
    }
}

.fa-user-friends {
    color: var(--primary-color);
}

.image-avatars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    flex: none;

    img {
        width: 24px;
        height: 24px;
        filter: none !important;
    }
}

.image-avatars > span {
    border-radius: 50%;
    background-color: var(--primary-color);
    width: 1.5625rem;
    height: 1.5625rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}

.image-avatars > span:nth-child(2) {
    margin-left: -0.4rem;
}

.image-group {
    border: 2px solid white;
    width: 1.5rem;
    border-radius: 50%;
}

.image-group:nth-child(2) {
    margin-left: -0.4rem;
}

.add-media-box-file > img {
    margin-top: 0px !important;
}

.add-media-box-file div p:last-child,
.read__more .add-media-box-file .file-info-size {
    vertical-align: text-top;
}

.embed-post {
    margin-top: 0.9375rem;
    width: 34.375rem;
    height: 19.3125rem;
    box-shadow: 0rem 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.09);
    border-radius: 0.3125rem;
    border: solid 0.0625rem #efefef;
    background-color: #fffdff;
}

.embed-post-header {
    border-bottom: 0.0625rem solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.8125rem;
}

.embed-post-header > span {
    color: rgba(193, 6, 6, 0.5);
    cursor: pointer;
}

.embed-post-header-content {
    display: flex;
    align-items: center;
    position: relative;
}

.instagram-icon::after {
    content: '\f16d';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 1.2813rem;
    top: 1.25rem;
    color: white;
    border-radius: 50%;
    background-color: #c13584;
    width: 100%;
    height: 100%;
}

.facebook-icon::after {
    content: '\f09a';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 1.2813rem;
    top: 1.25rem;
    color: white;
    border-radius: 50%;
    background-color: #4267b2;
    width: 100%;
    height: 100%;
}

.embed-post-header-content > img {
    width: 2.5rem;
    height: 2.5rem;
}

.embed-post-header-content-title {
    padding-left: 1.25rem;
    text-align: left;
}

.embed-post-header-title {
    display: flex;
    align-items: center;
}

.embed-post-header-title > h3 {
    margin-bottom: 0.125rem;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #3e3e3e;
}

.embed-post-header-title > a {
    font-size: 0.8125rem !important;
    font-weight: 500;
    color: #00416b;
    text-decoration: underline !important;
    padding-left: 0.9375rem;
}

.embed-post-header-content-title > p {
    margin-bottom: 0;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #3e3e3e;
    opacity: 0.7;
}

.embed-post-content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.625rem;
    padding-bottom: 0.9375rem;
    font-size: 1.0625rem;
    font-weight: normal;
    color: #3e3e3e;
    line-height: 1.47;
    text-align: left;
}

.embed-post-content > p {
    margin-bottom: 0;
}

.embed-video-box,
.embeded-img {
    float: left;
    width: 9rem;
    height: 9rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    margin-right: 0.9375rem;
    /* margin-bottom: 0.25rem; */
    position: relative;
}

.embed-video-box > video {
    width: 9rem;
    height: 9rem;
}

.embed-video-controls > .play-pause {
    top: 2.5rem !important;
    left: 2.5rem !important;
}

.btn-add-post {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 500;
    width: 7.1688rem;
    height: 2.1875rem;
    background-color: var(--primary-color);
}

.btn-add-post > span {
    padding-right: 0.5rem;
}

.figure-action span,
.figure-action-btn span {
    font-size: medium;
}

.resizable-figure {
    overflow: hidden;
    resize: horizontal;
    min-width: 10.625rem;
}

.btn-load-more-comments {
    width: 12.875rem;
    color: #1164fb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.btn-load-more-comments > .icon-caret-down {
    padding-right: 0.625rem;
}

.btn-load-more-comments:hover {
    color: #1164fb;
}

/*-------------------------------------------------------------
  Drafts
-------------------------------------------------------------*/

.draft-journal {
    > div {
        border-left: 6px solid var(--beta);

        &:last-child {
            @include md-x {
                border-bottom-left-radius: 4px;
            }
        }
    }
}

.draft-badge {
    display: flex;
    align-items: center;
    background: var(--beta);
    padding: 8px 4px;

    @include md-x {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .draft-badge__icon {
        width: 12px;
        height: 12px;
        filter: var(--filter-white);
        margin-right: 6px;
    }

    .draft-badge__title {
        color: white;
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 500;
    }
}

/*-------------------------------------------------------------
  Other
-------------------------------------------------------------*/

.deleted-user {
    text-decoration: line-through;
    max-width: 10rem;
    display: block;
}

//document files
.add-media-box-file img,
.document-file img {
    margin-top: 0 !important;
}

//map

.react-geocoder {
    margin: 10px 10px 0 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    background-color: #fff;
    box-sizing: border-box;
    clear: both;
    pointer-events: auto;
    transform: translate(0);
    min-width: 240px;
    z-index: 1;
    border-radius: 4px;
    transition: width 0.25s, min-width 0.25s;
    top: 0;
    position: absolute;
    right: 0;
}

.react-geocoder:before {
    content: '\f002';
    font-family: FontAwesome;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: rgba(0, 0, 0, 0.75);
}

.react-geocoder input {
    font: inherit;
    width: 100%;
    border: 0;
    background-color: transparent;
    margin: 0;
    height: 50px;
    color: #404040;
    color: rgba(0, 0, 0, 0.75);
    padding: 6px 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-geocoder-results {
    display: block !important;
    background-color: #fff;
    border-radius: 4px;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1000;
    overflow: scroll;
    height: 15rem;
    max-width: 240px;
    margin-right: 10px;
}

.react-geocoder-item {
    display: block;
    padding: 6px 12px;
    cursor: pointer;
    color: #404040;
}

.react-geocoder-item:hover {
    background-color: #f3f3f3;
}

.infinite-scroll-component {
    overflow: unset !important;
}

.read__more .add-media-box-file img {
    width: 4rem !important;
    max-width: 5% !important;
}

.min-height-100px {
    min-height: 100px;
}

#image_full_screen {
    object-fit: contain;
    z-index: 9999;
    position: fixed !important;
    margin: 0 auto;
    width: 100% !important;
    height: 100vh !important;
    top: 0;
    left: 0;
    padding: 30px;
}

#image_full_screen_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    z-index: 9999;
}

#image_full_screen_container:before {
    content: '';
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.right_bottom {
    right: auto;
    bottom: auto;
    height: auto;
    position: absolute;
    transform: translate3d(0px, 16px, 0px);
    top: 0;
    will-change: transform;
}

.left_bottom {
    left: auto;
    bottom: auto;
    height: auto;
    position: absolute;
    transform: translate3d(0px, 16px, 0px);
    top: 0;
    will-change: transform;
}

@media screen and (min-width: 640px) {
    .react-geocoder {
        width: 33.3333%;
        font-size: 15px;
        line-height: 20px;
        max-width: 360px;
    }

    .react-geocoder input {
        height: 36px;
        padding: 6px 35px;
    }
}
