@import '../global-variables.scss';

/*-------------------------------------------------------------
  Plans
-------------------------------------------------------------*/

.choose-account-plan-card {
  max-width: 24.4375rem;
  width: 100%;
  height: 31.25rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding-right: 2.125rem;
  padding-top: 2.75rem;
  padding-left: 2.125rem;
  padding-bottom: 2.4375rem;
}

.btn-get-plan-free {
  width: 19.0625rem;
  height: 4rem;
  border-radius: 0.625rem;
  background-color: var(--primary-color);
  font-size: 1.625rem !important;
  font-weight: 500;
}

.btn-get-plan-upgrade {
  width: 19.0625rem;
  height: 3.75rem;
  border-radius: 0.625rem;
  border: solid 0.125rem var(--primary-color);
  font-size: 1.625rem !important;
  font-weight: 500;
  color: #000000;
}

.all-accounts-include {
  margin-top: 5.625rem;
  margin-bottom: 6.9375rem;
}

.all-accounts-title {
  font-size: 2.5rem;
  font-weight: normal;
  color: #00416a;
  margin-bottom: 3.8125rem;
}

.all-accounts-include > .container {
  max-width: 84.375rem !important;
}

.all-accounts-features {
  padding-left: 0;
  padding-right: 0;
}

.feature-card {
  display: flex;
  align-items: center;
  margin-right: 5.625rem;
  margin-bottom: 2.5625rem;
  padding-left: 0;
  padding-right: 0;
}

.feature-card > img {
  width: 7.5rem;
  height: 7.5rem;
  opacity: 1;
  border-radius: 1.25rem;
  background-color: var(--primary-color);
  margin-right: 1.875rem;
}

.feature-card-content {
  width: 15rem !important;
}

.feature-card-title {
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 0.375rem;
  color: #00416a;
}

.feature-card-text {
  font-size: 1.25rem;
  font-weight: normal;
  color: #000000;
  margin-bottom: 0;
}

.plans-footer {
  height: 34.0625rem;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plans-footer > h3 {
  font-size: 6.1875rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0;
}

.place-welcome-card {
  max-width: 48.6875rem;
  height: 15.875rem;
  opacity: 0.85;
  border-radius: 0.9375rem;
  background-color: #ffffff;
  text-align: center;
  padding-top: 2.8125rem;
}

.place-welcome-card-title {
  font-size: 2.25rem;
  font-weight: normal;
  color: #00416a;
}

.place-welcome-card-text {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 0.75rem;
  line-height: 1.6;
}

.place-welcome-card-text > span {
  font-weight: normal;
}

.save-memories-plans-card {
  margin-top: 5.125rem;
  margin-bottom: 18.375rem;
  max-width: 55.875rem;
  width: 100%;
  height: 21.5rem;
  background-color: rgb(216, 221, 242);
  display: flex;
  align-items: center;
  border-radius: 0.9375rem;
}

.save-memories-plans-card > img {
  width: 18.125rem;
  height: 19.5rem;
  margin-right: 2.25rem;
}

.btn-save-memories {
  width: 19.875rem;
  height: 4rem;
  border-radius: 0.625rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 1.5rem;
  font-size: 1.75rem !important;
  font-weight: 500;
}

@media (max-width: 768.99px) {
  .choose-account-plans > .container {
      padding-left: 0;
      padding-right: 0;
      margin-right: 0;
      margin-left: 0;
      max-width: 100vw;
  }

  .first-card-mr {
      margin-right: 0;
  }

  .first-card-mr > .choose-account-header {
      margin-bottom: 2rem;
  }

  .account-features-spacing {
      padding-right: 0;
  }

  .btn-save-memories {
      width: 11.875rem;
      height: 3rem;
      font-size: 1rem;
  }

  .save-memories-plans-card {
      margin-bottom: 5rem;
      padding-right: 0;
  }

  .save-memories-plans-card > img {
      width: 50%;
  }

  .save-memories-card-title {
      font-size: 1.5rem;
  }

  .save-memories-card-text {
      font-size: 1.2rem;
  }

  .btn-save-memories {
      font-size: 1rem !important;
  }

  .all-accounts-include {
      margin-bottom: 0;
  }
}

@media (max-width: 420.99px) {
  .choose-account-plan-card {
      max-width: 100vw;
  }

  .first-card-mr {
      margin-bottom: 1.5rem;
  }

  .save-memories-plans-card {
      height: 13.5rem;
  }

  .save-memories-plans-card > img {
      width: 33%;
  }

  .save-memories-card-title {
      font-size: 1rem;
  }

  .save-memories-card-text {
      font-size: 0.8rem;
  }
}
