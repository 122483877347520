@import '../global-variables.scss';

/*-------------------------------------------------------------
  Settings
-------------------------------------------------------------*/

.settings-list-title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1em;
}

.settings-card {
    width: 100%;
    display: flex;
    padding: 0;
    align-items: stretch;

    .full-width-mobile {
        @include x-sm {
            width: 100%;
        }
    }

    .back-bar--settings {
        z-index: 1;

        @include x-md {
            display: block;
        }
        @include md-x {
            display: none;
        }
    }

    .wrapped-settings-list-mobile-hide {
        // width: 100%;
        @include x-md {
            display: none;
        }
    }

    .tab-content p {
        line-height: 1.3;
    }

    .settings-list {
        max-width: 230px;
        width: 100%;
        border-right: 1px solid $black1;
        padding: 1em;
        background: white;
        // margin-bottom: 48px;

        @include x-md {
            max-width: 100% !important;
        }

        .settings-list-bullets {
            margin: 0 0 16px !important;

            .nav-item {
                margin: 2px 0;

                .nav-item__link {
                    padding: 8px 10px;
                    font-size: 1em;
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    transition: $trans1;

                    .nav-item__icon {
                        margin: 0 16px 0 0;

                        img {
                            width: 20px;
                            height: 20px;
                            opacity: 0.6;
                            filter: var(--filter-black);
                        }
                    }

                    &:hover {
                        transition: $trans2;
                        background: var(--primary1);

                        .nav-item__icon img {
                            filter: var(--filter-primary);
                            opacity: 1;
                        }

                        .nav-item__title {
                            color: var(--primary-color);
                        }
                    }

                    &.active,
                    &.active:hover {
                        @include md-x {
                            background: var(--primary-color);

                            .nav-item__title {
                                color: white;
                                font-weight: 600;
                                transition: $trans2;
                            }

                            img {
                                filter: var(--filter-white);
                                opacity: 1;
                            }
                        }
                    }

                    .nav-item__title {
                        font-size: 15px;
                        color: black;
                    }
                }
            }
        }
    }

    .settings-content {
        width: 100%;
        position: relative;
        display: flex;
        padding: 2.5em 3em 3em;
        padding-bottom: 32px;
        padding-top: 48px;
        background: white;

        @include x-sm {
            padding: 1.5em 1em;
        }
        @media (min-width: 680px) and (max-width: 767.98px) {
            border-radius: 4px;
        }
        @include x-md {
            box-shadow: $shadow-frame;
        }
    }
}

/*------------------------------
  Tabbed plans
-------------------------------*/

.tab-pane {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include x-sm {
        font-size: 85%;
    }
}

.settings-section {
    margin: 0 0 24px;

    .page {
        @include x-md {
            box-shadow: none;
            background: none;
        }
        @include md-x {
            overflow: hidden;
        }
    }
}

.settings-title {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1em;
}

.settings-title-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    column-gap: 8px;

    .subscriptions-title-box {
        width: 100px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        background: #ffffff;
        border: 1px solid #d2d2d2;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 5px;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;

        p {
            margin: 0;
        }
    }

    .active {
        border: 2px solid #126afa;
    }

    p {
        font-size: 1em;
        line-height: 1.4;
        margin: 0 0 16px;
    }
}

.settings-subtitle {
    font-size: 1.2em;
    line-height: 1.3;
    margin-bottom: 16px;
}

/*------------------------------
  Checkout amount
-------------------------------*/

.personal-information-settings-form {
    flex: none;
}

.sharing-privacy-settings {
    flex: 1;
}

.wrapped-settings__angle-left {
    width: 21px;
    height: auto;
}

.settings-number-left {
    display: flex;
    align-items: center;

    .btn {
        margin-right: 16px;
    }

    .settings-number-left__info {
        font-size: 14px;
        color: $black7;
    }
}

.settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
}

.settings-header > h3 {
    margin-bottom: 0;
    margin-top: 1.25rem;
}

.btn-change-color,
.btn-change-color:hover {
    margin-right: 12.5rem;
    color: #1164fb;
    text-decoration-line: underline !important;
}

.header-text-colors {
    width: 21.75rem;
    border-radius: 0.1875rem;
    border: solid 0.0625rem #aeaeae;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.color-bullet-chosen {
    width: 1.6875rem;
    height: 1.6875rem;
    background-color: #0064ff;
    border-radius: 50%;
}

.big-text-line {
    display: flex;
    margin-bottom: 0.625rem;
    width: 11.25rem;
    border-bottom: 0.3125rem solid #aeaeae;
}

.small-text-line {
    display: flex;
    width: 5.625rem;
    border-bottom: 0.3125rem solid #aeaeae;
}

.settings-info {
    margin-bottom: 0;
    padding-right: 4.6875rem;
    color: #000000;
    opacity: 0.6;
    font-size: 1rem;
    line-height: 1.19;
    margin-top: 1rem;
}

/* DEFAULT SETTINGS */
.settings-initial-state {
    /* margin-top: 15.4375rem; */
    text-align: center;
}

.user-avatar-settings {
    width: 12.875rem;
    height: 12.875rem;
    border-radius: 50%;
    margin-bottom: 0.9375rem;
}

.user-name-settings {
    padding-top: 1rem;
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
    color: #000000;
}

.user-email-settings {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #252d33;
}

.joined-settings {
    font-size: 0.75rem;
    font-style: italic;
    color: #111a21;
}

.btn-donate {
    width: 100%;
    background: #03b6cd !important;

    img {
        margin-right: 15px;
        width: 20px;
        height: 16px;
    }
}
