@import '../global-variables.scss';

/*-------------------------------------------------------------
  Forgot password
-------------------------------------------------------------*/

.forgot-pass-card {
  border-radius: 0.3125rem;
  background-color: white;
  text-align: center;
}

.forgot-pass-content {
  margin-bottom: 2rem;
}

.forgot-pass-content > h3 {
  font-size: 1.625rem;
  color: rgb(0, 0, 0);
  font-weight: 600;
  margin-bottom: 1rem;
}

.forgot-pass-content > p {
  font-size: 16px;
  line-height: 1.6;
}

.forgot-pass-form {
  width: 100%;
}

.forgot-pass-input {
  border-bottom: 1px solid rgb(224, 224, 224);
  font-size: 1rem;
  color: black;
  margin-bottom: 2rem;
  width: 100%;
}

.forgot-pass-input::placeholder {
  color: rgb(189, 189, 189);
  opacity: 1;
  /* Firefox */
}

.forgot-pass-input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(189, 189, 189);
}

.forgot-pass-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(189, 189, 189);
}

.btn-reset-password {
  font-size: 1.1875rem;
  text-transform: uppercase;
  border-radius: 0.1875rem;
  background-color: rgb(224, 224, 224);
  margin-bottom: 1rem;
  color: white !important;
  width: 100%;
  height: 1.8rem;
}

.btn-reset-disabled:hover {
  color: rgb(166, 166, 166);
}

.btn-reset-active {
  background-color: rgb(88, 179, 0);
  color: white;
  font-size: 1.1875rem;
  text-transform: uppercase;
  border-radius: 0.1875rem;
  margin-bottom: 1rem;
  width: 100%;
}

.reset-link {
  margin-bottom: 1rem;
  display: block;
  text-decoration: none;
  font-size: 0.75rem;
  color: rgb(34, 188, 209);
}

.reset-link:hover {
  color: rgb(34, 188, 209);
}