@import '../global-variables.scss';

/*-------------------------------------------------------------
  Location modal
-------------------------------------------------------------*/

.location-modal-content-map {
    width: 100%;
    height: 400px;
    border-radius: 0;
    position: relative;

    > div:first-child {
        width: 100% !important;
        height: 100% !important;
    }
}

.mapboxgl-canvas {
    width: 100% !important;
}

.location-modal-content-map > img {
    width: 100%;
    height: 100%;
}

.map-search-form {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    max-width: 16.5625rem;
    width: 100% !important;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 0.9375rem;
    padding-right: 1.875rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    display: flex;
    align-items: center;
}

#viewport-marker::after {
    content: '';
    background-image: url(/static/media/icon_pin-color.86aab41a.svg);
    background-size: 30px;
    width: 30px;
    height: 30px;
    position: absolute;
    font-weight: normal;
    left: 0;
    transform: translate(-50%, -100%);
    margin-top: -10px;
}

.map-search-input {
    font-size: 1rem;
    font-weight: 300;
    color: #000;
}

.map-search-input::placeholder {
    color: #b6b6b6;
}

.location-modal-content-map-place {
    width: 7.1rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    font-size: 0.75rem;
    font-weight: 300;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.625rem;
    padding-right: 0.8125rem;
    top: 6.6875rem;
    left: 12.6875rem;
}

.location-modal-content-map-place > p {
    line-height: 1.17;
    margin-bottom: 0;
}

.underline-border-red {
    width: 100%;
    border-bottom: 1px solid #f75f5f;
}

.location-modal-content-map .overlays {
    overflow: visible !important;
}

a.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact:after {
    display: none !important;
}

/*-------------------------------------------------------------
  Geocoder
-------------------------------------------------------------*/

.react-geocoder {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    clear: both;
    pointer-events: auto;
    transform: translate(0);
    height: 34px;
    width: 260px;
    z-index: 1;
    border-radius: 4px;
    transition: width 0.25s, min-width 0.25s;
    position: absolute;
    top: 10px;
    left: 26px;
    box-shadow: $dropdown-shadow;

    &:before {
        content: '\f002';
        font-family: FontAwesome;
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        color: rgba(0, 0, 0, 0.75);
    }

    input {
        font: inherit;
        width: 100%;
        border: 0;
        background-color: transparent;
        margin: 0;
        height: 34px;
        color: #404040;
        color: rgba(0, 0, 0, 0.75);
        padding: 0 8px 0 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.react-geocoder-results {
    display: block !important;
    background-color: white;
    border-radius: 4px;
    left: 26px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 50px;
    z-index: 1000;
    overflow-y: scroll;
    height: 15rem;
    max-width: 260px;
    box-shadow: $dropdown-shadow;
    transition: $trans1;

    &::-webkit-scrollbar {
        width: 6px;
        background: $black1;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $black3;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary-color);
    }
}

.react-geocoder-item {
    display: block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.3;
    cursor: pointer;

    &:hover {
        background-color: var(--primary1);
        transition: $trans2;
    }
}

.infinite-scroll-component {
    overflow: unset !important;
}
.post-body header {
    position: static !important;
    top: unset !important;
    width: unset !important;
    z-index: unset !important;
    height: unset !important;
    box-shadow: unset !important;
    font-weight: 400 !important;
    font-size: 1.0625rem !important;
    color: #3e3e3e !important;
    line-height: 1.765 !important;
    word-wrap: break-word !important;
}
.read__more .add-media-box-file img {
    width: 4rem !important;
    max-width: 5% !important;
}
