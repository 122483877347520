.subscription-expiring-soon-banner {
    padding: 1.875rem;
    background: #fec4c4;
    border-radius: 1.25rem;
    max-width: 39.5rem;
    width: 100%;
    margin-bottom: 1.25rem;
}

.subscription-title {
    font-family: 'Roboto';
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: medium;
    letter-spacing: 0rem;
    color: #000000;
    margin-bottom: 0.625rem;
}

.subscription-title i::before {
    color: #000000;
}

.subscription-info {
    margin-bottom: 0.9375rem;
    font-family: 'Roboto';
    font-size: 1rem;
    line-height: 1.3125rem;
}

.btn-automatic-renewal {
    height: 2.1875rem !important;
}
