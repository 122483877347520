@import '../global-variables.scss';

/*-------------------------------------------------------------
  Account 
-------------------------------------------------------------*/

.account-plan {
    box-shadow: $shadow-frame;
    border-radius: 4px;
    margin: 0 0 16px;
    padding: 24px;

    .account-plan__upgrade-section {
        background: #fff7e1;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px;

        span {
            margin-left: 10px;
        }
    }

    .accont-plan-upgrade-secion-info {
        margin-top: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .account-plan-title {
        margin: 0 0 8px;
        display: flex;
        justify-content: space-between;

        .account-plan__title_subtitle {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
            }
        }

        .account-plan__title_price {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
        }
    }

    .account-plan__title {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary-color);
        letter-spacing: 1px;
        margin: 0 0 8px;
    }

    .account-plan__subtitle {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;

        span {
            cursor: pointer;
            color: var(--primary-color);
        }
    }

    .account-plan__desc {
        li {
            margin: 10px 0;
            line-height: 1.3;
            font-size: 14px;
            padding-left: 30px;
            position: relative;

            &:before {
                position: absolute;
                left: 0;
                top: 8px;
                height: 7px;
                width: 2px;
                background-color: var(--beta);
                content: '';
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }

            &:after {
                position: absolute;
                left: 0;
                bottom: 0;
                top: 13px;
                height: 2px;
                width: 13px;
                background-color: var(--beta);
                content: '';
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }
        }

        .fine-print {
            color: $black6;
            font-size: 12px;
            font-style: italic;
            padding: 0 !important;

            &:before,
            &:after {
                content: none;
            }
        }
    }

    .account-plan__price {
        font-size: 28px;
        font-weight: 300;
        margin: 16px 0;

        .currency {
            font-size: 13px;
            position: relative;
            top: -8px;
        }

        .duration {
            font-size: 12px;
            color: $black7;
            margin-left: 4px;
        }

        .strike {
            text-decoration: line-through;
        }
    }

    .btn {
        margin-top: 20px;
    }
}

#cross-price-tab {
    color: red;
    text-decoration: line-through;
    font-size: small;
}
#cross-price-section {
    color: red;
    text-decoration: line-through;
    font-size: medium;
}

.account-plan--pro {
    .account-plan__desc {
        @include lg-x {
            column-count: 2;
            column-gap: 16px;
        }
    }
}

/*-------------------------------
  Family plans
-------------------------------*/

.family-plan-section {
    .list__content--user {
        .list__subtitle {
            width: fit-content;
            padding: 0.15em 0.5em 0.2em;
            border-radius: 0.3em;
            font-size: 0.8em !important;
            line-height: 0;
            font-weight: 500;
            background-color: var(--primary-color);
            text-align: center;
            color: #ffffff !important;
        }
    }
}

/*
.family-members > li {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.family-members > li > img {
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
  border-radius: 50%;
}

.family-members > li > .icon-trash {
  font-size: 1.5rem;
  cursor: pointer;
}
.family-members > li > .icon-pencil {
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.family-member-info {
  max-width: 13.5625rem !important;
  width: 100%;
}

.family-member-name {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.035rem;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 0;
}

.family-member-desc {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.2;
  color: #9e9e9e;
  margin-bottom: 0;
}

.family-member-actions {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.btn-add-family {margin-right: 1rem;}

.family-member-modal-content {
  margin-top: 20%;
  max-width: 23.5rem;
  width: 100%;
  height: 21.25rem;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
  background-color: #fffdff;
  padding-left: 3.1rem;
  padding-top: 2.0938rem;
  padding-right: 3.125rem;
  padding-bottom: 1.875rem;
}

.user-mirror-form {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem !important;
}

.user-mirror-form > label {
  padding: 0;
}

.jrnlGuard-settings {
  padding-left: 1.875rem;
  padding-right: 1.9063rem;
  padding-top: 2.6437rem;
}

.jrnlGuard-info-section {
  border-bottom: 0.0625rem solid #d3d3d3;
  padding-bottom: 1.8938rem;
}

.settings-title > .icon-security {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-right: 0.75rem;
}

.jrnlGuard-info-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.3125rem;
}

.jrnlGuard-info-header > .settings-title {
  margin-right: 1.875rem;
  margin-bottom: 0;
}

.jrnlGuard-info-header > .settings-desc {
  margin-bottom: 0;
}

.jrnlGuard-info {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.19;
  color: #4b4b4b;
  padding-right: 2.5313rem;
}

.jrnlGuard-info:last-child {
  margin-bottom: 0;
}

.jrnlGuard-beneficiaries {
  padding-bottom: 1.9063rem;
  border-bottom: 0.0625rem solid #d3d3d3;
}

.beneficiaries-list > li {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.beneficiaries-list > li:last-child {
  margin-bottom: 1.9375rem;
}

.beneficiaries-list > li > .icon-pencil::before {
  color: #1164fb;
}

.beneficiaries-list > li > .icon-pencil {
  margin-right: 1.375rem;
}

.beneficiary-contact {
  max-width: 11.25rem;
  width: 100%;
}

.beneficiary-name {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e1e1e;
  line-height: 1.2;
  margin-bottom: 0;
}

.beneficiary-access {
  font-size: 14px;
  font-weight: normal;
  color: #9e9e9e;
  line-height: 1.2;
  margin-bottom: 0;
}

.beneficiary-info {
  max-width: 14.6875rem;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: #9e9e9e;
}

.beneficiary-info > p {
  margin-bottom: 0;
}

.beneficiary-section-actions {
  display: flex;
  align-items: center;
}

.btn-add-jrnlGuard {margin-right: 1rem;}

.seats-left-info {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.2;
  color: #181818;
  opacity: 0.71;
}

.jrnlGuards-accounts-list > li {
  display: flex;
  align-items: center;
  margin-bottom: 1.3125rem;
}

.jrnlGuards-accounts-list > li > img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
  border-radius: 50%;
}

.jrnlGuards-accounts-list > li > .beneficiary-name {
  min-width: 14.3125rem;
  margin-right: 1.625rem;
}

.btn-access-account {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 8.625rem;
  height: 1.5625rem;
  background-color: var(--primary-color);
  margin-right: 1.25rem;
  line-height: 1;
}

.beneficiary-modal-content {
  padding-top: 2.0938rem;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  padding-bottom: 1.9375rem;
  margin-top: 10%;
  max-width: 43.125rem;
  width: 100%;
  height: 36.125rem;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
  background-color: #fffdff;
}

.edit-beneficiary-modal-height {
  height: 31.375rem;
}

.beneficiary-form > .form-groups {
  display: flex;
}

.beneficiary-form > .form-groups > .form-group {
  margin-bottom: 0;
}

.beneficiary-form > .form-groups > .form-group > label {
  display: flex;
}

.beneficiary-form > .form-groups > .form-group:first-child {
  margin-right: 4.375rem;
}

.beneficiary-form > .available-beneficiary-content > .edit-beneficiary-margin {
  margin-top: 1.25rem;
}

.settings-form-desc {
  opacity: 0.7;
  font-size: 0.9375rem;
  font-weight: 300;
  margin-bottom: 0;
  line-height: 1;
  color: #000000;
  margin-bottom: 1.875rem;
}

.available-content-extra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.375rem;
}

.available-content-extra > .form-group {
  margin-bottom: 0;
  margin-right: 2.8125rem;
  display: flex;
  align-items: center;
}

.available-content-extra > .form-group > label {
  padding: 0;
}

.available-content-extra > p {
  opacity: 0.7;
  font-size: 0.9375rem;
  font-weight: normal;
  color: #000000;
  margin-bottom: 0;
}

.available-content-list {
  width: 100%;
  height: 12.5rem;
  border-radius: 0.625rem;
  border: dashed 0.0625rem #c2c1c2;
  background-color: #ffffff;
  margin-bottom: 1.875rem !important;
  padding-top: 0.9375rem;
  padding-left: 1.25rem;
  padding-right: 3.6875rem;
}

.available-content-list div.journal-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5938rem;
}

.available-content-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5938rem;
  min-width: 50%;
}
.available-content-list li:nth-child(odd) {
  margin-right: 2rem;
}

.available-content-list li > .icon-journals {
  margin-right: 1.25rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.available-content-list li > .journal-post-item {
  max-width: 100%;
  width: auto !important;
  padding-right: 1.8125rem;
}

.available-content-list li > .fa-check-circle {
  font-size: 1.5rem;
  color: var(--primary-color);
  opacity: 0.6;
  margin-left: auto;
}

.available-content-list li > .fa-check-circle.checked {
  opacity: 1;
}

.email-phone-form {
  display: flex;
}

.form-email-group {
  margin-right: 2.5rem;
}

.form-name-group > label,
.form-phone-group > label,
.form-email-group > label {
  display: flex;
}

.form-email-group > input {
  width: 16.5rem !important;
}

.form-phone-group > label {
  margin-bottom: 0;
}

.form-phone-group > .pd-telephone-input {
  width: 16.5rem !important;
}

.form-phone-group > .pd-telephone-input > .form-control {
  border-radius: 0 !important;
  border: none;
  border-bottom: 0.0625rem solid #d3d3d3 !important;
  height: 2.15rem !important;
}

.form-phone-group > .pd-telephone-input .pd-telephone-flag {
  padding: 0;
  margin: 0;
}

.form-phone-group
  > .pd-telephone-input
  > .pd-telephone-dropdown
  > .pd-telephone-flag
  > .flag {
  height: 1.75rem !important;
  width: 1.75rem !important;
}

.request-access-content {
  margin-top: 20%;
  max-width: 34.75rem;
  width: 100%;
  height: 20.3125rem;
  border-radius: 0.3125rem;
  background-color: #fffdff;
  padding-top: 1.4688rem;
  padding-left: 1.3125rem;
  padding-right: 1.0625rem;
}

.request-access-title {
  font-size: 1.625rem;
  font-weight: bold;
  margin-bottom: 0.875rem;
}

.request-access-body {
  font-size: 1.0625rem;
  font-weight: normal;
  line-height: 1.47;
  margin-bottom: 1.5625rem;
}

.request-access-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.request-access-actions > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
}

.request-access-actions > .btn-approve {
  width: 10.75rem;
  height: 2.1875rem;
  border-radius: 0.3125rem;
  background-color: var(--primary-color);
}
*/

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
}
.popup-arrow {
    color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
    width: 350px;
    padding: 25px 20px 25px 25px;
    background: #ffffff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25),
        0px 10px 18px rgba(0, 0, 0, 0.45);
    border-radius: 8px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.subscription_popup_modal {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;

    span {
        color: var(--primary-color);
    }

    .subscription_popup_modal_second {
        margin-top: 15px;
    }
}
