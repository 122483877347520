@import '../global-variables.scss';

/*-------------------------------------------------------------
  Wallpaper
-------------------------------------------------------------*/

.background-themes-section {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $black1;

    .background-img-showcase {
        box-shadow: $shadow-tile;
        border: 5px solid white;
        margin-bottom: 24px;
        width: fit-content;

        img {
            width: 250px;
            height: 150px;
        }
    }

    .background-themes-actions {
        display: flex;

        @include x-sm {
            flex-direction: column;
        }

        @include sm-x {
            align-items: center;
        }

        button {
            @include x-sm {
                margin-bottom: 8px;
                max-width: max-content;
            }

            @include sm-x {
                margin-right: 8px;
            }
        }
    }
}

.search-unsplash {
    border-bottom: 0.0625rem solid var(--primary-color);
    width: 18.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.375rem;
    font-size: 0.9375rem;
    font-weight: normal;
}

.btn-search-unsplash {
    font-size: 1rem;
    color: var(--primary-color);
}

input[type='search'i]::-webkit-search-cancel-button {
    appearance: none !important;
    -webkit-appearance: none !important;
}

.settings-gallery-title {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 0.2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    border-bottom: 1px solid $black2;
}

.settings-gallery-title:first-of-type {
    margin-top: 0;
}

.gallery-unsplash {
    display: grid;
    grid-gap: 16px;
    align-items: stretch;
    margin-bottom: 16px;

    @include x-sm {
        grid-template-columns: repeat(2, 1fr);
    }

    @include sm-x {
        grid-template-columns: repeat(3, 1fr);
    }

    img {
        border-radius: 4px;
        cursor: pointer;
        transition: $trans2;

        &:hover {
            transition: $trans1;
            box-shadow: 0 0 0 2px white, 0 2px 5px 3px $black4;
        }
    }
}

.gallery-unsplash > .selected-photo {
    box-shadow: 0 0 0 2px white, 0 0 0 5px var(--primary-color);
}

.change-background-modal-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40.875rem;
    height: 9.375rem;
    border-radius: 0.625rem;
    border: dashed 0.0625rem var(--primary-color);
}

.background-upload-form {
    text-align: center;
}

.background-upload-form > label {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.2;
    color: #181818;
    margin-bottom: 0.375rem;
}

.background-upload-form > label > .select-photos {
    text-decoration: none;
}

.file-details {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;
    color: #181818;
    margin-bottom: 0;
}

.background-upload-actions {
    margin-top: 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.background-upload-actions > .btn-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 5.875rem;
    height: 2.1875rem;
    border: solid 0.0625rem var(--primary-color);
    color: #000000;
    margin-right: 0.625rem;
}

.background-upload-actions > .btn-cancel:hover {
    color: #000000;
}

.background-upload-actions > .btn-save-changes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 9.9375rem;
    height: 2.1875rem;
    background-color: var(--primary-color);
    color: #ffffff;
}

.background-upload-actions > .btn-save-changes > span {
    padding-right: 0.625rem;
}

/*-------------------------------------------------------------
  Header color themes
-------------------------------------------------------------*/

.header-color-themes-section {
    .header-colors-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 32px;
        margin-bottom: 24px !important;
        border-bottom: 1px solid $black1;

        li {
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 2px;
            margin-right: 12px;
            margin-bottom: 12px;
            border: 2px solid white !important;
            box-shadow: 0 0 0 1px $black1, 0 2px 3px $black3;
            transition: $trans1;

            &:hover {
                box-shadow: 0 0 0 2px var(--primary-color);
                transition: $trans2;
            }

            &.chosen-color {
                box-shadow: 0 0 0 2px black;
            }
        }
    }
}

.theme-light {
    background-color: white;

    .header-nav {
        > ul {
            > .header-nav__item {
                > .header-nav__link {
                    color: black;

                    img {
                        filter: var(--filter-black);
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            filter: var(--filter-primary);
                        }

                        &:after {
                            background: var(--primary1);
                        }
                    }
                }

                &.active {
                    img {
                        filter: var(--filter-primary);
                        opacity: 1;
                    }

                    .header-nav__link {
                        border-bottom-color: var(--primary-color);
                    }
                }
            }

            .nav__tools--icon {
                > button {
                    img {
                        filter: var(--filter-black);
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                            filter: var(--filter-primary);
                        }
                    }
                }
            }
        }
    }
}

.theme-medium {
    .header-nav {
        > ul {
            > .header-nav__item {
                > .header-nav__link {
                    color: black;

                    img {
                        filter: var(--filter-delta);
                        opacity: 0.4;
                    }

                    &:hover {
                        img {
                            filter: var(--filter-delta);
                            opacity: 1;
                        }

                        &:after {
                            background: $white2;
                        }
                    }

                    .header-nav__title {
                        font-weight: 400;
                    }
                }

                &.active {
                    img {
                        opacity: 1;
                    }

                    .header-nav__link {
                        border-bottom-color: var(--delta);

                        .header-nav__title {
                            font-weight: 500;
                        }
                    }
                }
            }

            .nav__tools--icon {
                > button {
                    img {
                        filter: var(--filter-delta);
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.theme-dark {
    .header-nav {
        > ul {
            > .header-nav__item {
                > .header-nav__link {
                    color: white;

                    img {
                        filter: var(--filter-white);
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            filter: var(--filter-white);
                            opacity: 1;
                        }

                        &:after {
                            background: $white2;
                        }
                    }

                    .header-nav__title {
                        font-weight: 400;
                    }
                }

                &.active {
                    img {
                        opacity: 1;
                    }

                    .header-nav__link {
                        border-bottom-color: white;

                        .header-nav__title {
                            font-weight: 500;
                        }
                    }
                }
            }

            .nav__tools--icon {
                > button {
                    img {
                        filter: var(--filter-white);
                        opacity: 0.6;
                    }

                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

/*--------------------------------
Default theme
--------------------------------*/

.color-default,
.theme-default {
    background-color: white;
}

/*--------------------------------
Black theme
--------------------------------*/

.color-black,
.theme-black {
    background-color: black;
}

/*--------------------------------
  Gray theme
--------------------------------*/

.color-gray,
.theme-gray {
    background: rgb(34, 34, 34);
    background: -moz-linear-gradient(
        5deg,
        rgba(34, 34, 34, 1) 0%,
        rgba(119, 119, 119, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(34, 34, 34, 1) 0%,
        rgba(119, 119, 119, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(34, 34, 34, 1) 0%,
        rgba(119, 119, 119, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#222222", endColorstr="#777777", GradientType=1);
}

/*--------------------------------
  Blue theme
--------------------------------*/

.color-blue,
.theme-blue {
    background: rgb(0, 15, 50);
    background: -moz-linear-gradient(
        5deg,
        rgba(0, 15, 50, 1) 0%,
        rgba(0, 100, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(0, 15, 50, 1) 0%,
        rgba(0, 100, 255, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(0, 15, 50, 1) 0%,
        rgba(0, 100, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000f32", endColorstr="#0064ff", GradientType=1);
}

/*--------------------------------
  Teal theme
--------------------------------*/

.color-teal,
.theme-teal {
    background: rgb(0, 100, 255);
    background: -moz-linear-gradient(
        5deg,
        rgba(0, 100, 255, 1) 0%,
        rgba(0, 215, 240, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(0, 100, 255, 1) 0%,
        rgba(0, 215, 240, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(0, 100, 255, 1) 0%,
        rgba(0, 215, 240, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0064ff", endColorstr="#00d7f0", GradientType=1);
}

/*--------------------------------
  Mint theme
--------------------------------*/

.color-mint,
.theme-mint {
    background: rgb(0, 132, 162);
    background: -moz-linear-gradient(
        5deg,
        rgba(0, 132, 162, 1) 0%,
        rgba(32, 201, 153, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(0, 132, 162, 1) 0%,
        rgba(32, 201, 153, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(0, 132, 162, 1) 0%,
        rgba(32, 201, 153, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0084a2", endColorstr="#20c999", GradientType=1);
}

/*--------------------------------
  Green theme
--------------------------------*/

.color-green,
.theme-green {
    background: rgb(0, 99, 53);
    background: -moz-linear-gradient(
        5deg,
        rgba(0, 99, 53, 1) 0%,
        rgba(74, 214, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(0, 99, 53, 1) 0%,
        rgba(74, 214, 0, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(0, 99, 53, 1) 0%,
        rgba(74, 214, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#006335", endColorstr="#4ad600", GradientType=1);
}

/*--------------------------------
Gold theme
--------------------------------*/

.color-gold,
.theme-gold {
    background: rgb(123, 70, 39);
    background: -moz-linear-gradient(
        5deg,
        rgba(123, 70, 39, 1) 0%,
        rgba(222, 185, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(123, 70, 39, 1) 0%,
        rgba(222, 185, 0, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(123, 70, 39, 1) 0%,
        rgba(222, 185, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7b4627", endColorstr="#deb900", GradientType=1);
}

/*--------------------------------
Orange theme
--------------------------------*/

.color-orange,
.theme-orange {
    background: rgb(244, 68, 0);
    background: -moz-linear-gradient(
        5deg,
        rgba(244, 68, 0, 1) 0%,
        rgba(255, 147, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(244, 68, 0, 1) 0%,
        rgba(255, 147, 0, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(244, 68, 0, 1) 0%,
        rgba(255, 147, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f44400", endColorstr="#ff9300", GradientType=1);
}

/*--------------------------------
  Pink theme
--------------------------------*/

.color-pink,
.theme-pink {
    background: rgb(194, 0, 163);
    background: -moz-linear-gradient(
        5deg,
        rgba(194, 0, 163, 1) 0%,
        rgba(244, 0, 56, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(194, 0, 163, 1) 0%,
        rgba(244, 0, 56, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(194, 0, 163, 1) 0%,
        rgba(244, 0, 56, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c200a3", endColorstr="#f40038", GradientType=1);
}

/*--------------------------------
Violet theme
--------------------------------*/

.color-violet,
.theme-violet {
    background: rgb(130, 40, 210);
    background: -moz-linear-gradient(
        5deg,
        rgba(130, 40, 210, 1) 0%,
        rgba(224, 0, 207, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(130, 40, 210, 1) 0%,
        rgba(224, 0, 207, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(130, 40, 210, 1) 0%,
        rgba(224, 0, 207, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8228d2", endColorstr="#e000cf", GradientType=1);
}

/*--------------------------------
  Red theme
--------------------------------*/

.color-red,
.theme-red {
    background: rgb(115, 0, 0);
    background: -moz-linear-gradient(
        5deg,
        rgba(115, 0, 0, 1) 0%,
        rgba(214, 0, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
        5deg,
        rgba(115, 0, 0, 1) 0%,
        rgba(214, 0, 0, 1) 100%
    );
    background: linear-gradient(
        5deg,
        rgba(115, 0, 0, 1) 0%,
        rgba(214, 0, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#730000", endColorstr="#d60000", GradientType=1);
}

/*-------------------------------------------------------------
  Font size
-------------------------------------------------------------*/

.font-size-themes-section {
    padding-bottom: 32px;
    margin-bottom: 24px !important;
    border-bottom: 1px solid $black1;
}

.font-slide-container {
    max-width: 280px;
    margin-bottom: 1.5625rem;

    .font-size-list-indicators {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 4px !important;

        li {
            color: $black6;
            font-weight: 500;

            &.light-font {
                font-size: 12px;
            }

            &.medium-font {
                font-size: 16px;
            }

            &.strong-font {
                font-size: 20px;
            }
        }
    }
}

/*--------------------------------
  Font size slider
--------------------------------*/

.font-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    background: $black1;
    box-shadow: inset 0px 1px 2px $black3;
    outline: none;
    position: relative;
    border-radius: 100px;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 22px;
        height: 22px;
        border: 2px solid white;
        box-shadow: 0 1px 4px $black6;
        border-radius: 50%;
        background-color: var(--primary-color);
        cursor: pointer;
        transition: $trans1;

        &:hover {
            filter: var(--hover);
            transition: $trans2;
            transform: scale(1.1);
        }
    }

    &::-moz-range-thumb {
        width: 22px;
        height: 22px;
        border: 2px solid white;
        box-shadow: 0 1px 4px $black6;
        border-radius: 50%;
        background-color: var(--primary-color);
        cursor: pointer;
        transition: $trans1;

        &:hover {
            filter: var(--hover);
            transition: $trans2;
            transform: scale(1.1);
        }
    }
}

/*--------------------------------
  Font size content test
--------------------------------*/

.font-size-content-test {
    max-width: 500px;
    box-shadow: $dropdown-shadow;
    border-radius: 4px;
    padding: 20px;
    margin: 16px 0 0;

    h4 {
        font-weight: normal;
        margin-bottom: 8px;
    }

    p {
        font-weight: normal;
        color: black;
        line-height: 1.4;
        margin-bottom: 0 !important;
    }
}

/*-------------------------------------------------------------
  Customize emoticons
-------------------------------------------------------------*/

.emoticons-themes-section {
    > .emoticons-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & li.emoticons-list-item {
            width: 55px;
            height: 55px;
            border-radius: 4px;
            box-shadow: 0 0 0 1px $black1, 0 1px 5px $black3;
            background-color: #fffdff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            margin-bottom: 12px;
            position: relative;
            transition: $trans1;

            &:hover {
                transition: $trans2;
                box-shadow: 0 0 0 2px var(--primary-color), 0 1px 5px $black3;
            }

            img {
                width: 30px;
                height: 30px;
            }
        }

        span {
            font-size: 0.75rem;
            position: absolute;
            right: 0.1625rem;
            bottom: 0;
        }

        .dropdown {
            margin-right: 12px;
            margin-bottom: 12px;
        }

        .dropdown {
            .dropdown-toggle {
                width: 55px;
                height: 55px;
            }

            .dropdown-emoticon-menu {
                .dropdown-emoticon-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    & li {
                        margin: 6px;
                        padding: 4px !important;
                        border-radius: 4px;
                        transition: $trans1;
                        cursor: pointer;
                        min-width: inherit;
                        width: inherit;

                        &:hover {
                            transition: $trans2;
                            box-shadow: 0 0 0 2px var(--primary-color);
                        }

                        &.disabled {
                            box-shadow: none;
                            cursor: default;
                        }

                        img {
                            margin: 0;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    &.emoticon-used,
                    .disabled {
                        opacity: 0.6;
                    }
                }

                margin-top: 41px;
            }
        }
    }
}
