@import '../global-variables.scss';

/*-------------------------------------------------------------
  Footer
-------------------------------------------------------------*/

footer {
    padding: 4em 0;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            transition: $trans1;

            &:hover {
                transition: $trans2;
            }
        }
    }
}

.footer__row {
    margin: 0.5em 0;
}

.footer__logo {
    max-width: 260px;
}

.footer__apps {
    display: flex;

    a {
        margin: 8px;

        &:hover {
            opacity: 0.9;
            transform: scale(1.03);
        }
    }
}

.footer__links {
    text-align: center;

    li {
        display: inline-block;

        a {
            padding: 6px 8px;
            text-transform: uppercase;
            font-size: 13px;
            color: black;
            display: block;

            &:hover {
                color: var(--primary-color);
            }
        }
    }
}

.footer__social {
    display: flex;

    a {
        padding: 8px;
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px;

        &:hover {
            transform: scale(1.1);
        }

        img {
            filter: var(--filter-white);
            width: 20px;
            height: 20px;
        }
    }
}

.footer__facebook {
    @extend .facebook-bg;
}

.footer__twitter {
    @extend .twitter-bg;
}

.footer__instagram {
    @extend .instagram-bg--gradient;
}

.footer__copyright {
    font-size: 12px;
    color: $black6;

    span {
        padding: 0 6px;
        color: $black4;
        display: inline-block;
    }

    a {
        color: $black6;

        &:hover {
            color: var(--primary-color);
        }
    }
}
