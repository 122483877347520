@import '../global-variables.scss';

/*-------------------------------------------------------------
  Contacts page
-------------------------------------------------------------*/

.no-contacts {
    margin: 24px;
    text-align: center;
    color: $black4;
}

/*--------------------------------
  Contacts import banner
---------------------------------*/

.contacts-import-banner {
    box-shadow: 0 0 0 1px $black2;
    border-radius: 4px;
    padding: 16px;
    margin: 0 16px 16px;

    .contacts-import-banner__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        .contacts-import-banner__title {
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    p {
        line-height: 1.4;
    }

    .contacts-import-banner__close {
        cursor: pointer;
    }
}

/*--------------------------------
  Import banner media list
---------------------------------*/

.import-banner-media-list {
    display: flex;
    align-items: center;
    margin: 0;

    li {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 0 0 1px $black2;
        transition: $trans1;

        img {
            width: 50%;
            height: auto;
        }

        &:hover {
            box-shadow: 0 0 0 2px var(--primary-color);
            transition: $trans2;
        }
    }
}

.contacts-added-list {
    li {
        display: flex;
        align-items: center;
        img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            margin-right: 0.9375rem;
        }
        &:not(:last-child) {
            margin-bottom: 0.9375rem;
        }
    }
    .contact-email {
        font-family: 'Roboto';
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1.2;
        color: #9e9e9e;
        margin-bottom: 0;
    }
    .contact-name {
        font-family: 'Cabin';
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.2;
        color: #1e1e1e;
        margin-bottom: 0;
    }
}
