@import '../global-variables.scss';

/*-------------------------------------------------------------
  Options Bar
-------------------------------------------------------------*/

.options-bar {
    display: flex;
    align-content: flex-start;
    background: white;
    box-shadow: $shadow-frame;
    position: relative;
    z-index: 5;
    height: 40px;
    position: fixed;
    width: 100%;

    @include x-md {
        top: 50px;
    }
    @include md-x {
        top: 60px;
    }

    .options-bar__btn {
        border: none;
        outline: none;
        background: none;
        width: 40px;
        height: 40px;
        opacity: 0.6;
        transition: $trans2;
        filter: var(--filter-black);
        flex: none;

        &:hover {
            transition: $trans2;
            opacity: 1;
            filter: var(--filter-primary);
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    // .settings-button {
    //     border: 2px solid blue;
    //     float: left;
    // }

    .options-bar__gradient {
        position: absolute;
        width: 20px;
        height: 30px;
    }

    .options-bar__gradient--left {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        left: 0;
    }

    .options-bar__gradient--right {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
        right: 0;
    }

    .options-bar__title {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        margin: 0 16px;
        flex: auto;

        .options-bar__content {
            font-size: 0.9em;
            font-weight: 500;
            margin: 0 3px;
            padding: 5px 8px;
            border-radius: 3px;
            display: inline-block;
        }

        .options-bar__content--dates,
        .options-bar__content--journals,
        .options-bar__content--tags {
            font-size: 12px;
            color: white;
        }

        .options-bar__content--dates {
            background-color: var(--primary-color);
        }

        .options-bar__content--journals {
            background-color: var(--beta);
        }

        .options-bar__content--tags {
            background-color: var(--gamma);
        }
    }
}

/*-------------------------------------------------------------
  Back Bar
-------------------------------------------------------------*/

.back-bar {
    background: white;
    box-shadow: $shadow-tile;
    position: relative;

    @media (min-width: 680px) {
        margin-bottom: 0.5em;
        border-radius: 4px;
    }

    .btn {
        padding: 0.8em;

        &:hover {
            color: var(--primary);
        }
    }
}

.back-bar--settings {
    @include sm-x {
        display: none;
    }
}
