@import '../global-variables.scss';

/*-------------------------------------------------------------
  Notifications
-------------------------------------------------------------*/

.notif {
    .notif__content {
        .notif__form {
        }
    }
}

.notification-timestamp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: auto;
    color: #212529;
}
