@import '../global-variables.scss';

/*-------------------------------------------------------------
  Thank you page
-------------------------------------------------------------*/

.static-page {
  position: relative;
  height: auto;
  width: auto;
}

.logo-img {
  width: 8.8125rem;
  height: 2.5rem;
}

.thank-you-footer > .logo-img {
  margin-right: 3.125rem;
}

.thank-you-header {
  width: 100vw;
  height: 4rem;
  box-shadow: 0 0.125rem 2.375rem -0.9375rem rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 17.8125rem;
}

.thank-you-footer {
  display: flex;
  align-items: center;
  padding-left: 17.8125rem;
  height: 4rem;
  box-shadow: 0 0.125rem 2.375rem -0.9375rem rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

.thank-you-footer-bg {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.thank-you-header-bg {
  position: absolute;
  top: 4rem;
  z-index: -1;
}

.thank-you-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.thank-you-card {
  max-width: 43.75rem;
  width: 100%;
  height: 15.625rem;
  opacity: 0.85;
  border-radius: 0.9375rem;
  background-color: #ffffff;
  padding-top: 4.0625rem;
  padding-left: 5.3125rem;
  padding-bottom: 4.0625rem;
  padding-right: 5.0625rem;
}

.thank-you-card-title {
  font-size: 2.25rem;
  font-weight: normal;
  color: #00416a;
  margin-bottom: 0.75rem;
}

.thank-you-card-text {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.6;
  margin-bottom: 0;
}

.thank-you-footer-list {
  display: flex;
  align-items: center;
}

.thank-you-footer-list > li {
  font-size: 0.875rem;
  font-weight: normal;
  color: #00416a !important;
  border-bottom: 0.0625rem solid #00416a;
  margin-right: 1.25rem;
}

.thank-you-footer-list > li > a {
  color: #00416a !important;
}

.thank-you-footer-list > li:first-child {
  position: relative;
}

.thank-you-footer-list > li:first-child::after {
  content: '|';
  position: absolute;
  right: -0.75rem;
  color: #00416a !important;
}

/*  THANK YOU PAGE MEDIA QUERIES */

@media (max-width: 1350px) {
  .graphic-first-plan-table::before {
      right: -15%;
  }
}

@media (max-width: 1024.99px) {
  .graphic-first-plan-table::before,
  .graphic-second-plan-table::before {
      content: none;
  }
}

@media (max-width: 989.99px) {
  .choose-account > .container {
      max-width: 100vw !important;
      margin-right: 0;
      margin-left: 0;
  }

  .comparison-table-content > .col-4,
  .col-4.comparison-bg {
      min-width: 33%;
  }
}

@media (max-width: 900.99px) {
  .choose-account-card {
      max-width: 22.4375rem;
  }
}

@media (max-width: 850.99px) {
  .choose-account-card {
      max-width: 40%;
      height: 35.25rem;
  }

  .comparison-actions > .col-4 {
      min-width: 33% !important;
  }

  .comparison-actions > .col-4 > .btn {
      width: 100% !important;
  }

  .btn-plan {
      width: 100% !important;
  }
}

.thank-you-footer-list > li > a {
  color: #00416a !important;
}

.thank-you-footer-list > li:first-child {
  position: relative;
}

.thank-you-footer-list > li:first-child::after {
  content: '|';
  position: absolute;
  right: -0.75rem;
  color: #00416a !important;
}

/*  THANK YOU PAGE MEDIA QUERIES */

@media (max-width: 1350px) {
  .graphic-first-plan-table::before {
      right: -15%;
  }
}

@media (max-width: 1024.99px) {
  .graphic-first-plan-table::before,
  .graphic-second-plan-table::before {
      content: none;
  }
}

@media (max-width: 989.99px) {
  .choose-account > .container {
      max-width: 100vw !important;
      margin-right: 0;
      margin-left: 0;
  }

  .comparison-table-content > .col-4,
  .col-4.comparison-bg {
      min-width: 33%;
  }
}

@media (max-width: 900.99px) {
  .choose-account-card {
      max-width: 22.4375rem;
  }
}

@media (max-width: 850.99px) {
  .choose-account-card {
      max-width: 40%;
      height: 35.25rem;
  }

  .comparison-actions > .col-4 {
      min-width: 33% !important;
  }

  .comparison-actions > .col-4 > .btn {
      width: 100% !important;
  }

  .btn-plan {
      width: 100% !important;
  }
}

@media (max-width: 800px) {
  .choose-account-divider {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
  }

  .comparison-plan-content {
      min-width: 33%;
  }
}

@media (max-width: 776px) {
  .choose-account-card {
      max-width: 90%;
  }

  .or-card {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }

  .choose-account-divider::before,
  .choose-account-divider::after {
      content: none;
  }
}

@media (max-width: 420px) {
  .thank-you-header {
      padding-left: 0;
      justify-content: center;
  }

  .thank-you-header-bg > img {
      height: 31.25rem;
  }

  .thank-you-section {
      margin-top: 2rem;
      margin-bottom: 2rem;
  }

  .thank-you-card {
      padding: 1rem;
      text-align: center;
      height: 12rem;
  }

  .choose-account-card {
      max-width: 100%;
  }

  .comparison-plan-content {
      padding: 0.5rem;
  }

  .save-memories-card {
      padding-left: 0;
      padding-right: 0;
      height: 13.5rem;
  }

  .comparison-plan-title {
      font-size: 1rem;
  }

  .comparison-plan-info {
      font-size: 0.75rem;
  }

  .save-memories-card > img {
      margin-right: 1rem;
      width: 7.5rem;
  }

  .save-memories-card-title {
      font-size: 1.35rem;
  }

  .save-memories-card-text {
      font-size: 1rem;
  }

  .comparison-plan-content {
      min-width: 33%;
  }

  .col-4.comparison-bg,
  .col-8.comparison-bg {
      min-width: 33% !important;
  }

  .comparison-bg > p {
      font-size: 1.5rem;
  }

  .comparison-actions > .col-4 > .btn-get-free {
      margin-bottom: 1rem;
  }

  .comparison-table-content > .col-4 {
      min-width: 7.5rem;
  }

  .comparison-table-title {
      font-size: 1.5rem;
  }

  .comparison-table-text {
      font-size: 1.25rem;
  }

  .comparison-table-text > span {
      font-size: 1rem;
  }

  .btn-plan {
      width: 100%;
  }

  .thank-you-footer {
      padding-left: 0;
      justify-content: center;
  }

  .thank-you-footer-bg > img {
      height: 31.25rem;
      width: auto;
  }

  .comparison-actions > .col-4 > .btn {
      font-size: 1rem !important;
  }

  .get-save-text {
      font-size: 0.75rem;
  }
}