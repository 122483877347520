@import '../global-variables.scss';

/*-------------------------------------------------------------
  Data Deletion
-------------------------------------------------------------*/

.data-deletion__content {
    padding: 0 16px 16px;
}

.data-deletion__image {
    margin-bottom: 2em;
}
