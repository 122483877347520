/* MEDIA PAGE STYLES */

.media-navbar-section {
    margin-top: 3rem;
    padding-top: 2rem;
}

.media-viewer-section {
    display: block;
    position: fixed;
    /* Stay in place */
    z-index: 9999;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 1);
    /* Fallback color */
    background-color: rgba(38, 38, 38, 0.9);
}

.media-viewer-section .btn {
    color: #104d75;
}

.media-viewer-section .btn:hover {
    color: var(--primary-color);
}
.media-navbar {
    max-width: 73.75rem !important;
    width: 100%;
    height: 6.25rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
    padding-top: 0.8125rem;
    display: flex;
}

.media-search-navbar {
    max-width: 73.75rem !important;
    width: 100%;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-right: 1.8125rem;
    padding-left: 1.875rem;
    padding-top: 0.8125rem;
    display: flex;
    margin-bottom: 16px;
}

.media-search-filter {
    max-width: 10rem;
    width: 100%;
    margin-left: 0.75rem;
}

.media-navbar-fade {
    position: relative;
}

.media_map_view {
    height: 250px;
}

.media-navbar-fade::after {
    content: '';
    position: absolute;
    right: 10.3125rem;
    top: 0;
    width: 7.75rem;
    height: 6.25rem;
    background-image: linear-gradient(
        to right,
        rgba(255, 253, 255, 0),
        #fffdff 80%
    );
}

.media-contacts {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll;
    width: 100%;
    max-width: 65.8125rem;
    margin-right: 2.5rem;
}

.media-contacts > li {
    display: inline;
    text-align: center;
    margin-right: 2.5625rem;
}

.media-contacts > li > img {
    max-width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.media-contact-name {
    margin-top: 0.5625rem;
    font-family: 'Roboto';
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 3rem;
}

.dropdown-new-media {
    max-width: 8.5313rem !important;
    width: 100%;
    margin-top: 1.25rem;
}

.btn-new-media {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    font-family: 'Roboto';
    font-size: 0.9375rem !important;
    font-weight: 500;
    color: #ffffff;
}

.btn-new-media:hover,
.btn-new-media:focus,
.btn-new-media:hover span::before,
.btn-new-media:focus span::before,
.btn-new-media span::before {
    color: #ffffff;
}

.btn-new-media > span {
    margin-right: 0.625rem;
}

.media-viewer-section > .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.media-fullscreen {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

.media-fullscreen > img {
    max-width: 82.5rem;
    width: 100%;
    height: 46.0625rem;
    border-radius: 0.3125rem;
}

// .media-fullscreen {
//     padding-left: 0;
//     padding-right: 0;
//     position: relative;
// }

.media-fullscreen .add-media-box {
    margin-bottom: 1.25rem;
    position: relative;

    video {
        height: 85vh;
        background-color: #000;
    }
}

.add-media-box {
    & > video {
        width: 100%;
        max-height: 35rem;
        background-color: #000;
    }
}

.media-fullscreen .audio-visual-holder ~ .add-media-box {
    margin-top: 0;
}

.media-fullscreen .add-media-player {
    width: 100%;
    height: auto;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
}

.media-fullscreen .audio-controls {
    width: 33.25rem;
}

.video-controls {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* width: 33.25rem; */
    height: 2.5rem;
    border: solid 0.0625rem #d5d5d5;
    border-radius: 0 0 0.3125rem 0.3125rem;
    border-top-color: transparent !important;
    background-color: #ffffff;
}

.img-container {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 0.3125rem;
    padding: 3rem;

    img {
        height: 85vh;
        margin-left: auto;
        margin-right: auto;
        display: flex;
    }
}

.face_detection_svg {
    width: 100%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
}

.media-sidebar {
    min-width: 28rem;
    height: 100vh;
    border: solid 0.0625rem #707070;
    background-color: #ffffff;
    border-radius: 0.625rem 0 0 0.625rem;
    padding: 1.5625rem;
    overflow: scroll;
}

.media-sidebar-header-content {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.media-sidebar-title {
    font-family: 'Cabin';
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.11;
    color: #000000;
    margin-bottom: 0;
}

.media-sidebar-subtitle {
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: normal;
    color: #000000;
    line-height: 1.11;
    margin-bottom: 0;
}

.media-map {
    width: 28.125rem;
    height: 12.5rem;
    border-radius: 0.625rem;
}

.media-desc-section {
    margin-bottom: 1.875rem;

    .crop_image_list {
        display: flex;

        .circle_image_text {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }

        .add_tag_button {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.media-desc-content {
    font-family: 'Roboto';
    font-size: 1.0625rem;
    font-weight: normal;
    color: #000000;
}

.btn-add-location,
.btn-edit-media {
    color: #05416a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.btn-add-location:hover,
.btn-edit-media:hover {
    color: #05416a;
}

.btn-edit-media > span {
    padding-left: 0.625rem;
}

.btn-add-location > span {
    padding-left: 0.625rem;
    color: #05416a;
}

.media-location-section,
.media-people-section {
    margin-bottom: 1.25rem;
}

.media-people-list {
    display: flex;
}

.media-people-list > li {
    margin-right: 1.4375rem;
    text-align: center;
}

.media-people-list > li > img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.625rem;
    border-radius: 50%;
}

.media-people-contact-name {
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: 500;
    margin-bottom: 0;
}

.media-people-list > li:last-child {
    margin-left: 1.0625rem;
}

.btn-add-tag {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    background-color: var(--primary-color);
    border-radius: 50% !important;
    font-size: 2rem !important;
    margin-bottom: 0.625rem;
}

.media-sidebar-header {
    background: white;
    position: sticky;
    top: 0;
    padding-top: 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.25rem;

    .add__entry {
        display: flex;
    }
}

.btn-add-to-entry {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    color: #0e4d75;
}

.btn-add-to-entry:hover {
    color: #0e4d75;
}

.btn-add-to-entry > span {
    margin-left: 0.625rem;
    color: #0e4d75;
}

.btn-right {
    justify-content: end;
}

.btn-exit-media {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    color: #000000;
    margin-left: auto;

    small {
        margin-left: 5px;
    }
}

.btn-exit-media:hover {
    color: #000000;
}

.btn-exit-media > span {
    margin-left: 0.625rem;
}

.media-entry {
    width: 100%;
    height: auto;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding: 0.9375rem;
    margin-bottom: 0.625rem;
}

.media-entry-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.8125rem;
}

.media-entry-header > img {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border: solid 0.0625rem #707070;
    margin-right: 0.9375rem;
}

.media-entry-content-title {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.3125rem;
    color: #000000;
}

.media-entry-content-text {
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.14;
    margin-bottom: 0;
}

.media-entry-actions {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.media-entry-title {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 300;
    color: #000000;
    margin-bottom: 0.25rem;
}

.media-entry-title > span {
    font-weight: 500;
}

.media-entry-info {
    font-family: 'Roboto';
    font-size: 0.75rem;
    font-weight: 300;
    color: #000000;
    margin-bottom: 0;
}

.media-details-list > li {
    display: flex;
    align-items: center;
    margin-bottom: 1.3125rem;
}

.media-details-list > li > span {
    margin-right: 1rem;
}

.media-detail {
    font-family: 'Roboto';
    font-size: 1.0625rem;
    font-weight: 300;
    color: #000000;
    margin-bottom: 0;
}

.media-detail > span {
    padding-left: 1.25rem;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: 300;
}

.media-gallery {
    max-width: 73.75rem !important;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.media-gallery-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.media-gallery-list > li > img {
    width: 17.5rem;
    height: 16.6875rem;
    border-radius: 0.625rem;
}

.media-gallery-list > li {
    border-radius: 0.625rem;
    margin-bottom: 0.625rem;
}

.media-gallery-list > li:nth-child(4n + 0) {
    margin-right: 0;
}

.media-photo-availability {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.3125rem;

    .media-checkmark {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.media-checkmark {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem var(--primary-color);
    position: relative;
}

.media-checkmark:checked {
    background-color: var(--primary-color);
}

.media-checkmark:checked::after {
    position: absolute;
    content: '\f00c';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    left: 0.1rem;
}

.media-extra-info {
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    opacity: 0.8;
    margin-left: 1.8rem;
}

.various-entries {
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 0 !important;
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 1.875rem !important;
    border-bottom: 0.0625rem solid var(--primary-color);
}

.various-entries:hover {
    color: var(--primary-color) !important;
}

.add-files-various-entries-modal,
.add-files-entries-modal,
.various-entries-modal {
    display: flex;
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 1);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    border-radius: 0.325rem;
}

.various-entries-content {
    margin-top: 2%;
    max-width: 500px;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-top: 1.125rem;
    padding-left: 0;
    padding-right: 0;
}

.various-entries-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    padding-left: 1.5625rem;
    padding-right: 0.9375rem;
}

.various-entries-content-title {
    font-family: 'Cabin';
    font-size: 1.625rem;
    font-weight: bold;
}

.various-entries-list {
    padding-left: 1.5625rem;
    padding-right: 0.9375rem;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 40.3125rem;
    padding-top: 0.5rem;

    .active {
        cursor: pointer;
        border-radius: 0.3125rem;
        border: solid 0.3125rem var(--primary-color);
    }
}

.various-entries-list > li {
    border: solid 0.3125rem transparent;
}

.various-entries-list > li:hover {
    cursor: pointer;
    border-radius: 0.3125rem;
    border: solid 0.3125rem var(--primary-color);
}

.chosen-media-entry {
    border: solid 0.3125rem var(--primary-color);
}

.various-entries-list::-webkit-scrollbar {
    width: 0.3125rem;
    margin-right: 0.7188rem;
}

.various-entries-list::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.25rem;
    margin: 0.625rem;
}

.various-entries-list::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.25rem;
}

.various-entries-list::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.75);
}

.add-file-to-journal-form {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
    margin-bottom: 2rem;
}

.add-file-to-journal-form > label {
    display: flex;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    padding: 0;
    margin-bottom: 0.6rem;
    color: #8f8f8f;
}

.add-file-to-journal-form > select {
    width: 100%;
}

.media-select {
    font-family: 'Roboto';
    font-size: 1.125rem;
    font-weight: normal;
    color: #000000;
    border: none;
    border-bottom: 0.0625rem solid #d5d5d5;
}

.add-file-various-entries-actions {
    display: flex;
    align-items: center;
    padding-left: 1.5625rem;
    padding-bottom: 1.25rem;
    margin-top: 1.25rem;
}

.add-file-various-entries-actions > .btn-cancel {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.4063rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: #fffdff;
    color: #000000;
    margin-right: 0.375rem;
}

.add-file-various-entries-actions > .btn-add-to-entry {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.625rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    color: #ffffff;
}

.add-file-various-entries-actions > .btn-add-to-entry:hover {
    color: #ffffff;
}

.add-file-various-entries-actions > .btn-add-to-entry > span {
    color: #ffffff;
    padding-right: 0.625rem;
    margin-left: 0;
}

.journals-entries-form-list {
    margin-left: 16px;
}

.journals-entries-form-list > li {
    display: flex;
    align-items: center;
    margin-top: 0.625rem;
}

.journals-entries-form-list > li > span {
    cursor: pointer;
}

.journals-entries-form-list > li > .trash-icon {
    font-size: 1.25rem;
    padding-left: 0.625rem;
}

.journals-entries-form-list > li > .add-icon {
    color: var(--primary-color);
    font-size: 1.25rem;
    padding-left: 1.25rem;
}

.add-photos-media-form-group {
    margin-right: 0.9375rem;
}

.journal-select-width > .media-select {
    width: 11.875rem !important;
}

.entry-select-width > .media-select {
    width: 14.375rem !important;
}

.add-photos-media-form-group > label {
    display: flex;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    color: #8f8f8f;
    padding: 0 !important;
    margin-bottom: 0.5rem;
}

.add-photos-media-actions {
    display: flex;
    align-items: center;
    margin-top: 1.875rem;
    margin-left: 16px;
}

.add-photos-media-actions > .btn-cancel {
    width: 6.4063rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: #fffdff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.375rem;
}

.add-photos-media-actions > .btn-add-photos {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.625rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    color: #ffffff;
}

.add-photos-media-actions > .btn-add-photos > span {
    padding-right: 0.625rem;
}

.contacts-photos-list,
.files-to-upload {
    display: flex;
    flex-wrap: wrap;
}

.files-to-upload > li {
    margin-right: 0.625rem;
    margin-top: 0.625rem;
}

.contacts-photos-list > li {
    margin-right: 0.625rem;
    margin-top: 0.625rem;
    position: relative;
}

.contacts-photos-list > .single-photo > .icon-trash {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    font-size: 0.6rem;
    opacity: 1;
}

.media-navbar-section .media-dropdown-menu {
    transform: translate3d(-5.125rem, 3rem, 0) !important;
}

.media-dropdown-menu-media-fix:after {
    top: -0.8125rem;
    left: unset !important;
    right: 8.125rem !important;
}

.media-controls {
    height: 4.4375rem;
}

.media-controls > .btn-play,
.media-controls > .btn-mute,
.media-controls > .btn-fullscreen {
    font-size: 2rem !important;
    height: auto !important;
}

.media-controls > .video-duration {
    font-size: 1.5rem !important;
}

.media-controls > .video-progress-bar,
.media-controls > .audio-progress-bar {
    width: 60rem !important;
}

.audio-visual-holder {
    width: 81.875rem;
    height: 46.0625rem;
    border-radius: 0.3125rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-visual-img {
    width: 18.125rem;
}

.media-file-showcase {
    max-width: 100%;
    width: 81.875rem;
    height: 46.0625rem;
    border-radius: 0.3125rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.media-file-showcase > .icon-file {
    font-size: 8rem;
    color: #1db5cc;
    margin-bottom: 2.5rem;
}

.media-file-desc {
    font-family: 'Roboto';
    font-size: 1.75rem;
    font-weight: 500;
    color: #1db5cc;
    margin-bottom: 3.1875rem;
}

.btn-download-file {
    width: 17.9375rem;
    height: 3.475rem;
    border-radius: 0.3125rem;
    background-color: #00416b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #ffffff !important;
    font-size: 1.25rem !important;
}

.btn-download-file > span {
    padding-left: 1.5625rem;
}

.media-opacity {
    opacity: 0.9;
}

.media-input-desc {
    border-bottom: 0.0625rem solid #000 !important;
    font-family: 'Roboto';
    font-size: 1.125rem;
    font-weight: 300;
    width: 100% !important;
    height: 5rem;
}

.media-tag-person {
    position: absolute;
    top: 14.4375rem;
    left: 38.8125rem;
}

.media-tag-person > input {
    min-width: 9.375rem;
    width: 100%;
    height: 1.9375rem;
    border-radius: 0.3125rem;
    background-color: #05416a;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-align: center;
}

.media-tag-person > input::placeholder {
    color: #ffffff;
    text-align: center;
}

.tag-content {
    position: absolute;
    width: 6.5rem;
    height: 6.5rem;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    border: solid 0.25rem #05416a;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
}
.tag-icon {
    cursor: pointer;
}
.tag-icon:hover {
    color: var(--primary-color);
}

.tag-people-info {
    opacity: 0.8;
    font-family: 'Roboto';
    font-size: 1.125rem;
    font-weight: 300;
    color: #000000;
    margin-top: 1.25rem;
    margin-bottom: 0;
    padding-right: 2.1875rem;
}

.media-user {
    display: flex;
    align-items: center;
}

.media-user-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.3125rem;
}

.media-user > img {
    margin-right: 1.25rem;
    width: 4.4375rem;
    height: 4.4375rem;
}

.media-user-name {
    font-family: 'Roboto';
    font-size: 1.3125rem;
    font-weight: 500;
    margin-bottom: 0;
}

.media-redirect {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: normal;
    color: #05416a;
    margin-left: 1.5625rem;
}

.media-user-collection {
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 300;
    color: #000000;
    margin-bottom: 0;
}

.btn-back-home > span {
    padding-right: 0.625rem;
}

.btn-back-home:hover,
.btn-back-home:focus,
.btn-back-home,
.btn-back-home:hover > span::before,
.btn-back-home:focus > span::before,
.btn-back-home > span::before {
    color: #ffffff;
}

.media-navbar-user {
    padding-top: 0;
    align-items: center;
    justify-content: space-between;
}

.unknown-user-name {
    opacity: 0.5;
}

.media-user-info > .btn-add-to-entry {
    margin-left: 1.5625rem;
}

.media-user-info > .btn-add-to-entry > span {
    margin-left: 0;
    padding-right: 0.625rem;
}

.location-unknown {
    opacity: 0.5;
    padding-left: 0.9375rem;
}

.media-gallery-audio-detail,
.media-gallery-video-detail {
    position: relative;
}

.media-gallery-audio-detail > audio,
.media-gallery-video-detail > video {
    width: 17.5rem;
    height: 16.6875rem;
    object-fit: cover;
    border-radius: 0.625rem;
}

.media-audio-controls > .play-pause,
.media-video-controls > .play-pause {
    top: 33.3% !important;
    left: 36.3% !important;
}

.audio-visual-holder-gallery {
    width: 17.5rem;
    height: 16.6875rem;
    border-radius: 0.625rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-visual-holder-gallery > img {
    width: 9.5313rem;
    height: 8.0687rem;
    object-fit: contain;
}

.media-gallery-file-detail {
    width: 17.5rem;
    height: 16.6875rem;
    border-radius: 0.3125rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.media-gallery-file-detail > .icon-file {
    font-size: 5rem;
    color: #1db5cc;
}

.media-gallery-file-detail > .media-file-desc {
    font-size: 1.125rem !important;
    margin-top: 2rem;
    margin-bottom: 0;
}

.play-pause {
    width: 4.3125rem;
    height: 4.3125rem;
    border-radius: 50% !important;
    background-color: white;
    position: absolute;
    color: #2f5f81;
    font-size: 2rem !important;
    top: 7.5rem;
}

.paused-video {
    opacity: 0.4;
}

.align-items-center {
    align-items: center !important;
}

.media-controls {
    height: 4.4375rem;
}

.media-controls > .btn-play,
.media-controls > .btn-mute,
.media-controls > .btn-fullscreen {
    font-size: 2rem !important;
    height: auto !important;
}

.media-controls > .video-duration {
    font-size: 1.5rem !important;
}

.media-controls > .video-progress-bar,
.media-controls > .audio-progress-bar {
    width: 60rem !important;
}

.video-progress-bar {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    outline: none;
    width: 20.875rem;
    height: 0.4375rem;
    border-radius: 3.125rem;
    background: linear-gradient(
        to right,
        #1164fb 0%,
        #1164fb 50%,
        #93a8b7 50%,
        #93a8b7 100%
    );
}

.video-progress-bar::-webkit-slider-thumb {
    /* -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none; */
    opacity: 0;
}

.media-controls > .video-progress-bar,
.media-controls > .audio-progress-bar {
    width: 60rem !important;
}

.video-duration {
    margin-bottom: 0;
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: normal;
    padding-left: 0.75rem;
}

.audio-controls {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: solid 0.0625rem #d5d5d5;
    border-radius: 0.3125rem;
    background-color: #ffffff;
}

.audio-progress-bar {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    outline: none;
    width: 22.9063rem;
    height: 0.4375rem;
    border-radius: 3.125rem;
    background: linear-gradient(
        to right,
        #1164fb 0%,
        #1164fb 50%,
        #93a8b7 50%,
        #93a8b7 100%
    );
}

.audio-progress-bar::-webkit-slider-thumb {
    opacity: 0;
}

.media-new-button {
    margin-top: auto;
    margin-bottom: auto;
}

.btn-back-home {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #ffffff;
    width: 9.375rem;
    height: 2.5rem;
    border-radius: 0.3125rem;
    background-color: #1164fb;
}

.tag-person-modal {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 1);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    border-radius: 0.325rem;
}

.tag-person-modal-content {
    margin: 2rem;
    max-width: 25rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-left: 0;
    padding-right: 0;
    max-height: 100vh;
    height: calc(100vh - 4rem);
}

.tag-person-modal-content-header {
    padding-top: 1.7813rem;
    padding-left: 1.4375rem;
}

.tag-person-modal-content-title {
    font-family: 'Cabin';
    font-size: 1.8125rem;
    font-weight: bold;
}

.tag-person-modal-content-contacts {
    margin-top: 1.5625rem;
    height: calc(100vh - 4rem - 305px);
    overflow: scroll;
    z-index: 1020;
}

.tag-person-modal-content-subtitle {
    padding-left: 1.5rem;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    opacity: 0.8;
    margin-bottom: 0;
    padding-bottom: 0.75rem;
    border-bottom: 0.0375rem solid rgba(60, 60, 67, 0.29);
}

.tag-person-modal-content-list > li {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-size: 1.1875rem;
    font-weight: normal;
    border-bottom: 0.0375rem solid rgba(60, 60, 67, 0.29);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.tag-person-modal-content-list > li > img {
    width: 2.625rem;
    height: 2.625rem;
    margin-left: 1.25rem;
}

.tag-person-modal-content-list > li > span {
    color: #d9d9d9;
    margin-right: 1.5625rem;
}

.tag-person-modal-content-actions {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
}

.tag-person-modal-content-actions > .btn-cancel {
    margin-top: 0.6875rem;
    width: 20.6812rem;
    height: 3.0625rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: #fffdff;
    font-family: 'Roboto';
    font-size: 1.0625rem;
    font-weight: 500;
}

.tag-person-modal-add-user {
    margin-bottom: 1.4375rem;
    margin-top: 2.1875rem;
    padding-left: 1.4375rem;
    padding-right: 1.625rem;
}

.tag-person-modal-label {
    padding: 0;
    opacity: 0.8;
    font-family: 'Roboto';
    font-size: 0.9375rem;
    font-weight: normal;
    padding-bottom: 0.75rem;
}

.tag-person-modal-add-user-list {
    margin-bottom: 1.25rem !important;
}

.tag-person-modal-add-user-list > li {
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.375rem;
    margin-bottom: 0.625rem;
    border-bottom: 0.0375rem solid rgba(60, 60, 67, 0.29);
}

.add-contact-input {
    padding-bottom: 0.375rem;
    width: 100% !important;
    border-bottom: 0.125rem solid #d5d5d5 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-contact-input > input {
    width: 93% !important;
}

.add-contact-input > button {
    line-height: 1 !important;
    color: #818181 !important;
    padding: 0;
    font-size: 1.2rem !important;
}

.media-banner-card {
    width: 100%;
    max-width: 36.25rem;
    height: 12.625rem;
    display: flex;
    border-radius: 0.3125rem;
    background-color: rgb(210, 216, 240);
    padding-left: 1.3125rem;
    padding-right: 0.9375rem;
}

.shared-jrnl-banner {
    height: 7.375rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 #00000017;
    background-color: #e7effb;
    padding-left: 0.9375rem;
    padding-top: 0.9375rem;
    margin-bottom: 1.25rem;
}

.shared-jrnl-banner > img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.25rem;
}

.shared-jrnl-banner__content-title {
    font-family: 'Roboto';
    font-size: 1.125rem;
    line-height: 1.75;
    color: #000000;
    margin-bottom: 0;
}

.shared-jrnl-banner__content-text {
    font-family: 'Roboto';
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1;
    color: #000000;
    margin-bottom: 10px;
}

.media-card {
    background-color: #d2f3fa !important;
    width: 36.3125rem;
    height: 12.625rem;
    padding-left: 0;
}

.media-card > .banner-content {
    padding-left: 0;
}

.media-card > .banner-content > .banner-text {
    line-height: 1.47;
}

.media-card > .banner-img {
    margin-top: 0;
    width: unset;
    height: unset;
}

.media-card > .banner-img > img {
    width: 24rem;
    height: 12.625rem;
}

.banner-title {
    padding-top: 1.3438rem;
    font-family: 'Cabin';
    font-size: 1.625rem;
    font-weight: bold;
}

.banner-close {
    color: #000000;
    margin-top: 0.875rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 0.875rem;
    height: 0.875rem;
}

.banner-close:hover {
    color: #000000;
}

.banner-img {
    width: 10.625rem;
    height: 10.125rem;
    margin-top: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-img > img {
    min-width: 10.625rem;
    height: 10.125rem;
}

.form-group__select.form-group__select {
    box-shadow: 0 0 0 1px $black2;
    padding: 8px 25px 8px 8px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: $trans1;
    line-height: 1.1;

    img {
        width: 8px;
        height: 8px;
        position: absolute;
        right: 8px;
    }

    .form-group__select-text {
        flex-grow: 1;
        font-size: 14px;
        transition: $trans2;
    }

    &:hover {
        box-shadow: 0 0 0 1px var(--primary-color);
        transition: $trans2;

        img {
            filter: var(--filter-primary);
        }

        .form-group__select-text {
            color: var(--primary-color);
        }
    }
}

#media-journal-tags-wrapper,
#media-entry-journal-tags-wrapper {
    width: 11.875rem;
    margin-right: 0.9375rem;
}

#screenshot {
    position: absolute;
    z-index: 1;
}
#draw {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: 48px;
}
#draw #boxes {
    fill: blue;
    stroke: white;
    stroke-width: 1;
    fill-opacity: 0.5;
    stroke-opacity: 0.9;
}

.media-detect-face-img,
.media-detect-face-wrapper {
    fill: white;
    stroke: #05416a;
    stroke-width: 5;
    stroke-opacity: 0.9;
    fill-opacity: 0;
}
.media-detect-face-wrapper {
    fill: #05416a;
    fill-opacity: 1;
    stroke-opacity: 1;
}
.media-detect-face-text {
    stroke: white;
    fill: white;
    stroke-width: 1;
    stroke-opacity: 0.9;
}
.media-detect-face-text.unknown {
    stroke: grey;
    fill: grey;
}

svg circle,
svg rect {
    pointer-events: auto;
}

.circle_image {
    border-radius: 80px;
    margin: 10px;
}

.media-icon-comments {
    margin-right: 10px;
}
