@import '../global-variables.scss';

/*-------------------------------------------------------------
  Banner
-------------------------------------------------------------*/

.banner {
    @media (min-width: $page-width) {
        margin-bottom: 12px;
    }
}

.banner-card {
    box-shadow: $shadow-tile;
    position: relative;
    padding: 1.5em;
    display: flex;
    align-items: center;

    @media (max-width: $page-width) {
        padding: 1em;
    }
    @media (min-width: $page-width) {
        border-radius: 4px;
        padding: 1.5em;
    }
}

.banner-card__img {
    margin-right: 1.3em;
    flex: none;

    img {
        width: 100%;
        height: auto;

        @include x-sm {
            max-width: 80px;
        }
        @include sm {
            max-width: 100px;
        }
        @include md-x {
            max-width: 120px;
        }
    }
}

.banner-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 0;

    @include x-sm {
        font-size: 80%;
    }
}

.banner-card__title {
    font-size: 1.6em;
    line-height: 1.2;
    font-weight: 600;
    margin: 0 0 0.3em;
}

.banner-card__subtitle {
    font-size: 1em;
    line-height: 1.3;
    opacity: 0.7;
}

.banner-card__actions {
    display: flex;
    margin-top: 20px;

    .btn {
        margin-right: 8px;
    }
}

.banner-card__close {
    position: absolute;
    display: block;

    @include x-sm {
        top: 8px;
        right: 8px;
    }

    @include sm-x {
        top: 10px;
        right: 10px;
    }
}

.banner-card--dark {
    .banner-card__title,
    .banner-card__subtitle {
        color: white;
    }

    .btn-close {
        @extend .btn-close--dark;
    }
}

/*--------------------------------
  All About Me
--------------------------------*/

.all-about-me-bg {
    background-color: var(--beta);
}

/*--------------------------------
  Books
--------------------------------*/

.bg-books {
    background-color: var(--primary-color);
}

/*--------------------------------
  Oops
--------------------------------*/

.oops-card {
    background-color: #de005c;
}

/*--------------------------------
  Delete
--------------------------------*/

.delete-card-bg,
.white-bg {
    background-color: white;
}

/*--------------------------------
  No Orders
--------------------------------*/

.no-orders {
    background-color: var(--beta);
}

/*-------------------------------------------------------------
  Shared JRNL banner
-------------------------------------------------------------*/

.shared-jrnl-banner {
    box-shadow: $shadow-tile;
    height: 7.375rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 #00000017;
    background-color: #e7effb;
    padding-left: 0.9375rem;
    padding-top: 0.9375rem;
    margin-bottom: 1.25rem;

    .shared-jrnl-img {
        border-radius: 100px;
        width: 40px;
        height: 40px;
    }

    .shared-jrnl-banner__content {
        padding-left: 16px;

        .shared-jrnl-banner__content-title {
            font-size: 17px;
            font-weight: 600;
            line-height: 1.2;
            margin: 0 0 0.3em;
        }

        .shared-jrnl-banner__content-text {
            font-size: 14px;
            line-height: 1.3;
            color: $black7;
            margin: 0 0 0.7em;
        }

        .btn-add-to-jrnl {
            @extend .btn-outline;
        }
    }
}
