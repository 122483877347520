@import '../global-variables.scss';

/*-------------------------------------------------------------
  Purchase table
-------------------------------------------------------------*/

.purchase-table {
    padding: 0 0 1em;
}

.purchase-table__item {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    align-items: center;
}

.purchase-table__label--card-type {
    text-transform: uppercase;
}

.purchase-table__label {
    font-weight: 600;
}

.purchase-table__item--total {
    font-size: 120%;
    font-weight: 600;
    border-top: 1px solid #0002;
}

.purchase-table__quantity {
    border: 1px solid #0004 !important;
    display: inline-block;
    padding: 4px 8px;
    width: 60px !important;
    text-align: center;
    border-radius: 4px;
    font-size: 0.9em;
    font-weight: 500;
}

.purchase-table__value {
    span {
        padding: 0 0.5em;
    }
}

/*-------------------------------------------------------------
  Coupon Code
-------------------------------------------------------------*/

.coupon-code {
    display: flex;
    margin-bottom: 1.2em;

    .coupon-input {
        border-width: 1px 0 1px 1px !important;
        border-style: solid !important;
        border-color: #0004 !important;
        padding: 8px;
        border-radius: 4px 0 0 4px;
    }
}

.coupon-code__btn {
    border-width: 1px;
    border-style: solid;
    border-color: var(--primary);
    background: white;
    padding: 0 12px;
    border-radius: 0 4px 4px 0;
    color: var(--primary);
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9em;
    transition: $trans2;

    &:hover {
        filter: var(--hover);
        transition: $trans2;
    }
}

.coupon-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #0004;
    opacity: 1;
    /* Firefox */
}

.coupon-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #0004;
}

.coupon-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #0004;
}
