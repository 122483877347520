@import '../global-variables.scss';

/*-------------------------------------------------------------
  Card grid
-------------------------------------------------------------*/

.card-grid {
    @extend .grid;

    @include x-md {
        grid-template-columns: repeat(2, 1fr);
    }
    @include sm-x {
        grid-row-gap: 12px;
        grid-column-gap: 12px;
    }
    @include md-x {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 576px) and (max-width: $page-width) {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }
}

.card-grid__item {
    padding: 1.3em 1.1em;
    margin: 0 !important;
    display: flex;
    cursor: pointer;
    background: white;
    transition: $trans1;
    flex-direction: column;

    &:hover {
        @include sm-x {
            transform: scale(1.03);
            transition: $trans2;
        }
    }

    @include x-sm {
        border-bottom: 1px solid $black1;

        &:nth-child(odd) {
            border-right: 1px solid $black1;
        }
    }
    @include sm-x {
        box-shadow: $shadow-tile;
        border-radius: 4px;
    }
}

.card-grid__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1em;

    img {
        width: 100%;
        height: auto;
        margin: auto;

        @include x-sm {
            max-width: 70px;
        }
        @include sm {
            max-width: 90px;
        }
        @include md-x {
            max-width: 100px;
        }
    }
}

.card-grid__text {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include x-sm {
        font-size: 90%;
    }
}

.card-grid__title {
    line-height: 1.3;
    font-weight: 600;
    margin: 0 0 0.3em;
}

.card-grid__subtitle {
    font-size: 0.8em;
    color: $black6;
}

/*-------------------------------------------------------------
  Grid
-------------------------------------------------------------*/

.grid {
    display: grid;
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.grid--sm {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
}

.grid--rg {
    grid-row-gap: 12px;
    grid-column-gap: 12px;
}

.grid--md {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
}

.grid--col1 {
    grid-template-columns: 1fr;
}
.grid--col2 {
    grid-template-columns: repeat(2, 1fr);
}
.grid--col3 {
    grid-template-columns: repeat(3, 1fr);
}
.grid--col4 {
    grid-template-columns: repeat(4, 1fr);
}
.grid--col5 {
    grid-template-columns: repeat(5, 1fr);
}
.grid--col6 {
    grid-template-columns: repeat(6, 1fr);
}
.grid--col7 {
    grid-template-columns: repeat(7, 1fr);
}
.grid--col8 {
    grid-template-columns: repeat(8, 1fr);
}
.grid--col9 {
    grid-template-columns: repeat(9, 1fr);
}
.grid--col10 {
    grid-template-columns: repeat(10, 1fr);
}
.grid--col11 {
    grid-template-columns: repeat(11, 1fr);
}
.grid--col12 {
    grid-template-columns: repeat(12, 1fr);
}
