@import '../global-variables.scss';

/*-------------------------------------------------------------
  Share modal
-------------------------------------------------------------*/

.user-tags {
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid $black4;
    border-radius: 4px;
    min-height: 120px;
    max-height: 400px;
    position: relative;
    padding: 8px 40px 8px 8px;
    @extend .scrollable;
    display: flex;
    flex-wrap: wrap;

    .user-tags__item {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0 10px 0 6px;
        border-radius: 10px;
        margin: 5px;
        height: 32px;
        background: white;
        box-shadow: 0 0 0 1px $black1, 0 1px 3px $black2;

        .user-tags__avatar {
            width: 22px;
            height: 22px;
            border-radius: 100px;
            box-shadow: 0 0 0 1px white;
        }

        .user-tags__title {
            font-size: 14px;
            padding: 0 8px;

            &.truncate {
                max-width: 240px;
            }
        }

        .user-tags__close {
            width: 10px;
            height: 10px;
            margin-left: 2px;
            cursor: pointer;
            transition: $trans1;
            filter: var(--filter-black);
            opacity: 0.2;

            &:hover {
                filter: var(--filter-primary);
                opacity: 1;
                transition: $trans2;
            }
        }
    }
}

.user-tags__add {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: -24px 12px 0 auto;
    position: relative;
    top: -10px;
}

/*-------------------------------------------------------------
  Share contacts
-------------------------------------------------------------*/

.share-modal-add-user {
    .form-group--single {
        .form-group__item {
            padding: 0;
        }
    }

    .list {
        margin: 8px 0 0 !important;
        border: none;
        border-radius: 0;
    }
}
