@import '../global-variables.scss';

/*-------------------------------------------------------------
  Confirm post modal
-------------------------------------------------------------*/

.confirm-post-modal {
  /* display: none;  Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  border-radius: 0.325rem;
}

/* .confirm-post-modal-content {

} */

.confirm-post-card {
  background-color: #ffffff;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  margin-top: 20%;
  width: 36.3125rem;
  height: 14.125rem;
  border-radius: 0.3125rem;
  background-color: #ffffff;
}

.confirm-post-card {
  background-color: #ffffff;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
  margin-top: 20%;
  width: 36.3125rem;
  height: 14.125rem;
  border-radius: 0.3125rem;
  background-color: #ffffff;
}

.confirm-post-card-content > .banner-actions {
  display: flex;
  align-items: center;
  margin-top: 1.3125rem;
}

.confirm-post-card-content > p {
  line-height: 1.47;
}

.confirm-post-card > .banner-img > img {
  width: 10.2375rem;
  height: 11.1375rem;
}