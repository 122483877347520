@import '../global-variables.scss';

/*-------------------------------------------------------------
  Forms
-------------------------------------------------------------*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

textarea {
    resize: none;
}

input,
textarea {
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -webkit-appearance: none;
}

input[type='text'] {
    border: none;
    width: auto;
}

input[type='text']:focus {
    border: none;
    outline: none;
}

textarea.post-content {
    width: 100%;
    min-height: 1rem;
    max-height: 20rem;
    height: 5rem;
    border: none;
    outline: none;
    overflow: auto;
    resize: none;
    font-weight: normal;
    font-size: 1.0625rem;
    color: rgb(62, 62, 62);
    line-height: 1.765;
}

textarea.posted {
    height: 10rem;
}

.input-error-msg {
    color: var(--red);
    font-size: 12px;
    padding-top: 4px;
    display: block;
}

/*-------------------------------------------------------------
  Form group
-------------------------------------------------------------*/

.form-group {
    margin-bottom: 16px;

    .form-group__row {
        width: 100%;

        @include lg-x {
            display: flex;

            .form-group__item {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .form-group__row--exp-cvv {
        display: flex;
        column-gap: 10px;

        .form-group__item {
            width: inherit;
            margin-right: 0;
        }
    }

    .form-group__row--nowrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
    }

    .form-group__avatar {
        text-align: center;

        .avatar {
            width: 120px;
            height: 120px;
            border-radius: 100px;
            margin: 0 0 16px;
        }

        .btn {
            margin: auto;
        }
    }

    .form-group__item--6 {
        @include lg-x {
            width: 50% !important;
        }
    }

    .form-group__item--4 {
        @include lg-x {
            width: 33.33333333333% !important;
        }
    }

    .form-group__item--3 {
        @include lg-x {
            width: 25% !important;
        }
    }

    .form-group__item {
        display: flex;
        flex-direction: column;
        padding: 20px 0 18px;
        margin: 0 0 16px;
        position: relative;
        width: 100%;

        label {
            font-size: 13px;
            font-weight: 600;
            color: black;
            padding: 0;
            position: absolute;
            top: 0;
        }

        input[type='text'],
        input[type='password'],
        input[type='tel'],
        input[type='email'],
        textarea {
            border-radius: 4px;
            padding: 8px 10px;
            border: 1px solid $black3;
            width: 100%;

            &:disabled {
                background: $black-light5;
                color: $black4;
            }
        }

        .MuiInputBase-root {
            margin: 0 !important;
            padding: 0 !important;
            border: none !important;
        }

        .form-group__exp-date {
            input {
                width: 50px;
                text-align: center;
                margin-right: 8px;
            }
        }

        .form-group__expiration {
            input {
                //max-width: 77px;
            }
        }

        .form-group__cvv {
            input {
                //max-width: 64px;
            }
        }

        .form-group__desc {
            font-size: 11px;
            line-height: 1.2;
            color: $black6;
            margin-top: 8px;
        }
    }

    .form__error {
        font-size: 12px;
        color: var(--red-color);
        margin-top: 8px;
    }

    .form-group__select {
        box-shadow: 0 0 0 1px $black2;
        padding: 8px 25px 8px 8px;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 4px;
        transition: $trans1;
        line-height: 1.1;

        img {
            width: 8px;
            height: 8px;
            position: absolute;
            right: 8px;
        }

        .form-group__select-text {
            flex-grow: 1;
            font-size: 14px;
            transition: $trans2;
        }

        &:hover {
            box-shadow: 0 0 0 1px var(--primary-color);
            transition: $trans2;

            img {
                filter: var(--filter-primary);
            }

            .form-group__select-text {
                color: var(--primary-color);
            }
        }
    }
}

.form-group--single {
    margin: 0;

    .form-group__item {
        padding-bottom: 0;
        margin: 0;
    }
}

/*-------------------------------------------------------------
  Select
-------------------------------------------------------------*/

.MuiPaper-elevation8 {
    box-shadow: $dropdown-shadow !important;
}

.form-group__select-list {
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    @media (max-width: 459.98px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 460px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.mui-select-styles {
    box-shadow: 0 0 0 1px $black4;
    padding: 8px 24px 8px 8px !important;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: $trans1;
    cursor: pointer !important;
    min-height: inherit;
    line-height: inherit !important;
    margin: 0 !important;
    border-radius: 4px !important;

    &:after {
        content: '';
        background-image: url('/static/media/icon_caret-down.2e15136e.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 8px;
        top: 13px;
    }
}

.MuiSelect-select {
    width: 100%;
    @extend .mui-select-styles;

    .MuiSelect-select {
        padding: 0;
    }

    &:hover {
        box-shadow: 0 0 0 1px var(--primary-color);
        transition: $trans2;

        img,
        &:after {
            filter: var(--filter-primary);
        }

        .form-group__select-text {
            color: var(--primary-color);
        }
    }
}

.time-select {
    //@extend .mui-select-styles;

    .MuiSelect-select {
        &:focus {
            background: transparent;
        }
    }
}

.time-picker {
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: repeat(3, 3fr);
    max-width: 200px;

    .MuiSelect-select {
        &:focus,
        option {
            background: transparent !important;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: none;
    }

    svg {
        display: none;
    }
}

.select-dropdown {
    .MuiPaper-root {
        max-height: 400px !important;
        @extend .scrollable;
    }
}

.MuiMenu-list {
    padding: 0 !important;
    margin: auto !important;
    width: 100%;

    .MuiListItem-root {
        transition: $trans1;
        padding: 8px 16px !important;
        min-height: inherit;
        font-size: 14px;

        &:hover {
            background: var(--primary1);
            transition: $trans2;
        }

        &.Mui-selected {
            background: var(--primary-color) !important;
            color: white;
        }
    }
}

/*-------------------------------------------------------------
  Form input button
-------------------------------------------------------------*/

.form-input-btn {
    display: flex;

    .form-input-btn__input {
        flex: auto;
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 10px !important;
        width: 100%;
    }

    .form-input-btn__btn {
        flex: none;
        border-radius: 0 4px 4px 0;
        padding: 0 12px;
        @extend .btn;
        @extend .btn-gradient;

        img {
            width: 16px;
            height: 16px;
        }
    }
}

/*-------------------------------------------------------------
  Search box
-------------------------------------------------------------*/

.search-box {
    position: relative;
    background: $black-light5;
    display: flex;
    align-items: center;
    padding: 0 16px 0 12px;
    border-radius: 4px;

    .search-box__icon {
        opacity: 0.4;
        flex: none;
        margin-right: 8px;
    }

    .search-box__input {
        background: transparent;
        height: 40px;
        flex: auto;
    }

    .search-box__input::placeholder {
        color: $black4;
    }

    .search-box__input::-moz-placeholder {
        color: $black4;
    }

    .search-box__input:-moz-placeholder {
        color: $black4;
    }

    .search-box__input::-webkit-input-placeholder {
        color: $black4;
    }

    .search-box__input:-ms-input-placeholder {
        color: $black4;
    }
}

/*-------------------------------------------------------------
  Toggles
-------------------------------------------------------------*/

.toggle-section {
    border-top: 1px solid $black1;
}

.toggle {
    min-width: 40px;
    min-height: 24px;
    display: flex;
    align-items: center;

    label {
        position: relative;
        padding: 0;
        height: 10px;
        width: 36px;
        transition: $trans1;

        &:hover {
            filter: var(--hover);
        }
    }

    input[type='checkbox'] + label::before {
        cursor: pointer;
        content: ' ';
        display: block;
        height: 10px;
        width: 36px;
        border-radius: 100px;
        position: absolute;
        right: 0;
        background-color: $black1;
        box-shadow: inset 0 1px 3px $black3;
        transition: $trans1;
    }

    input[type='checkbox'] + label::after {
        cursor: pointer;
        content: ' ';
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: 17px;
        background-color: white;
        box-shadow: 0 0 0 1px $black3, 0 1px 3px $black3;
        transition: $trans1;
    }

    input[type='checkbox']:checked + label::after {
        background-color: var(--primary-color);
        right: -3px;
        transition: all 0.3s ease-in;
        box-shadow: none;
    }

    input[type='checkbox']:checked + label::before {
        background-color: rgba(17, 100, 251, 0.38);
    }
}

.toggle-group {
    display: flex;
    margin-left: 16px;

    @include x-sm {
        flex-direction: column;
    }

    .toggle {
        @include x-sm {
            margin: 4px 0;
        }

        @include sm-x {
            margin: 0 0 0 16px;
        }
    }
}

.toggle--right {
    margin-left: 16px;
}

.toggle--icon {
    min-width: 60px;

    .toggle__icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.custom-radio {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: none;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    left: 0;
    top: 0.4375rem;
    height: 1.1875rem;
    width: 1.1875rem;
    background-color: rgba(255, 253, 255, 0.502);
    border-radius: 50%;
    border: 0.125rem solid black;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
    background-color: #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
    top: 0.2813rem;
    left: 0.275rem;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background: black;
}

.view-option {
    font-weight: 600;
    margin-left: 1rem;
}

button:focus {
    outline: none !important;
}

/*-------------------------------------------------------------
  Checkbox
-------------------------------------------------------------*/

.checkbox-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
        padding: 0;
        font-size: 14px;
        line-height: 1.3;
    }
}

.checkbox {
    width: 20px;
    height: 20px;
    line-height: 19px;
    text-align: center;
    box-shadow: inset 0 0 0 1px $black6;
    position: relative;
    border-radius: 4px;
    margin-right: 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    flex: none;
    transition: $trans1;

    &:hover {
        transition: $trans2;
        filter: var(--hover);
        box-shadow: inset 0 0 0 2px var(--primary-color);
    }

    &:checked {
        border-color: transparent;
        box-shadow: inset 0 0 0 40px var(--primary-color);
        position: relative;
        top: -1px;
    }

    &:checked::after {
        content: '\f00c';
        font-family: FontAwesome;
        color: white;
    }
}

/*-------------------------------------------------------------
  Radio
-------------------------------------------------------------*/

.radio-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
        padding: 0;
        font-size: 14px;
        line-height: 1.3;
    }
}

.radio {
    width: 20px;
    height: 20px;
    text-align: center;
    box-shadow: inset 0 0 0 1px $black6;
    position: relative;
    border-radius: 100px;
    margin-right: 12px;
    cursor: pointer;
    border: none;
    flex: none;
    transition: $trans1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transition: $trans2;
        filter: var(--hover);
        box-shadow: inset 0 0 0 2px var(--primary-color);
    }

    &:checked {
        border-color: transparent;
        box-shadow: inset 0 0 0 3px var(--primary-color);
        position: relative;
        top: -1px;
    }

    &:checked::after {
        content: '';
        width: 8px;
        height: 8px;
        background: var(--primary-color);
        border-radius: 100px;
    }
}

/*-------------------------------------------------------------
  Variables
-------------------------------------------------------------*/

.dropdown-style {
    box-shadow: 0 0 0 1px $black3;
    padding: 8px;
    border-radius: 4px;
}
