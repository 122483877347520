@import '../global-variables.scss';

/*-------------------------------------------------------------
  Comments
-------------------------------------------------------------*/

.posted-comments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.comment-textarea {
    border-width: 2px;
    border-style: solid;
    border-color: $black1;
    border-radius: 4px;
    padding: 12px 14px;
    width: 100%;
    min-height: 140px;
    font-size: 15px;
    line-height: 1.4;
}

/*-------------------------------------------------------------
  Comments actions
-------------------------------------------------------------*/

.comment-actions {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 16px 4px;
    border-top: 1px solid $black1;
}

.comments-filter {
    .btn-comments-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: #000000;
        line-height: 1;
        background: transparent;
        border: none;
        outline: none;
        font-size: 14px;

        .icon-caret-down {
            margin-left: 8px;
        }
    }

    .comments-filter-dropdown {
        min-width: inherit;
        max-width: 120px;

        .dropdown-item {
            font-size: 14px;
            line-height: 1.5;
            padding: 4px 6px;
            border-radius: 4px;
            transition: $trans1;

            &:hover {
                background: var(--primary1);
                transition: $trans2;
            }
        }
    }
}

.btn-comments-filter:hover {
    color: #000000;
}

/*-------------------------------------------------------------
  Comments
-------------------------------------------------------------*/

.comment-avatar {
    width: 34px;
    height: 34px;
    margin-right: 16px;
    border-radius: 100%;
    margin-top: 3px;
}

.posted-comments-list,
.replied-comments-list {
    width: 100%;

    > li {
        display: flex;
        position: relative;
        padding: 16px;
        border-bottom: 1px solid $black1;

        &:last-of-type {
            .posted-comment-content {
                border: none;
            }
        }

        .disabled {
            p {
                color: $black6;
            }

            img {
                filter: grayscale(100%);
            }
        }

        .posted-comment-content {
            width: 100%;

            .posted-comments-text {
                word-break: break-word;
                font-size: 15px;
                line-height: 1.4;
                font-weight: normal;
                width: 100%;
                color: black;
                margin: 0 0 6px;
                padding: 6px 44px 0 0;
            }

            .edit-posted-comments {
                flex-direction: column;
                align-items: flex-end;
                margin: 4px 0 0;

                p {
                    margin-bottom: 16px;
                }

                textarea {
                    @extend .comment-textarea;
                }

                .btn-save-comment {
                    margin: 8px 0 0 auto;
                }
            }

            .posted-comment-header {
                display: flex;
                align-items: center;
                margin-right: -4px;
            }

            .comment-footer {
                display: flex;
                align-items: center;
            }

            .posted-comment-user {
                font-weight: 600;
                line-height: 1.4;
                margin: 0 6px 0 0;
                float: left;
                font-size: 12px;
                color: black;
            }

            .spacer {
                flex-grow: 1;
            }

            .comments-dropdown {
                line-height: 0;

                span {
                    font-size: 20px;
                    color: $black4;
                }
            }

            .btn-comment-reply {
                color: $black6;
                font-size: 12px;
                background: transparent;
                border: none;
                margin: 0 8px 0 0;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }

            .posted-comment-time {
                display: flex;
                align-items: center;
                margin-right: 20px;
                font-size: 12px;
                color: $black4;
                white-space: nowrap;
                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 0.3rem;
                }
            }
        }
    }
}

/*-------------------------------------------------------------
  Replies
-------------------------------------------------------------*/

.replied-comments-list {
    border-left: 16px solid $black1;

    > li {
        &.focused-comment-bg {
            border-left: 5px solid var(--primary-color);
        }

        .reply-comment-content {
            width: 100%;

            textarea {
                @extend .comment-textarea;
            }

            .comment-reply-actions {
                padding: 16px 0 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    margin-left: 10px;
                }
            }
        }
    }
}

/*-------------------------------------------------------------
  New comment
-------------------------------------------------------------*/

.comment-dropdown-menu {
    top: -300px !important;
    right: 5px !important;

    .comment-contact-list {
        margin: 0;

        > li {
            padding: 4px 16px 4px 44px;
        }
    }
}

.comment-contact-list {
    margin: 0;

    > li {
        display: flex;
        align-items: center;
        border: none !important;

        .comment-icon {
            width: 18px;
            height: 18px;
        }

        .circle-image {
            border-radius: 100px;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            filter: none;
        }
    }
}

.dropdown-menu.comment-dropdown-menu {
    width: 16.3125rem !important;
}

.dropdown-comment-list > li {
    cursor: pointer;
    border-bottom: 1px solid #dadada;
}

/*-------------------------------------------------------------
  New comment
-------------------------------------------------------------*/

.new-comment {
    display: flex;
    padding: 16px 16px 0;

    .new-comment-textarea {
        @extend .comment-textarea;
    }
}

.new-comment-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;

    .btn-post-comment {
        margin-left: 8px;
    }
}
