@import '../global-variables.scss';

/*-------------------------------------------------------------
  Upgrade to pro
-------------------------------------------------------------*/

.upgrade-pro-welcome-card,
.upgrade-pro-error-card {
    padding: 2em;

    .btn-gradient {
        margin: 0 auto;
    }
}

.upgrade-pro-welcome-text,
.upgrade-pro-error-text {
    text-align: center;
    margin-bottom: 1rem;
}

.upgrade-pro-welcome-card > img {
    margin-bottom: 1.875rem;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include x-sm {
        max-width: 80%;
    }
    @include sm-x {
        max-width: 400px;
    }
}

.upgrade-pro-welcome-card .modal-title,
.upgrade-pro-error-card .modal-title {
    padding-top: 0;
    padding-left: 0;
    margin-bottom: 0.3em;
}

/*------------------------------
  Upgrade to pro
-------------------------------*/

#upgrade-pro-checkout-modal {
    .modal-box {
        height: initial;
    }
}

.modal-box {
    .settings-title-section {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 430px;
        column-gap: 8px;

        @include x-sm {
            font-size: 90%;
            max-width: 93%;
        }
    }
}

/*------------------------------
  Plan info
-------------------------------*/

.plan-info__img {
    img {
        width: 100%;
        max-width: 400px;
        margin: 1em auto;
        display: block;
    }
}

.plan-info__title {
    font-size: 1.3em;
    font-weight: 600;
    margin: 0 0 0.3em;
}

.plan-info__desc {
    margin-bottom: 1.5em;
    line-height: 1.3;
}
