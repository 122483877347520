@import '../global-variables.scss';

/*-------------------------------------------------------------
  Dropzone
-------------------------------------------------------------*/

.dropzone {
    @include x-sm {
        padding: 2em 2em;
    }
    @include sm {
        padding: 3em 2em;
    }
    @include md-x {
        padding: 4em 2em;
    }

    .dz-default {
        display: none;
    }
}
