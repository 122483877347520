@import '../global-variables.scss';

/*-------------------------------------------------------------
  Layout
-------------------------------------------------------------*/

* {
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
}

body {
    font-size: 100%;
    line-height: 1;
    overflow-x: hidden;
    height: 100%;
}
main {
    min-height: calc(100% - 355px);
}

#main {
    transition: margin-left 0.5s;
}
#login {
    .access {
        padding-top: 5rem;
    }
}
.app-main {
    margin-top: 0;
    overflow-y: auto;
    min-height: 100vh;

    @include x-sm {
        padding-top: 50px;
    }

    @include sm {
        padding-top: 50px;
    }

    @include md-x {
        padding-top: 60px;
    }
}

.app-main.full-view {
    padding-top: 0.5rem;
}

.wrapper {
    margin: 0 auto;
}

.table-div {
    display: table;
    width: 100%;
}

.table-div-cell {
    display: table-cell;
    vertical-align: middle;
}

.container,
.container-sm {
    @include sm {
        max-width: 95%;
    }
}

/*-------------------------------------------------------------
  Page container
-------------------------------------------------------------*/

.default-mode {
    width: 100%;

    @include sm-x {
        max-width: $page-width;
    }
}

.infinite-scroll-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.compact-mode {
    max-width: 480px;
}

.expanded-mode {
    max-width: 880px;
}

.page-container {
    margin-left: auto;
    margin-right: auto;
    @extend .default-mode;

    @media (min-width: $page-width) {
        padding: 16px;
    }
}

.page-container--800 {
    max-width: 800px;
}

.page {
    display: inline-block;
    width: 100%;
    background: white;
    box-shadow: $shadow-tile;

    @media (min-width: $page-width) {
        border-radius: 4px;
    }

    .page__header {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        position: relative;

        .page__title {
            font-size: 1.5em;
            font-weight: 600;
            line-height: 1.2;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .page__header-btn {
            }
        }
    }
}

/*-------------------------------------------------------------
  Element styles
-------------------------------------------------------------*/

a {
    color: #1852f5;
    text-decoration: none !important;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

.circle-img {
    border-radius: 50%;
}

.vertical-top {
    vertical-align: top;
}

.vertical-bottom {
    vertical-align: bottom;
}

.vertical-middle {
    vertical-align: middle;
}

.db {
    display: block;
}

.dn {
    display: none;
}

.dib {
    display: inline-block;
}

.pr {
    position: relative;
}

.pa {
    position: absolute;
}

.ps {
    position: static;
}

.select-none {
    user-select: none;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}

blockquote,
q {
    quotes: none;
}

::-ms-clear {
    display: none;
}

:focus {
    outline: none !important;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

.public-DraftStyleDefault-ul {
    list-style: disc;
}

.public-DraftStyleDefault-ol {
    list-style: decimal;
}

.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
    line-height: 160%;
    margin: 0.8em 0;
    padding: 0 0 0 2.2em;
}

.ver-top-box {
    display: inline-block;
    vertical-align: top;
}

.vertical-middle {
    vertical-align: middle;
}

.c-blue {
    color: #00416a;
}

.c-lblue {
    color: #00b6cb;
}

.c-white {
    color: #ffffff;
}

.c-black {
    color: #000000;
}

.bgc-lblue {
    background-color: #00b6cb;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.trans {
    transition: all 0.5s ease;
}

.pointer-cursor {
    cursor: pointer;
}

.height-890 {
    height: 55.625rem !important;
}

.height-636 {
    height: 39.75rem !important;
}

.height-355 {
    height: 22.1875rem !important;
}

.pr-14px {
    padding-right: 0.875rem !important;
}

.width-650 {
    max-width: 40.625rem !important;
}

.width-680 {
    max-width: 42.5rem !important;
}

.width-880 {
    max-width: 55rem !important;
}

/*-------------------------------------------------------------
  Colors
-------------------------------------------------------------*/

:root {
    --primary-color: rgb(0 100 255); // Royal blue #0064ff
    --primary1: rgba(0, 101, 255, 0.1);
    --primary2: rgba(0, 101, 255, 0.2);
    --beta: rgb(130, 40, 210); // Violet #8228d2
    --beta1: rgb(130 40 210 / 0.1);
    --beta2: rgb(130 40 210 / 0.2);
    --beta3: rgb(130 40 210 / 0.3);
    --gamma: rgb(0, 215, 240); // Teal #00d7f0
    --delta: rgb(0, 15, 50); // Deep blue #000f32
    --epsilon: rgb(32 201 153); // Mint #20c999

    // Effects
    --hover: brightness(1.15);
    --active: brightness(0.9);

    // Journals & Tags
    --dates: rgb(232 241 255);
    --journals: rgb(240 226 251);
    --tags: rgb(226 245 203);

    // Other Colors
    --green-color: rgb(120, 220, 40);
    --green-color2: rgb(79 177 0);
    --red-color: rgb(195 0 0);

    // Filters
    --filter-primary: invert(25%) sepia(56%) saturate(4310%) hue-rotate(211deg)
        brightness(100%) contrast(109%);
    --filter-beta: invert(18%) sepia(80%) saturate(6249%) hue-rotate(271deg)
        brightness(88%) contrast(87%);
    --filter-gamma: invert(71%) sepia(42%) saturate(2666%) hue-rotate(136deg)
        brightness(95%) contrast(103%);
    --filter-delta: invert(9%) sepia(39%) saturate(2220%) hue-rotate(196deg)
        brightness(98%) contrast(111%);
    --filter-white: invert(96%) sepia(0%) saturate(0%) hue-rotate(170deg)
        brightness(104%) contrast(106%);
    --filter-black: invert(0%) sepia(87%) saturate(7500%) hue-rotate(245deg)
        brightness(83%) contrast(111%);
    --filter-green: invert(64%) sepia(85%) saturate(537%) hue-rotate(39deg)
        brightness(97%) contrast(103%);
}

.instagram-bg {
    background-color: #e1316a;
}

.instagram-bg--gradient {
    background: rgb(64, 93, 230);
    background: -moz-linear-gradient(
        45deg,
        rgba(64, 93, 230, 1) 0%,
        rgba(131, 58, 180, 1) 25%,
        rgba(193, 53, 132, 1) 54%,
        rgba(253, 29, 29, 1) 79%,
        rgba(255, 220, 128, 1) 100%
    );
    background: -webkit-linear-gradient(
        45deg,
        rgba(64, 93, 230, 1) 0%,
        rgba(131, 58, 180, 1) 25%,
        rgba(193, 53, 132, 1) 54%,
        rgba(253, 29, 29, 1) 79%,
        rgba(255, 220, 128, 1) 100%
    );
    background: linear-gradient(
        45deg,
        rgba(64, 93, 230, 1) 0%,
        rgba(131, 58, 180, 1) 25%,
        rgba(193, 53, 132, 1) 54%,
        rgba(253, 29, 29, 1) 79%,
        rgba(255, 220, 128, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#405de6", endColorstr="#ffdc80", GradientType=1);
}

.twitter-bg {
    background-color: #00abed;
}

.facebook-bg {
    background-color: #4167b1;
}

.pinterest-bg {
    background-color: #e60022;
}

.google-bg {
    background-color: #4285f4;
}

.brand-gradient {
    background: var(--beta);
    background: -moz-linear-gradient(
        45deg,
        var(--beta) 0%,
        var(--primary-color) 50%,
        var(--gamma) 100%
    );
    background: -webkit-linear-gradient(
        45deg,
        var(--beta) 0%,
        var(--primary-color) 50%,
        var(--gamma) 100%
    );
    background: linear-gradient(
        45deg,
        var(--beta) 0%,
        var(--primary-color) 50%,
        var(--gamma) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--delta)', endColorstr='var(--gamma)', GradientType=1);
}

/*-------------------------------------------------------------
  Font styles
-------------------------------------------------------------*/

.small-gray {
    font-size: 13px;
    font-weight: 400;
    color: $black6;
}

.truncate {
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;

    > span {
        display: table-cell;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.section-title {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $black6;
    background: $black-light5;
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 16px;
}

.error-message {
    font-size: 11px;
    color: red;
    background: $black-light5;
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 16px;
}

/*-------------------------------------------------------------
  Card styles
-------------------------------------------------------------*/

.card-styles {
    background-color: white;

    @media (max-width: $page-width) {
        border-bottom: 5px solid $black1;
    }

    @media (min-width: $page-width) {
        border-radius: 4px;
        margin-bottom: 12px;
        box-shadow: $shadow-tile;
    }
}

/*-------------------------------------------------------------
  Social Icons
-------------------------------------------------------------*/

.social-icon {
    width: 40px;
    height: 40px;
    line-height: 37px;
    text-align: center;
    border-radius: 10px;

    img {
        width: 24px;
        height: 24px;
        filter: var(--filter-white);
    }
}

.social-icon--lg {
    width: 80px;
    height: 80px;
    line-height: 77px;
    text-align: center;
    border-radius: 20px;

    img {
        width: 48px;
        height: 48px;
        filter: var(--filter-white);
    }
}

/*-------------------------------------------------------------
  Scrollable area
-------------------------------------------------------------*/

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $black3;
        border-radius: 4px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary-color);
    }
}

/*-------------------------------------------------------------
  Page Membership Alert
-------------------------------------------------------------*/

.page--member-alert {
    padding: 2em;

    .intro-title {
        font-size: 1.5em;
        font-weight: 600;
        margin: 0 0 0.5em;
    }
}
