.finished-book-process-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.finished-book-process-content img {
    width: 6.3125rem;
    height: 6rem;
    margin-bottom: 1.25rem;
    opacity: 0.4;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
