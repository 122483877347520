/*-----------------------------------------------------
  Components
-----------------------------------------------------*/

@import '../scss/components/alert-card.scss';
@import '../scss/components/all-about-me.scss';
@import '../scss/components/banner.scss';
@import '../scss/components/books.scss';
@import '../scss/components/buttons.scss';
@import '../scss/components/comments.scss';
@import '../scss/components/checkbox.scss';
@import '../scss/components/calendar.scss';
@import '../scss/components/card-grid.scss';
@import '../scss/components/data-deletion.scss';
@import '../scss/components/dropdowns.scss';
@import '../scss/components/dropzone.scss';
@import '../scss/components/editor.scss';
@import '../scss/components/entries.scss';
@import '../scss/components/forgot-password.scss';
@import '../scss/components/ios-install-banner.scss';
@import '../scss/components/journals-tags.scss';
@import '../scss/components/list.scss';
@import '../scss/components/loading.scss';
@import '../scss/components/media.scss';
@import '../scss/components/memories.scss';
@import '../scss/components/options-bar.scss';
@import '../scss/components/plans.scss';
@import '../scss/components/purchase-table.scss';
@import '../scss/components/remove-my-data.scss';
@import '../scss/components/search-bar.scss';
@import '../scss/components/selector.scss';
@import '../scss/components/subscription-expire-banner.scss';
@import '../scss/components/thank-you-page.scss';
@import '../scss/components/timeline.scss';
@import '../scss/components/media';
@import '../scss/components/pdf.scss';
@import '../scss/components/donation.scss';

/*-----------------------------------------------------
  Modals
-----------------------------------------------------*/

@import '../scss/modals/add-edit-delete.scss';
@import '../scss/modals/add-media.scss';
@import '../scss/modals/checkout.scss';
@import '../scss/modals/checkout-success.scss';
@import '../scss/modals/confirm-post.scss';
@import '../scss/modals/contacts.scss';
@import '../scss/modals/content-box.scss';
@import '../scss/modals/date-time-picker.scss';
@import '../scss/modals/delete.scss';
@import '../scss/modals/fullscreen.scss';
@import '../scss/modals/import-file.scss';
@import '../scss/modals/location.scss';
@import '../scss/modals/modal.scss';
@import '../scss/modals/manage-contact.scss';
@import '../scss/modals/media-modal.scss';
@import '../scss/modals/move-entries.scss';
@import '../scss/modals/photo-upload-preview.scss';
@import '../scss/modals/photos.scss';
@import '../scss/modals/send-comment.scss';
@import '../scss/modals/share.scss';
@import '../scss/modals/social-media-picker.scss';
@import '../scss/modals/upgrade-pro-account.scss';
@import '../scss/modals/edit-book.scss';
@import '../scss/modals/book-create-success.scss';
@import '../scss/modals/social-image-upload.scss';
@import '../scss/modals/onboarding-first-dialog.scss';

/*-----------------------------------------------------
  Settings
-----------------------------------------------------*/

@import '../scss/settings/account.scss';
@import '../scss/settings/billing.scss';
@import '../scss/settings/choose-account.scss';
@import '../scss/settings/contacts.scss';
@import '../scss/settings/email-to-jrnl.scss';
@import '../scss/settings/import-export.scss';
@import '../scss/settings/invitations.scss';
@import '../scss/settings/jrnl-guard.scss';
@import '../scss/settings/notifications.scss';
@import '../scss/settings/profile.scss';
@import '../scss/settings/reminders.scss';
@import '../scss/settings/security.scss';
@import '../scss/settings/settings.scss';
@import '../scss/settings/social-connect.scss';
@import '../scss/settings/step-verification.scss';
@import '../scss/settings/themes.scss';
@import '../scss/settings/onboarding-tooltip.scss';

/*-----------------------------------------------------
  Structure
-----------------------------------------------------*/

@import '../scss/structure/clearfixes.scss';
@import '../scss/structure/fonts.scss';
@import '../scss/structure/forms.scss';
@import '../scss/structure/header.scss';
@import '../scss/structure/layout.scss';
@import '../scss/structure/sidebar.scss';

/*-----------------------------------------------------
  Front
-----------------------------------------------------*/

@import '../scss/front/header.scss';
@import '../scss/front/home.scss';
@import '../scss/front/footer.scss';
@import '../scss/front/access.scss';
@import '../scss/front/subpage.scss';

/*-----------------------------------------------------
  Fonts
-----------------------------------------------------*/
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* DRAFT JS FONTS IMPORT */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Gravitas+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=PT+Mono&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helvetica/Helvetica.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/helvetica/Helvetica.woff') format('woff'),
        url('../fonts/helvetica/Helvetica.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

.real-you-bg {
    background-image: url('../images/frontend/home/real-you.jpg');
}

.great-features-bg {
    background-image: url('../images/frontend/home/features.jpg');
}

.common-modal {
    .common-modal-card {
        border-radius: 0.3125rem;
        background-color: white;
        text-align: center;
        padding: 0;

        .close-btn {
            text-align: right;
            padding: 0;
        }
    }
}
