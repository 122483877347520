@import '../global-variables.scss';

/*-------------------------------------------------------------
  Purchase complete modal
-------------------------------------------------------------*/

#completed-purchase-modal {
    .modal-box {
        height: initial;
    }

    .verification-image {
        width: 100%;
        height: auto;
        margin: 0 auto 2em;
        display: block;

        @include x-sm {
            max-width: 80%;
        }
        @include sm-x {
            max-width: 400px;
        }
    }
}

.modal-message {
    margin: 0 0 1.2em;
}

.modal-message__title {
    font-size: 1.4em;
    font-weight: 700;
    margin: 0 0 0.5em;
}

.modal-message__details {
    color: #0008;
    line-height: 1.4;
}

.buyer-info {
    padding: 0.2em 0 1.2em;

    li {
        padding: 0.2em 0;
    }
}
