@import '../global-variables.scss';

/*-------------------------------------------------------------
  Loading
-------------------------------------------------------------*/

.loading-img > img {
    margin-top: 5rem;
    width: 12.1875rem;
    height: 12.1875rem;
    transition: opacity 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* The progress container (grey background) */
.loading-container {
    width: 100%;
    height: 0.6875rem;
    background: transparent;
}

/* The progress bar (scroll indicator) */
.loading-bar {
    height: 0.6875rem;
    background: #2ebfd2;
    width: 0%;
    transition: width 1s;
}
