@import '../global-variables.scss';

/*-------------------------------------------------------------
  Delete modal
-------------------------------------------------------------*/

.delete-modal {
    width: 100%;
    padding: 24px;

    .delete-modal__img {
        width: 100%;

        img {
            width: 120px;
            height: 120px;
            display: block;
            margin: auto;

            @include x-sm {
                width: 100px;
                height: 100px;
            }
        }
    }

    .delete-modal__content {
        text-align: center;
        margin: 16px 0 24px;

        @include x-sm {
            font-size: 90%;
        }

        .delete-modal__title {
            font-size: 1.5em;
            line-height: 1.2;
            margin: 0 0 0.3em;
            font-weight: 600;
        }

        .delete-modal__subtitle {
            font-size: 0.9em;
            line-height: 1.3;
            color: $black8;
        }

        .delete-moadl_text {
            border-radius: 4px;
            padding: 8px 10px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            width: 100%;
            margin-top: 20px;
        }
    }

    .delete-modal__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            margin: 0 4px;
        }
    }

    .expolore-power-jrnler {
        text-align: center;
        margin-top: 15px;
        color: var(--primary-color);
    }
}
