@import '../global-variables.scss';

/*-------------------------------------------------------------
  Delete modal
-------------------------------------------------------------*/

.onboarding-modal {
    width: 100%;
    padding: 24px;

    .onboarding-modal__img {
        width: 100%;

        img {
            width: 50px;
            height: 50px;
            display: block;
            margin: auto;

            @include x-sm {
                width: 50px;
                height: 50px;
            }
        }
    }

    .onboarding-modal__content {
        margin: 16px 0 24px;

        @include x-sm {
            font-size: 90%;
        }

        .onboarding-modal__title {
            text-align: center;
            font-size: 1.5em;
            line-height: 1.2;
            margin: 0 0 1em;
            font-weight: 600;
        }

        .onboarding-modal__subtitle {
            font-size: 0.9em;
            line-height: 1.3;
            color: $black8;
            font-weight: 400;
            margin: 0 0 3em;
        }

        .onboarding-moadl_text {
            border-radius: 4px;
            padding: 8px 10px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            width: 100%;
            margin-top: 20px;
        }

        label {
            font-size: 14px;
        }
    }

    .donation-modal__actions {
        align-items: center;
        justify-content: center;

        .btn-continue {
            color: #ffffff;
            background: linear-gradient(
                34.46deg,
                #8228d2 -12.18%,
                #007bff 51.54%,
                #51a5ff 84.92%
            );
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
            padding-left: 48px;
            padding-right: 48px;
        }

        .go-back-div {
            width: 100%;

            .go-back-dashboard {
                display: table;
                margin: auto;
                border-bottom: 1px solid blue;
            }
        }
    }

    .onboarding-modal__actions {
        display: flex;
        align-items: center;
        justify-content: space-between; 

        .btn {
            margin: 0 4px;
        }

        .btn-continue {
            color: #ffffff;
            background: linear-gradient(
                34.46deg,
                #8228d2 -12.18%,
                #007bff 51.54%,
                #51a5ff 84.92%
            );
        }

        .btn-skip {
            left: 0;
        }

        .btn-back {
            flex: 1;
        }

        div {
            flex: 1;
        }

        .btn-timeline {
            flex: 1;
        }
    }

    .onboarding-modal__actions__timeline {
        // margin-left: 40px;
        // margin-right: 40px;
        justify-content: space-between;
    }
}
