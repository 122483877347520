@import '../global-variables.scss';

/*-------------------------------------------------------------
  Drawer
-------------------------------------------------------------*/

.drawer {
    position: fixed;
    z-index: 5;
    display: flex;
    top: 100px;
    bottom: 0;
    background-color: white;
    box-shadow: $shadow-drawer;
    width: 300px;
    @extend .scrollable;

    @include x-md {
        top: 90px;
    }
}

.drawer__content {
    width: 100%;
}

.drawer__heading {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;
    padding-right: 8px;

    .drawer__title {
        color: black;
        padding-left: 8px;
        margin-bottom: 0;
        font-size: 1.1em;
        font-weight: 500;
    }
}

/*--------------------------------
  Drawer left
---------------------------------*/

.drawer--left {
    transition: $trans1;
    transform: translate(-320px, 0px);

    .drawer__content {
        .drawer__section {
            padding: 16px;
        }
    }
}

/*--------------------------------
  Drawer right
---------------------------------*/

.drawer--right {
    .drawer__content {
        .drawer__section {
            padding: 16px 24px;

            .drawer__title {
                padding: 0;
            }
        }
    }

    .list--contacts {
        border: none;

        .list__item {
            border: none;
        }
    }

    .form-group__item {
        padding: 20px 0 10px;
        margin: 0;
    }

    .form-group--text-size {
        margin: 0;

        .form-group__item {
            padding-bottom: 0;
        }
    }

    .dropdown {
        .dropdown-menu {
            min-width: inherit;
            width: 100% !important;
            top: 28px !important;
            left: 0 !important;
            transform: inherit !important;
        }
    }
}

#drawer_right {
    transform: translate(320px);
    transition: all 0.15s ease-in-out;

    &.show-drawer {
        transform: translate(0);
        transition: all 0.15s ease-in-out;
    }
}

/*-------------------------------------------------------------
  Journals and Tags
-------------------------------------------------------------*/

.btn-add-journals,
.btn-add-tags {
    padding: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.tags-list-toggle,
.journal-list-toggle {
    display: flex;
    align-items: center;
    color: #5f5f5f;
    font-size: 0.9375rem;
    font-weight: normal;
    margin-left: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}

.tags-list-toggle > p,
.journal-list-toggle > p {
    margin-bottom: 0;
    padding-left: 0.625rem;
}

.filter-list {
    li {
        position: relative;

        .filter-list__content {
            display: flex;
            align-items: center;
            color: $black6;
            font-size: 0.875rem;
            padding: 10px;
            transition: $trans1;
            position: relative;
            border-radius: 4px;
            cursor: pointer;

            .truncate {
                max-width: 200px;
            }

            &:hover {
                transition: $trans2;
                color: var(--primary-color);
                background: var(--primary1);

                &.active-item-list {
                    color: black !important;
                    background: transparent !important;
                    cursor: default !important;

                    img {
                        filter: var(--filter-primary);
                        opacity: 1;
                    }
                }

                img {
                    filter: var(--filter-primary);
                    opacity: 1;
                }
            }

            img {
                margin-right: 12px;
            }
        }

        .close {
            display: none;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 8px;
            right: 8px;
            padding: 5px;
        }

        &.active-item-list {
            .filter-list__content {
                color: black !important;
                font-weight: 500;

                img {
                    filter: var(--filter-primary);
                    opacity: 1;
                }
            }

            .close {
                display: block;
            }
        }
    }
}

.journal-item,
.tag-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 11rem;
    display: inline-block;
}

.tag-list > li:hover,
.journal-list > li:hover {
    color: var(--primary-color) !important;
    border-radius: 0.3125rem;
    background-color: rgba(17, 100, 251, 0.1);
}

/*-------------------------------------------------------------
  Manage entries
-------------------------------------------------------------*/

.btn-drawer {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: $trans1;
    width: 100%;

    img {
        width: 20px;
        height: 20px;
        opacity: 0.6;
        transition: $trans1;

        &.left {
            margin-right: 10px;
        }
    }

    &:hover {
        color: var(--primary-color);

        img {
            filter: var(--filter-primary);
            opacity: 1;
            transition: $trans2;
        }
    }
}

.btn-finish {
    width: 100%;
    border-radius: 4px;
}

.btn-drawer--manage {
    margin-bottom: 16px;
}

/*--------------------------------
  Manage entries list
---------------------------------*/

.manage-entries-list {
    padding: 10px;
    margin: 8px 0 16px;
    border: 1px solid $black1;
    border-radius: 4px;
}

.manage-entries-list__btn {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    transition: $trans1;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        transition: $trans1;
        opacity: 0.6;
    }

    &:hover {
        color: var(--primary-color);

        img {
            filter: var(--filter-primary);
            transition: $trans2;
            opacity: 1;
        }
    }
}

/*-------------------------------------------------------------
  Display
-------------------------------------------------------------*/

.display-filter-form {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 1.5rem;
}

.display-filter-form > .form-group {
    font-size: 0.9375rem;
    font-weight: normal;
    margin-bottom: 1.4375rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.display-filter-form > .form-group > label {
    padding: 0;
    margin-left: 0.625rem;
    cursor: pointer;
}

.display-filter-form > .form-group > input {
    border: 0.125rem solid rgb(0, 0, 0);
    border-radius: 50%;
    width: 1.0625rem;
    height: 1.0625rem;
    position: relative;
}

.display-filter-form > .form-group > input:checked::after {
    content: '';
    position: absolute;
    content: '';
    top: 0.2rem;
    left: 0.2rem;
    width: 0.4375rem;
    height: 0.4375rem;
    border-radius: 50%;
    background: rgb(0, 0, 0);
}
