@import '../global-variables.scss';

/*-------------------------------------------------------------
  Choose account
-------------------------------------------------------------*/

.first-card-mr {
  margin-right: 1.875rem;
}

.choose-account-card {
  max-width: 23.4375rem;
  width: 100%;
  height: 33.25rem;
  border-radius: 1.25rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding-left: 2.125rem;
  padding-right: 2.125rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.choose-account-divider {
  max-width: 4.625rem;
  width: 100%;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-right: 2.375rem;
  margin-left: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-account-divider::before {
  content: '';
  position: absolute;
  top: 1.5rem;
  border-right: 0.5rem solid #ffffff;
  border-radius: 5px;
  height: 9.125rem;
}

.choose-account-divider::after {
  content: '';
  position: absolute;
  bottom: 1.5rem;
  border-right: 0.5rem solid #ffffff;
  border-radius: 0.3125rem;
  height: 9.125rem;
}

.or-card {
  max-width: 4.625rem;
  width: 100%;
  height: 4.625rem;
  border-radius: 0.9375rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-card > p {
  font-size: 2.125rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #00416a;
}

.account-features {
  margin-bottom: 2.5rem !important;
}

.account-features > li {
  margin-bottom: 0.8125rem;
  font-size: 1.125rem;
  font-weight: normal;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.125rem;
}

.account-features-spacing {
  margin-bottom: 3.25rem !important;
}

.account-features-spacing > li {
  margin-bottom: 1rem !important;
}

.account-features > li::before {
  content: '\f111';
  position: absolute;
  left: 0;
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  color: #00416a;
  font-size: 0.5rem;
}

.choose-account-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.choose-account-plan {
  font-size: 2.125rem;
  font-weight: normal;
  color: #00416a;
  margin-bottom: 0;
  max-width: 11rem;
}

.choose-account-plan-info {
  text-align: right;
  font-size: 2.125rem;
  font-weight: 500;
  color: #00416a;
  margin-bottom: 0;
}

.choose-account-plan-info-medium {
  font-size: 1.125rem !important;
  font-weight: 500;
}

.choose-account-discount {
  display: block;
  text-align: right;
  margin-top: 0.625rem;
  font-size: 1.5rem;
  font-weight: 500;
  opacity: 0.7;
  line-height: 0.92;
  color: #00416a;
  position: relative;
}

.choose-account-discount::after {
  position: absolute;
  content: '';
  width: 3rem;
  top: 45%;
  right: -0.1875rem;
  border-top: 0.1875rem solid;
  border-color: inherit;

  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

.choose-account-desc {
  line-height: 26px;
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.choose-account-desc-info {
  font-size: 1.125rem;
  font-weight: 300;
  width: 100%;
  margin-bottom: 0;
}

.choose-account-desc-price {
  font-size: 0.9375rem;
  font-weight: 500;
  width: 6.5625rem;
  color: #00416a;
  font-style: italic;
  margin-bottom: 0;
}

.accounts-features-header {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.8125rem;
}

.btn-plan {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19.0625rem;
  height: 5.625rem;
  border-radius: 0.625rem !important;
  line-height: 1;
}

.btn-get-free {
  font-size: 1.75rem !important;
  font-weight: 500;
  color: #000000;
  border: solid 0.125rem var(--primary-color);
}

.btn-get-free:hover {
  color: #000000;
}

.btn-get-upgrade {
  font-size: 1.75rem !important;
  font-weight: 500;
  width: 19.0625rem;
  height: 5.625rem;
  border-radius: 0.625rem;
  background-color: var(--primary-color);
  color: #ffffff;
  flex-direction: column;
}

.get-save-text {
  display: flex;
  /* display: block; */
  padding-top: 0.25rem;
  font-size: 1.5rem;
  font-weight: normal;
}

.comparison-plan > .container {
  max-width: 56.25rem;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.comparison-plan > .container > .row {
  margin-left: 0;
  margin-right: 0;
}

.comparison-plan-header {
  font-size: 2.5rem;
  font-weight: normal;
  color: #00416a;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 4.75rem;
}

.comparison-table-content > .col-4 {
  min-width: 19.9375rem;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.comparison-table-title {
  font-size: 2.125rem;
  font-weight: bold;
  color: #00416a;
  margin-bottom: 1.25rem;
  text-align: center;
}

.comparison-table-text {
  font-size: 2.125rem;
  font-weight: 500;
  color: #00416a;
  text-align: center;
}

.comparison-table-text > span {
  font-size: 1.25rem;
  font-weight: 500;
}

.comparison-plan-content {
  min-width: 16.375rem;
  height: auto;
  padding-top: 1.375rem;
  padding-left: 1.25rem;
  padding-bottom: 1.25rem;
  border: solid 0.0625rem #979797;
  background-color: #ffffff;
}

.comparison-plan-title {
  font-size: 1.5rem;
  font-weight: normal;
  color: #000000;
  margin-bottom: 0.625rem;
}

.comparison-plan-info {
  font-size: 1.125rem;
  font-weight: 300;
  color: #666666;
  margin-bottom: 0;
}

.graphic-first-plan-table {
  position: relative;
}

.graphic-first-plan-table::before {
  content: '';
  position: absolute;
  z-index: -1;
  background-image: url('../../images/static-pages/undraw-typewriter.svg');
  background-repeat: no-repeat;
  background-size: 35.125rem 24.3125rem;
  width: 35.125rem;
  height: 24.3125rem;
  opacity: 0.75;
  right: -40%;
  top: 1.4375rem;
}

.graphic-second-plan-table {
  position: relative;
}

.graphic-second-plan-table::before {
  content: '';
  position: absolute;
  z-index: -1;
  background-image: url('../../images/static-pages/undraw-book-lover.svg');
  background-repeat: no-repeat;
  width: 28.8125rem;
  height: 33.625rem;
  opacity: 0.75;
  left: -25.5rem;
  top: 7px;
}

.col-4.comparison-bg {
  height: auto;
  min-width: 19.9375rem;
  width: 100%;
  background-color: #0064ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #ffffff;
  border: solid 0.0625rem #979797;
  padding-left: 0;
  padding-right: 0;
}

.col-4.comparison-bg:nth-child(3n + 2) {
  border-right: 0.125rem solid #fff;
}

.col-8.comparison-bg {
  min-width: 39.875rem;
  height: 7.6875rem;
  background-color: #01b5cb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #ffffff;
  border: solid 0.0625rem #979797;
}

.comparison-bg > p {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
}

.comparison-bg > p > span {
  display: block;
  padding-top: 0.8125rem;
  font-size: 1.5rem;
}

.comparison-actions {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.comparison-actions > .col-4 {
  min-width: 19.9375rem !important;
  display: flex;
  justify-content: center;
}

.comparison-actions > .col-4 > .btn {
  width: 17.5625rem !important;
  margin-right: 0 !important;
}

.save-memories-card {
  margin-top: 5.125rem;
  margin-bottom: 18.375rem;
  max-width: 55.875rem;
  width: 100%;
  height: 21.5rem;
  border-radius: 0.9375rem;
  background-color: #d8ddf2;
  display: flex;
  align-items: center;
}

.save-memories-card > img {
  margin-right: 3.4375rem;
}

.save-memories-card-title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #00416a;
  margin-bottom: 1.25rem;
}

.save-memories-card-text {
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 0;
}