@import '../global-variables.scss';

/*-------------------------------------------------------------
  List
-------------------------------------------------------------*/

.list {
    border-top: 1px solid $black1;
    margin: 0 0 24px !important;
    width: 100%;

    .list__item {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid $black1;
        width: 100%;
        position: relative;

        @include x-sm {
            padding: 12px 0;
        }

        &.list__item--stack {
            flex-direction: column;

            .list__row {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }

        &.list__item--link {
            cursor: pointer;

            &:hover {
                background-color: var(--primary1);
                transition: $trans2;
            }

            &.active {
                background-color: var(--primary-color);
                color: white;

                .list__subtitle {
                    color: $white8;
                }
            }
        }

        .list__img {
            margin-right: 16px;
            flex: none;

            .fa-check {
                width: 30px;
                height: 30px;
                font-size: 20px;
                line-height: 1.6;
            }
        }

        .list__img--card {
            img {
                width: 36px;
                height: auto;
                border-radius: 3px;
                box-shadow: 0 0 0 1px $black2, 0 1px 2px $black3;
            }
        }

        .list__img--icon-sm {
            img {
                width: 16px;
                height: 16px;
            }
        }

        .list__img--icon-md {
            img {
                width: 24px;
                height: 24px;
            }
        }

        .list__img--avatar {
            img {
                width: 40px;
                height: 40px;
                border-radius: 100px;
            }
        }

        .list__img--social {
            width: 40px;
            height: 40px;
            line-height: 37px;
            text-align: center;
            border-radius: 10px;

            &:after {
                font-size: 24px;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                color: white;
                width: 40px;
                height: 40px;

                &:before {
                    font-size: 24px;
                }
            }

            img {
                width: 24px;
                height: 24px;
                filter: var(--filter-white);
            }
        }

        .list__content {
            flex-grow: 1;
            overflow: hidden;

            .list__title {
                font-size: 1em;
                line-height: 1.25;
                margin: 0 0 2px;
                word-break: break-word;

                a {
                    color: #000;
                }
            }

            .list__title--link {
                transition: $trans1;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                    transition: $trans2;
                }

                //span {
                //white-space: nowrap;
                //}
            }

            .list__subtitle {
                font-size: 0.85em;
                line-height: 1.2;
                color: $black7;
                margin: 0;

                .list__subtitle-item {
                    display: block;
                    margin-bottom: 2px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .list__content-actions {
                margin-top: 4px;

                button {
                    border: none;
                    background: transparent;
                    margin-right: 8px;
                    font-size: 0.85em;
                    opacity: 0.6;
                    transition: $trans1;

                    &:hover {
                        color: var(--primary-color);
                        transition: $trans2;
                        opacity: 1;
                    }
                }
            }
        }

        .list__actions {
            display: flex;
            align-items: center;
            flex: none;

            .list__button {
                margin-left: 4px;
                background: transparent;
                border: none;
                outline: none;
                width: 24px;
                height: 24px;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;
                    pointer-events: none;
                }
            }
        }

        .list__hotspot {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
        }
    }

    .list__status {
        margin-left: 16px;
        display: flex;
        flex: none;
    }

    .list__options {
        display: flex;
        align-items: center;
        margin-left: 16px;
        flex: none;

        button {
            line-height: 0;
        }

        > * {
            margin-left: 8px;
        }

        > .material-dropdown-menu {
            margin-left: 4px;
            position: relative;

            div:last-child {
                top: 28px !important;
                right: 0;
                left: inherit !important;
                transform: none !important;
                z-index: 1;
                box-shadow: none;

                .MuiList-root {
                    padding: 10px;
                    border-radius: 4px;
                    box-shadow: $dropdown-shadow;

                    li {
                        padding: 4px 6px !important;
                        font-size: 14px;
                        transition: $trans1;
                        border-radius: 4px;

                        &:hover {
                            background-color: var(--primary1);
                            transition: $trans2;
                        }
                    }
                }
            }
        }
    }

    .dropdown {
        margin-left: 8px;

        .dropdown-toggle {
            text-align: center;
            width: 16px;
        }

        .dropdown-menu {
            right: 0;
            width: 130px;
        }
    }

    .list-toggles {
        margin-top: 8px;

        .list-toggles__item {
            display: flex;
            align-items: center;
            padding: 6px 0;

            .list-toggles__content {
                flex-grow: 1;

                .list-toggles__title {
                    font-size: 1em;
                    line-height: 1.2;
                    margin: 0 0 2px;
                }

                .list-toggles__subtitle {
                    font-size: 0.85em;
                    line-height: 1.2;
                    color: $black7;
                    margin: 0;
                }
            }
        }
    }
}

/*-------------------------------------------------------------
  List 
-------------------------------------------------------------*/

.list--page {
    margin: 0 !important;

    .list__item {
        padding: 16px 10px 16px 16px;
    }
}

/*-------------------------------------------------------------
  List select
-------------------------------------------------------------*/

.list--select {
    max-height: 400px;
    @extend .scrollable;
    border: 1px solid $black4;
    border-radius: 4px;

    .list__check {
        margin-right: 16px;

        .check-icon {
            box-shadow: inset 0 0 0 1px $black2;
            border-radius: 100px;
            width: 24px;
            height: 24px;
            line-height: 21px;
            text-align: center;
            transition: $trans2;

            img {
                width: 12px;
                height: 12px;
                opacity: 0.2;
            }

            &.check-icon--selected {
                box-shadow: inset 0 0 0 100px var(--green-color2);
                transition: $trans2;

                img {
                    filter: var(--filter-white);
                    opacity: 1;
                }
            }
        }
    }

    .list__item {
        padding: 10px 16px;
        transition: $trans2;
        cursor: pointer;

        &:hover {
            background: var(--primary1);
            transition: $trans1;
        }

        .list__img--avatar {
            img {
                width: 32px;
                height: 32px;
            }
        }
    }
}

/*-------------------------------------------------------------
  2 Col list
-------------------------------------------------------------*/

.list--2col {
    @include lg-x {
        display: flex;

        .list__item:first-child {
            padding-right: 8px;
            border-right: 1px solid $black1;
        }

        .list__item:last-child {
            padding-left: 16px;
        }
    }
}

/*-------------------------------------------------------------
  Contacts list
-------------------------------------------------------------*/

.list--contacts {
    .list__item {
        padding: 10px 0;

        .list__content {
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                color: var(--primary-color);
            }
        }

        .list__img--avatar {
            margin-right: 14px;

            img {
                width: 32px;
                height: 32px;
            }
        }

        .list__content {
            font-size: 90%;
        }
    }
}

.signature {
    position: absolute;
    right: 12px;
    bottom: 12px;
    color: rgba(0, 0, 0, 0.07);
    font-size: 10px;
}

.copy-span {
    position: absolute;
    z-index: 9999;
    top: 30px;
    left: -37px;
    width: 114px;
    height: 36px;
    display: none;
    color: #fff;
    font-size: 10px;
    line-height: 36px;
    text-align: center;

    background: rgba(0, 0, 0, 0.72);
    border-radius: 3px;
}

.copy-to-clipboard {
    &:hover span {
        display: block !important;

        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 9998;
            left: 42%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid rgba(0, 0, 0, 0.72);
            transform: translate(5%, -100%);
        }
    }
}

/*-------------------------------------------------------------
  List Single
-------------------------------------------------------------*/

.list--single {
    border: none;
    box-shadow: $dropdown-shadow;

    .list__item {
        border: none;
    }

    .list__img {
        margin-left: 16px;
    }
}
