@import '../global-variables.scss';

/*-------------------------------------------------------------
  Contacts modal
-------------------------------------------------------------*/

.edit-contact-card {
    margin-top: 20%;
    max-width: 34.375rem;
    width: 100;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 #00000017;
    background-color: #fffdff;
    padding-top: 1.7813rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    padding-bottom: 3.125rem;
}

.add-contact-card {
    margin-top: 20%;
    max-width: 34.375rem;
    width: 100%;
    height: 37.8125rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding-top: 1.875rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    padding-bottom: 1.875rem;
}

.edit-contact-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.edit-contact-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
}

.edit-contact_content {
    display: flex;
    align-items: center;
    margin-bottom: 1.375rem;
}

.edit-contact_content-info > h3 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: #1e1e1e;
    border-bottom: solid 0.0625rem #0064fb;
    margin-bottom: 1.5rem;
}

.edit-contact_content-info {
    width: 70%;
}

.edit-contact_content-info > p {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000000;
    border-bottom: solid 0.0625rem #0064fb;
    margin-bottom: 0;
}

.edit-contact_content-img {
    margin-right: 1.5625rem;
    position: relative;
}

.edit-contact_content-img > img {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 50%;
    opacity: 0.5;
}

.edit-contact_content-info > p > input,
.edit-contact_content-info > h3 > input {
    width: 100%;
}

.close-banner-decoration {
    cursor: pointer;
}

.add-contact-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.add-contact-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
}

.search-add-contact {
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #aeaeae;
    margin-bottom: 0.625rem;
    max-width: 15.1875rem;
    width: 100%;
    padding-bottom: 0.375rem;
    padding-left: 0.5625rem;
}

.btn-search-contact {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
    color: #aeaeae;
    font-size: 0.8rem;
}

.btn-search-contact:hover {
    color: #aeaeae;
}

.search-contact-input {
    font-size: 0.875rem;
    font-weight: normal;
    width: 100%;
}

.search-contact-input::-webkit-placeholder,
.search-contact-input::-moz-placeholder,
.search-contact-input::placeholder {
    color: #9e9e9e;
}

.shared-content {
    @media (min-height: 880px) {
        max-height: 500px;
    }
}

.shared-content-title {
    font-weight: 600;
    padding: 16px;
}

/*-------------------------------------------------------------
  Add contacts list
-------------------------------------------------------------*/

.add-contacts-list-content {
    width: 28.125rem;
    height: 18.75rem;
    border-radius: 0.625rem;
    border: 0.0625rem dashed #707070;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}

.selected-contacts-list {
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #aeaeae;
    margin-bottom: 0 !important;
}

.add-contacts-list {
    padding-top: 1.25rem;
    padding-left: 0;
    padding-right: 0;
}

.add-contacts-list > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.9375rem;
}

.add-contacts-list > li > img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.9375rem;
}

.add-contacts-list > li > .contacts-info {
    max-width: 19.25rem;
    width: 100%;
}

.add-contacts-list > li > span {
    color: var(--primary-color);
    font-size: 1.5rem;
}

.btn-confirm {
    margin-top: 0.6875rem;
    margin-bottom: 1.875rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5625rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    line-height: 1;
    color: #ffffff;
}

.btn-confirm:hover {
    color: #ffffff;
}

.btn-confirm > span {
    padding-right: 0.625rem;
}

/*-------------------------------------------------------------
  Import contacts
-------------------------------------------------------------*/

.import-contacts-card {
    margin-top: 10%;
    width: 100%;
    max-width: 34.375rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    padding: 3.125rem;
}

.import-contacts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.875rem;
}

.import-contacts-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;
}

.import-contacts-header > .search-add-contact {
    margin-bottom: 0;
}

.import-contacts-list-content {
    width: 28.125rem;
    height: 26.25rem;
    border-radius: 0.625rem;
    border: 0.0625rem dashed #707070;
    padding-top: 1.25rem;
    padding-left: 1.875rem;
    padding-right: 0.625rem;
}

.import-contacts-list {
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 1.375rem;
}

.import-contacts-list::-webkit-scrollbar {
    width: 0.3125rem;
    margin-right: 0.7188rem;
}

.import-contacts-list::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.25rem;
    margin: 0.625rem;
}

.import-contacts-list::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 0.25rem;
}

.import-contacts-list::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.75);
}

.import-contacts-list > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.9375rem;
}

.import-contacts-list > li > .contacts-info {
    max-width: 19.25rem;
    width: 100%;
}

.import-contacts-list > li > img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.9375rem;
}

.import-contacts-list > li > span {
    color: var(--primary-color);
    font-size: 1.5rem;
}

.import-accounts-no-account-email {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2.5rem;
    padding-left: 0.625rem;
}

.import-accounts-input {
    width: 100% !important;
    border-bottom: 0.0625rem solid #aeaeae;
    margin-right: 0.625rem;
}

.import-accounts-input input {
    width: 80%;
}
.import-accounts-input > i {
    padding-right: 0.625rem;
}

/*-------------------------------------------------------------
  Send email
-------------------------------------------------------------*/

.btn-send-mail {
    width: 6.3125rem;
    height: 2.1875rem;
    background-color: #0064fb;
}

.no-account-email {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 0;
    max-width: 22.75rem;
    width: 100%;
}

.no-account-email > span {
    color: #9e9e9e;
}

.fa-plus-circle.no-account-color {
    opacity: 0.7;
}

.no-account-color {
    color: #05416b !important;
}

.selected-contacts-divider {
    border-bottom: 0.0625rem solid #aeaeae;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem !important;
}

.import-accounts-extra-info {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
    font-size: 0.875rem;
    font-weight: 300;
}

.import-contacts-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.import-contacts-actions > .btn-cancel {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-right: 1.25rem;
    width: 5.875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem var(--primary-color);
    color: #000000;
}

.import-contacts-actions > .btn-cancel:hover {
    color: #000000;
}

.import-contacts-actions > .btn-confirm {
    margin-bottom: 0;
    margin-top: 0;
}

.no-account-card {
    margin-top: 20%;
    max-width: 26.6875rem;
    width: 100%;
    height: 4.5rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-account-card > span {
    padding-right: 0.625rem;
    color: #9e9e9e;
    font-size: 1.5rem;
}

.no-account-content {
    font-size: 0.875rem;
    font-weight: 300;
    color: #000000;
    margin-bottom: 0;
    line-height: 1.14;
}
