@import '../global-variables.scss';

/*-------------------------------------------------------------
  Modals
-------------------------------------------------------------*/

.modal-frame {
    display: flex;
    align-items: flex-start;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $black4;
}

.modal-frame--fill-mobile {
    @include x-sm {
        background-color: white;
    }
}

.MuiDialog-root,
.MuiPopover-root {
    z-index: 10000 !important;
}

.modal-frame,
.MuiDialog-paper {
    @include x-sm {
        padding: 0;
    }
    @include sm-x {
        padding: 5%;
    }
}

.modal-styles {
    box-shadow: $modal-shadow;
    background: white;
}

.modal-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    position: relative;

    @include x-sm {
        height: 100%;
    }
    @include sm {
        border-radius: 8px;
        box-shadow: $modal-shadow;
    }
    @include sm-x {
        margin-left: auto;
        margin-right: auto;
        height: fit-content;
    }
    @include md-x {
        border-radius: 8px;
        box-shadow: $modal-shadow;
    }
}

.modal-box--gallery {
    @include x-lg {
        height: 100%;
    }

    .modal__body {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;

        @include lg-x {
            max-height: 600px;
        }
    }
}

@include sm-x {
    .modal-box--300 {
        max-width: 300px;
    }

    .modal-box--400 {
        max-width: 400px;
    }

    .modal-box--500 {
        max-width: 500px;
    }

    .modal-box--600 {
        max-width: 600px;
    }

    .modal-box--700 {
        max-width: 700px;
    }

    .modal-box--800 {
        max-width: 800px;
    }
}

.modal__header {
    font-size: 1.25rem;
    font-weight: 600;
    padding: 16px;
    margin: 0;
    flex: none;
    position: relative;

    span {
        font-weight: 700;
    }
}

.modal__subheader {
    font-size: 1rem;
    font-weight: 400;
    padding: 16px;
    margin: 0;
}

.modal__header--button {
    display: flex;
    justify-content: space-between;
}

.modal__close {
    @extend .btn-icon;
    @extend .btn-icon--3;
    position: absolute;
    right: 13px;
    top: 18px;

    img {
        width: 14px;
        height: 14px;
    }
}

.modal__body {
    padding: 0 16px;
    flex: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    p {
        line-height: 1.4;

        &:last-child {
            margin: 0;
        }
    }
}

.modal__box--dropzone {
    .modal__body {
        padding-bottom: 16px;
    }
}

.modal__options {
    padding: 16px 16px 0;
}

.modal__footer {
    display: flex;
    flex: none;
    justify-content: space-between;
    padding: 16px;
}

.MuiDialog-paper {
    border-radius: 0 !important;
    position: fixed !important;
    top: 0;
    bottom: 0;
    box-shadow: none;
    overflow: inherit;
    width: 100% !important;
    max-width: inherit !important;
    height: 100% !important;
    max-height: inherit !important;
    background: transparent;
    margin: 0 !important;
    background: transparent !important;
}

/*-------------------------------------------------------------
  Delete modal
-------------------------------------------------------------*/

.modal-box--delete {
    width: 90%;
    margin: 16px auto;
    height: auto;
    display: block;
    box-shadow: $modal-shadow;
    border-radius: 8px;
}

.modal-tooltip {
    color: #0064ff;
    font-size: small;
    margin-top: 5px;
}
