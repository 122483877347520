@import '../global-variables.scss';

/*-------------------------------------------------------------
  Import file modal
-------------------------------------------------------------*/

.import-file-modal {
    display: flex;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.import-file-content {
    margin-top: 20%;
    max-width: 36.3125rem;
    border-radius: 0.3125rem;
    background-color: #ffffff;
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.875rem;
    padding-left: 1.8125rem;
}

.import-file-content-header {
    display: flex;
    justify-content: space-between;
}

.import-file-content-title {
    font-size: 1.625rem;
    font-weight: bold;
    margin-left: 16px;
}

.import-file-content-close {
    font-size: 1rem;
}

.import-file-content-close::before {
    color: #000000 !important;
}

.import-file-content-box {
    margin: 16px 0;
}

.import-file-content-box > .box-input {
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.import-file-content-box > .box-input > .photos-action > label {
    padding: 0;
}

.import-file-content-actions {
    margin-top: 1.2688rem;
}

.import-file-content-actions-subtitle {
    margin-bottom: 0;
    padding-bottom: 0.9375rem;
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #000;
}

.import-file-content-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.import-file-content-btns > button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.import-file-content-btns > button > span {
    padding-right: 0.625rem;
}
