/*-------------------------------------------------------------
 Queries
-------------------------------------------------------------*/

@mixin ss {
    @media (min-width: 0px) and (max-width: 359.98px) {
        @content;
    }
}
@mixin xs {
    @media (min-width: 360px) and (max-width: 575.98px) {
        @content;
    }
}
@mixin sm {
    @media (min-width: 576px) and (max-width: 767.98px) {
        @content;
    }
}
@mixin md {
    @media (min-width: 768px) and (max-width: 991.98px) {
        @content;
    }
}
@mixin lg {
    @media (min-width: 992px) and (max-width: 1199.98px) {
        @content;
    }
}
@mixin xl {
    @media (min-width: 1200px) and (max-width: 1499.98px) {
        @content;
    }
}
@mixin xxl {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin xs-x {
    @media (min-width: 360px) {
        @content;
    }
}
@mixin sm-x {
    @media (min-width: 576px) {
        @content;
    }
}
@mixin md-x {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin lg-x {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin xl-x {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin xxl-x {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin xs-md {
    @media (min-width: 360px) and (max-width: 767.98px) {
        @content;
    }
}
@mixin sm-lg {
    @media (min-width: 576px) and (max-width: 991.98px) {
        @content;
    }
}
@mixin sm-xl {
    @media (min-width: 576px) and (max-width: 1199.98px) {
        @content;
    }
}
@mixin md-xl {
    @media (min-width: 768px) and (max-width: 1199.98px) {
        @content;
    }
}
@mixin lg-xxl {
    @media (min-width: 992px) and (max-width: 1799.98px) {
        @content;
    }
}

@mixin x-xs {
    @media (max-width: 359.98px) {
        @content;
    }
}
@mixin x-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}
@mixin x-md {
    @media (max-width: 767.98px) {
        @content;
    }
}
@mixin x-lg {
    @media (max-width: 991.98px) {
        @content;
    }
}
@mixin x-xl {
    @media (max-width: 1199.98px) {
        @content;
    }
}
@mixin x-xxl {
    @media (max-width: 1799.98px) {
        @content;
    }
}

/*-------------------------------------------------------------
 Blacks and Whites
-------------------------------------------------------------*/

$black-light1: rgba(0, 0, 0, 0.01);
$black-light2: rgba(0, 0, 0, 0.02);
$black-light3: rgba(0, 0, 0, 0.04);
$black-light4: rgba(0, 0, 0, 0.04);
$black-light5: rgba(0, 0, 0, 0.05);

$black0: rgba(0, 0, 0, 0.05);
$black1: rgba(0, 0, 0, 0.1);
$black2: rgba(0, 0, 0, 0.2);
$black3: rgba(0, 0, 0, 0.3);
$black4: rgba(0, 0, 0, 0.4);
$black5: rgba(0, 0, 0, 0.5);
$black6: rgba(0, 0, 0, 0.6);
$black7: rgba(0, 0, 0, 0.7);
$black8: rgba(0, 0, 0, 0.8);
$black9: rgba(0, 0, 0, 0.9);
$black10: rgba(0, 0, 0, 0.95);

$white0: rgba(255, 255, 255, 0.05);
$white1: rgba(255, 255, 255, 0.1);
$white2: rgba(255, 255, 255, 0.2);
$white3: rgba(255, 255, 255, 0.3);
$white4: rgba(255, 255, 255, 0.4);
$white5: rgba(255, 255, 255, 0.5);
$white6: rgba(255, 255, 255, 0.6);
$white7: rgba(255, 255, 255, 0.7);
$white8: rgba(255, 255, 255, 0.8);
$white9: rgba(255, 255, 255, 0.9);
$white10: rgba(255, 255, 255, 0.95);

/*-------------------------------------------------------------
  Transistions
-------------------------------------------------------------*/

$trans1: all 0.1s ease-in-out;
$trans2: all 0.2s ease-in-out;
$trans3: all 0.3s ease-in-out;
$trans4: all 0.4s ease-in-out;
$trans5: all 0.5s ease-in-out;
$trans10: all 1s ease-in-out;

/*-------------------------------------------------------------
  Font Sizes
-------------------------------------------------------------*/

$body-1: calc(2.2rem + 1.5vw);
$body-2: calc(1.8rem + 1.2vw);
$body-3: calc(1.6rem + 1vw);
$body-4: calc(1.4rem + 0.8vw);
$body-5: calc(1.2rem + 0.6vw);
$body-6: calc(1rem + 0.5vw);
$body: calc(0.9rem + 0.4vw);
$body-sm: calc(0.8rem + 0.3vw);
$body-xs: calc(0.7rem + 0.3vw);

/*-------------------------------------------------------------
  Other elements
-------------------------------------------------------------*/

/* Borders */

$border-line1: 1px solid $black1;
$border-line2: 1px solid $black2;

/* Drop shadows */

$dropdown-shadow: 0 0 0 1px $black1, 0 3px 8px $black3;
$modal-shadow: 0 2px 3px $black2, 0 8px 16px $black6;
$shadow-frame: rgba(0, 0, 0, 0.2) 0px 0 2px, rgba(0, 0, 0, 0.1) 0px 0px 6px;
$shadow-drawer: rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.2) 0px 0px 8px;
$shadow-tile: rgba(0, 0, 0, 0.2) 0px 1px 2px, rgba(0, 0, 0, 0.1) 0px 4px 6px;

/* Page width */

$page-width: 680px; // page max-width setting
