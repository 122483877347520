@import '../global-variables.scss';

/*-------------------------------------------------------------
  Email to JRNL
-------------------------------------------------------------*/

.emailJRNL-settings {
    .journals-email-section {
        margin: 0 0 24px;
    }
}

.authorised-emails-section > p {
    max-width: 25rem;
}

.address-card {
    margin-top: 20%;
    max-width: 27.8125rem;
    height: 15.3125rem;
    padding-top: 2.0938rem;
    padding-left: 3.125rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
}

.add-sender-form > .settings-select > label,
.edit-sender-form > .settings-select > label,
.edit-address-form > .settings-select > label,
.add-address-form > .settings-select > label {
    display: flex;
}

.form-address-actions {
    display: flex;
    align-items: center;
    margin-top: 2.25rem;
}

.btn-add-address > span,
.btn-edit-address > span {
    padding-right: 0.625rem;
}

.btn-add-address,
.btn-edit-address {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 9.9375rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    color: #ffffff;
}

.btn-form-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #000000;
    width: 5.875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #000000;
    margin-right: 10px;
}

.btn-form-delete > span {
    padding-right: 0.625rem;
}

.btn-form-delete:hover,
.btn-form-cancel:hover {
    color: #000000;
}

.generate-card {
    margin-top: 20%;
    background-color: #fffdff;
}

.generate-card-actions {
    display: flex;
    align-items: center;
    margin-top: 1.6875rem;
}

.generate-card-actions > .btn-cancel {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 5.3375rem;
    height: 2.1875rem;
    border: solid 0.0625rem var(--primary-color);
    background-color: #fffdff;
    color: #000000;
    margin-right: 0.625rem;
}

.generate-card-actions > .btn-cancel:hover {
    color: #000000;
}

.btn-reload {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5rem;
    height: 2.1875rem;
    background-color: var(--primary-color);
    line-height: 1;
}

.duplicated-card {
    margin-top: 20%;
    background-color: #fffdff;
    height: 100% !important;
}

.duplicated-card-actions {
    display: flex;
    align-items: center;
    margin-top: 1.6875rem;
    margin-bottom: 1.5625rem;
}

.duplicated-card-actions > .btn-cancel {
    line-height: 1;
    width: 6.25rem;
    height: 2.1875rem;
    border: solid 0.0625rem #1164fb;
    background-color: #fffdff;
    color: #000000;
    margin-right: 0.625rem;
}

.duplicated-card-actions > .btn-add-account {
    width: 10.625rem;
    height: 2.1875rem;
    background-color: #1164fb;
    color: #ffffff;
}

.duplicated-card-actions > .btn-cancel:hover {
    color: #000000;
}
