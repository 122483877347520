@import '../global-variables.scss';

//-------------------------------------------------------------
//  Front Header
//-------------------------------------------------------------

.header {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 4px;

        @include x-sm {
            padding-right: 16px;
            height: 40px;
        }
        @include sm-x {
            height: 70px;
        }

        .header__logo {
            img {
                width: auto;

                @include x-sm {
                    height: 30px;
                }
                @include sm-x {
                    height: 50px;
                }
            }
        }

        .header__link {
            transition: $trans2;
            line-height: 2;
            box-shadow: 0 0 0 1px var(--primary-color);
            color: var(--primary-color);
            border-radius: 100px;
            padding: 0 16px;

            @include x-sm {
                font-size: 0.7em;
            }
            @include sm {
                font-size: 0.9em;
            }

            &:hover {
                filter: var(--hover);
                transition: $trans2;
            }
        }
    }
}
