@import '../global-variables.scss';

/*-------------------------------------------------------------
  Fullscreen modal
-------------------------------------------------------------*/

.fullscreen-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-image: url('../../images/timeline-images/timeline-bg.png');
}

.fullscreen-nav {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
}

.btn-expanded {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #ffffff;
  background-color: var(--primary-color);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem !important;
}

.btn-expanded:hover {
  color: #ffffff;
}

.btn-sharing {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 2.375rem;
  height: 2.1875rem;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #dadada;
  background-color: #fffdff;
}

.fullscreen-w {
  max-width: 44.3125rem !important;
  width: 100%;
}

.reading-bar {
  max-width: 11.9375rem !important;
  -ms-flex: auto !important;
  flex: auto !important;
  width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  margin-right: 1.875rem;
}

.reading-type {
  max-width: 11.9375rem;
  height: 7.75rem;
  border-radius: 0.3125rem;
  background-color: rgba(255, 253, 255, 0.8);
  padding-left: 0.75rem;
  padding-bottom: 1.125rem;
  margin-bottom: 0.625rem;
}

.reading-view {
  max-width: 11.9375rem;
  height: 10.125rem;
  border-radius: 0.3125rem;
  background-color: rgba(255, 253, 255, 0.8);
  padding-left: 0.75rem;
  padding-bottom: 1.125rem;
  margin-bottom: 0.625rem;
}

.help-bar-title {
  padding-top: 0.9375rem;
  padding-bottom: 0.8125rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #aeaeae;
}

.help-bar-group {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 1.25rem;
}

.help-bar-label {
  padding: 0 0 0 0.5625rem;
  font-size: 0.9375rem;
  font-weight: 500;
  cursor: pointer;
}

.help-bar-radio {
  background-color: #ffffff;
  border: 0.125rem solid rgb(0, 0, 0);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
}

.help-bar-radio:checked::after {
  position: absolute;
  content: '';
  top: 0.3333rem;
  left: 0.3333rem;
  width: 35%;
  height: 35%;
  border-radius: 50%;
  background: rgb(0, 0, 0);
}