@import '../global-variables.scss';

/*-------------------------------------------------------------
  Invitations
-------------------------------------------------------------*/

.invite-form {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 10px;
  border: 1px solid $black3;
  width: 100%;
  margin: 0 0 16px;

  .invite-form__code {
    flex-grow: 1;
  }

  .invite-form__button {
    margin-left: 4px;
    background: transparent;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    cursor: pointer;

    object {
      width: 13px;
      height: 13px;
      pointer-events: none;
    }
  }
}

.invite-options {
  display: flex;
  align-items: center;

  .invite-options__or {
    margin-right: 8px;
  }

  .invite-options__icons {
    .invite-options__icon {
      color: white;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 16px;
      border-radius: 6px;
      margin-right: 6px;
      cursor: pointer;
      transition: $trans1;

      &:hover {
        transition: $trans2;
        filter: var(--hover);
      }
    }
  }
}
