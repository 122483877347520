@import '../global-variables.scss';

//-------------------------------------------------------------
//  Hero
//-------------------------------------------------------------

.hero {
    position: relative;

    .container {
        display: grid;
        text-align: center;
        position: relative;
        z-index: 1;

        @include x-sm {
            padding: 4em 0 10em;
            grid-row-gap: 0.5rem;
            overflow: hidden;
        }
        @include sm {
            grid-row-gap: 0.4rem;
            padding: 5em 0 8em;
        }
        @include md {
            grid-row-gap: 0.5rem;
            padding: 10em 0 12em;
        }
        @include lg-x {
            grid-row-gap: 0.5rem;
            padding: 12em 0 16em;
        }
    }
}

.hero__title {
    font-weight: 700;
    line-height: 1.1;
    margin: 0;

    @include ss {
        font-size: 2.2em;
        padding: 0 1em;
    }
    @include xs {
        font-size: 2.6em;
        padding: 0 1em;
    }
    @include sm {
        font-size: 3em;
    }
    @include md {
        font-size: 3.6em;
    }
    @include lg-x {
        font-size: 5em;
    }
}

.hero__subtitle {
    line-height: 1.4;
    font-weight: 300;
    color: $black7;
    padding: 0 2em;
    margin: 0 auto;

    @include ss {
        font-size: 0.8em;
        max-width: 90%;
    }
    @include xs {
        font-size: 1em;
        max-width: 80%;
    }
    @include sm {
        font-size: 1em;
        max-width: 400px;
    }
    @include md {
        font-size: 1.2em;
        max-width: 470px;
    }
    @include lg-x {
        font-size: 1.4em;
        max-width: 500px;
    }
}

.hero__cta {
    width: fit-content;
    margin: 1em auto;
}

.hero__download {
    margin: 0 auto;
    color: $black6;

    @include x-sm {
        font-size: 12px;
    }
}

.hero-apps {
    display: flex;
    justify-content: center;

    a {
        transition: $trans1;

        &:hover {
            opacity: 0.9;
            transform: scale(1.03);
            transition: $trans2;
        }
    }
}

.hero-app__item {
    display: block;
    margin: 8px;

    img {
        display: block;
        width: auto;

        @include x-md {
            height: 35px;
        }
        @include md-x {
            height: 50px;
        }
    }
}

.hero-img--top {
    top: -70px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;

    @include x-sm {
        display: none;
    }
    @include sm-xl {
        width: 50%;
    }
    @include xl-x {
        max-width: 760px;
    }
}

.hero-img--left,
.hero-img--right {
    bottom: 0;

    @include x-sm {
        display: none;
    }
}

.hero-img--left {
    @include sm {
        left: -75%;
    }
    @include md {
        left: -70%;
    }
    @include lg {
        left: -50%;
    }
    @include xl-x {
        left: -30%;
    }
}

.hero-img--right {
    @include sm {
        right: -75%;
    }
    @include md {
        right: -70%;
    }
    @include lg {
        right: -50%;
    }
    @include xl-x {
        right: -30%;
    }
}

.hero-img--bottom {
    position: absolute;
    bottom: 0;
    margin: auto;
    z-index: 0;

    @include x-sm {
        display: none;
    }
    @include sm-x {
        left: -50%;
        right: -50%;
    }
    @media (min-width: 1400px) and (max-width: 1699.98px) {
        width: 2500px;
    }
    @media (min-width: 1700px) {
        width: 3000px;
    }
}

.hero-sm {
    position: absolute;
    bottom: 0;
    margin: auto;
    z-index: -1;

    img {
        width: 100%;
        height: auto;
        max-width: inherit;
    }

    @include sm-x {
        display: none;
    }
}

//-------------------------------------------------------------
//  Partners
//-------------------------------------------------------------

.partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 0;
    box-shadow: 0 0 5px $black3;

    a {
        img {
            width: auto;

            @include x-sm {
                height: 30px;
            }
            @include sm {
                height: 45px;
            }
            @include md-x {
                height: 60px;
            }
        }
    }
}

//-------------------------------------------------------------
//  Sections
//-------------------------------------------------------------

section {
    .section__title {
        font-weight: 700;
        text-align: center;
        line-height: 1.2;

        @include x-sm {
            font-size: 1.6em;
        }
        @include sm {
            font-size: 2em;
        }
        @include md-x {
            font-size: 2.5em;
        }
    }

    .section__row {
        display: flex;

        @include x-sm {
            flex-direction: column;
        }
        @include sm-x {
            margin: 3em 0;
        }

        > div {
            @include sm-x {
                width: 50%;
            }
        }

        .section__img {
            text-align: center;

            img {
                width: 100%;
                height: auto;
            }
        }

        .section__copy {
            font-size: 1.1em;
            line-height: 1.4;
            font-weight: 300;

            @include x-md {
                padding: 2em;
            }
            @include md {
                padding: 0 3em;
            }
            @include lg-x {
                padding: 3em;
            }

            h2 {
                font-size: 1.6em;
                font-weight: 600;
                margin: 0 0 0.5em;
            }
        }
    }

    .section__row--reverse {
        @include sm-x {
            flex-direction: row-reverse;
        }
    }
}

.section {
    @include x-sm {
        padding: 2em 0;
    }
    @include sm {
        padding: 4em 0;
    }
    @include md {
        padding: 6em 0;
    }
    @include lg-x {
        padding: 8em 0;
    }
}

//-------------------------------------------------------------
//  Legacy
//-------------------------------------------------------------

.legacy {
    .section__title {
        margin: 0 0 1em;
    }

    .btn {
        width: fit-content;
    }
}

//-------------------------------------------------------------
//  Real You
//-------------------------------------------------------------

.real-you {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: black;

    @include x-sm {
        padding: 3em 1em;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    .section__title {
        margin: 0 0 0.8em;
        color: white;
    }

    .btn {
        box-shadow: 0 4px 6px $black3;
    }
}

.real-you__emoji {
    font-size: 4em;
    text-align: center;
    margin: 0 0 0.5em;
    letter-spacing: 2px;
    display: block;
}

.real-you__copy {
    font-size: 1.1em;
    line-height: 1.4;
    font-weight: 300;
    text-align: center;
    max-width: 600px;
    margin: auto;
    color: white;
}

.real-you-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
}

//-------------------------------------------------------------
//  Privacy
//-------------------------------------------------------------

.privacy {
    .section__title {
        margin: 0 0 1em;
    }

    .btn {
        width: fit-content;
    }
}

//-------------------------------------------------------------
//  Great Features
//-------------------------------------------------------------

.great-features {
    .container {
        background-color: rgb(77, 0, 146);
        position: relative;
        overflow: hidden;

        @include x-sm {
            padding: 4em 2em;
        }
        @include sm-x {
            padding: 5em;
            border-radius: 16px;
        }

        .section__title {
            margin: 0 0 0.8em;
            color: white;
        }

        .section__title,
        .great-features__copy,
        .btn {
            position: relative;
            z-index: 1;
        }
    }

    .btn {
        box-shadow: 0 4px 6px $black3;
    }
}

.great-features__copy {
    font-size: 1.1em;
    line-height: 1.4;
    font-weight: 300;
    text-align: center;
    max-width: 600px;
    margin: auto;
    position: relative;
    z-index: 1;
    color: white;
}

.great-features-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.25;
}

//-------------------------------------------------------------
//  CTA
//-------------------------------------------------------------

.cta__btn {
    width: fit-content;
    margin: 2em auto 0;
}
