@import '../global-variables.scss';

/*-------------------------------------------------------------
  Move entries modal
-------------------------------------------------------------*/

#move-entries-modal {
    .modal__body {
        padding: 0;

        @include sm-x {
            max-height: 400px;
        }
    }

    .list {
        margin: 0 !important;

        .list__item {
            padding: 12px 16px;
            cursor: pointer;
            transition: $trans2;

            &:hover {
                background: var(--primary1);
                transition: $trans1;
            }
        }

        > .active {
            .list__item {
                background: var(--primary-color);
                color: white;

                img {
                    filter: var(--filter-white);
                }

                .list__subtitle {
                    color: $white7;
                }

                .default-journal-tag {
                    box-shadow: 0 0 0 1px $white3;
                    color: $white8;
                }
            }
        }
    }
}

.move-entries-choice {
    display: flex;
    align-items: center;
    padding: 16px 16px 0;
    margin: 0;
    border-top: 1px solid $black1;
}
