@import '../global-variables.scss';

/*-------------------------------------------------------------
  Journals and tags
-------------------------------------------------------------*/

.journal-indicator-card {
    @extend .card-styles;
    display: flex;
    align-items: center;
    padding: 16px;
}

.journal-indicator-card > span {
    font-size: 2rem;
    padding-right: 0.625rem;
}

.journal-indicator-title {
    font-size: 1.375rem;
    font-weight: normal;
    color: #000000;
    margin-bottom: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btn-new-book-journal {
    min-width: max-content;

    .fa-plus-circle:before {
        color: var(--primary);
    }
}

.btn-back-journals {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10.75rem;
    height: 2.25rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
    color: #000000;
    font-weight: 500;
}

.btn-back-journals:hover {
    color: #000000;
}

.btn-back-journals > .icon-arrow-left::before {
    color: var(--primary-color);
}

.nav-tabs {
    border-bottom: 0.0625rem solid rgb(239, 239, 239);
    margin: 0;
    padding: 0;

    .nav-item {
        cursor: pointer;
        transition: $trans2;

        .nav-link {
            background: transparent;
            border-radius: none !important;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 16px;
            font-weight: 500;
            width: 100%;

            &.active {
                color: black;
            }
        }

        > i {
            font-size: 1rem;
            font-weight: 500;
        }

        .journal-badge {
            margin-left: 8px;
            color: white;
            font-weight: 500;
            width: auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 2px 3px;
            font-size: 10px;
            box-shadow: 0px 0px 0px 1px $black3;
            color: $black6;
            transition: $trans2;
        }

        &:hover {
            transition: $trans2;
            color: var(--primary-color);
            border-bottom: 0.1875rem solid var(--primary1);

            img {
                filter: var(--filter-primary);
                opacity: 1;
            }

            .journal-badge {
                color: var(--primary-color);
                box-shadow: 0 0 0 1px var(--primary-color);
                transition: $trans2;
            }
        }

        &.active {
            border-bottom: 0.1875rem solid black;

            .journal-badge {
                box-shadow: 0px 0px 0px 1px $black3;
                color: $black6;
            }
        }
    }
}

.tab-pane__actions {
    margin: 16px;

    .btn {
        width: 100%;
        border-radius: 4px;
    }
}

.nav-item.journal-post-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.1875rem solid transparent;
}

.nav-item.journal-post-nav > a {
    color: rgb(165, 165, 165);
    padding: 0;
}

.nav-item.journal-post-nav.active > a {
    color: black;
}

.nav-item.journal-post-nav.active > a > .journal-badge {
    background-color: black;
}

.nav-item.journal-post-nav:hover > a {
    color: black;
}

.nav-item.journal-post-nav:hover > a > .journal-badge {
    background-color: black;
}

/* zhang's code */
.read-more {
    color: grey;
    cursor: pointer;
}

.media-below {
    font-size: 0.9rem;
    color: #909090;
}

.journal-post-list {
    padding-left: 16px;
    padding-right: 16px;

    .list-icon {
        margin-right: 16px;
        width: 24px;
        height: 24px;
    }

    li {
        display: flex;
        align-items: center;
        color: black;
        width: 100%;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        padding: 12px 16px;
        margin: 4px 0;
        transition: $trans2;

        &:hover {
            color: var(--primary-color) !important;
            border-radius: 0.3125rem;
            background-color: var(--primary1);
            transition: $trans1;

            .list-icon {
                filter: var(--filter-primary);
                opacity: 1;
            }
        }
    }

    .list-status {
        background: transparent;
        border: none;
        outline: none;

        img {
            width: 16px;
            width: 16px;
        }
    }

    .dropdown-trigger {
        padding: 8px 0 8px 8px;
        width: 24px;
        height: 32px;
    }

    .material-dropdown-menu {
        position: relative;

        div:nth-of-type(1) {
            z-index: 0;
        }

        div:nth-of-type(2) {
            z-index: 1;
            transform: none !important;

            .MuiPaper-root {
                transform: none !important;
                box-shadow: $dropdown-shadow;
                position: relative;
                top: 32px;
                right: 130px;
                width: 150px !important;

                .MuiList-root {
                    padding: 10px;

                    li {
                        padding: 4px 6px !important;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.journal-post-list
    > li.active
    > .journal-post-info.journal-extra-info
    > .icon-shared::before,
.journal-post-list
    > li:hover
    > .journal-post-info.journal-extra-info
    > .icon-shared::before {
    color: var(--primary-color);
}

.journal-post-icon {
    font-size: 1.5rem;
    color: inherit;
    font-weight: 600;
    padding-right: 1.25rem;
}

.journal-post-item {
    display: inline;
    font-size: 1rem;
    color: black;
    width: 100%;
    overflow: hidden;
}

.journal-post-title {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.default-journal-tag {
    box-shadow: 0px 0px 0px 1px $black3;
    color: $black6;
    border-radius: 4px;
    padding: 2px 3px;
    display: inline-block;
    margin-left: 6px;
    font-size: 10px;
    position: relative;
    top: -1px;
}

.shared-journal-tag {
    padding-top: 5px;
    display: flex;
    align-items: center;
}

.shared-journal-tag > img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    max-width: inherit;
}

.journal-post-description {
    color: $black6;
    font-size: 0.8rem;
    font-weight: normal;
}

.journal-photo-attachment {
    border: 0.0625rem solid rgb(134, 134, 134);
    border-radius: 0.3125rem;
    font-size: 0.6875rem;
    font-weight: 500;
    color: black;
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    text-transform: uppercase;
}

.journal-post-info {
    font-size: 1.1rem;
    color: rgb(134, 134, 134);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
}

.journal-post-info > .dropdown {
    padding-right: 1rem;
}

.journal-post-list
    > li
    > .journal-post-info
    > .dropdown
    > .icon-shared::before {
    color: #707070;
}

.journal-post-list
    > li:hover
    > .journal-post-info
    > .dropdown
    > .icon-shared::before,
.journal-post-list
    > li:hover
    > .journal-post-info.journal-extra-info
    > span:first-child {
    color: var(--primary-color);
}

.journal-post-info > .fa-lock {
    color: inherit;
}

.edit-limit-word {
    margin: 0.5rem 0;
}

.journals-section-title {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    color: #212529;
    padding-left: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
