@import '../global-variables.scss';

/*-------------------------------------------------------------
  Social media picker
-------------------------------------------------------------*/

.empty-instagram {
    height: 29.0375rem;
}

.connected-instagram {
    height: 31.1rem;
}

.social-media-description {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 16px;
}
.social-media-uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 8.4375rem;
    padding-top: 6.25rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem rgb(142, 142, 142);
    margin-bottom: 32px;
}

.uploading-from {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
}

.social-upload {
    width: 3.2313rem;
    height: 2.8312rem;
}

.jrnl-upload {
    width: 3.0438rem;
    height: 2.9rem;
}

.uploading-text {
    font-size: 1.0625rem;
    font-weight: normal;
    line-height: 1.47;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}

.uploading-from > i {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
}

.jnrl-uploading-bar {
    border-radius: 1.25rem;
    background-color: rgba(24, 82, 246, 0.5);
    position: relative;
    width: 18.75rem;
    height: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-bar-gif {
    width: 18.75rem;
    height: auto;
}

/* .jnrl-uploading-bar > span {
  max-width: 100%;
  width: 15%;
  display: block;
  height: 100%;
  border-radius: 1.25rem;
  background-color: #1852f6;
  position: relative;
  overflow: hidden;
}} */

/* .selecting-instagram {
height: 37.975rem;
} */

.social-media-close {
    font-size: 2rem;
    cursor: pointer;
}

.social-profile {
    display: flex;
    align-items: baseline;
    padding: 0 16px;
    margin: 0 0 16px;

    .social-profile__username {
        font-size: 1rem;
        font-weight: 500;
    }

    .social-profile__change {
        font-size: 0.75rem;
        font-weight: normal;
        color: $black6;
        cursor: pointer;
        margin-left: 8px;

        &:hover {
            color: var(--primary-color);
        }
    }
}

.image-grid {
    @extend .grid;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    padding: 4px 0 12px;

    @include ss {
        grid-template-columns: repeat(2, 1fr);
    }
    @include xs {
        grid-template-columns: repeat(3, 1fr);
    }
    @include sm-x {
        grid-template-columns: repeat(5, 1fr);
    }

    .image-grid__item {
        border-radius: 4px;
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
        background: black;
        cursor: pointer;
        transition: $trans2;

        &:hover {
            transition: $trans1;
            box-shadow: 0 0 0 2px white, 0 2px 5px 3px $black4;
        }

        .image-grid__img {
            position: absolute;
            width: auto;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &.selected-img {
            .image-grid__img {
                opacity: 0.6;
            }
        }
    }
}

.social-media-photos-picker {
    padding-left: 0.875rem;
}

.social-media-photos-picker > div {
    padding-left: 0;
    padding-right: 0;
    max-width: 7rem;
    height: 7rem;
    margin-bottom: 0.3125rem;
    margin-right: 0.3125rem;
}

.social-media-selected-photos {
    margin-top: 1rem;
}

.social-media-selected-photo {
    width: 5.9375rem;
    height: 5.9375rem;
    border-radius: 0.3125rem;
}

.ig-photo:hover {
    cursor: pointer;
    opacity: 0.5;
}

.social-media-selected-img {
    max-width: 5.9375rem;
    margin-right: 0.5rem;
    padding-right: 0;
    padding-left: 0;
}

.social-media-connection {
    padding: 2em;

    .social-icon,
    p,
    button {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .social-icon {
        margin-bottom: 16px;
    }

    p {
        text-align: center;
        font-weight: normal;
        line-height: 1.5;
        margin: 0 auto;
        max-width: 450px;
    }

    button {
        margin: 16px auto 0;
    }
}

.social-media-actions {
    margin-top: 1.8687rem;
    margin-bottom: 1.875rem;
    width: 45.625rem;
    display: flex;
    align-items: center;
}

.social-media-actions > .btn-cancel {
    width: 6.4063rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: #fffdff;
    font-size: 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 0.375rem;
}

.social-media-actions > .btn-upload {
    width: 8.625rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    font-size: 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.opacity-disabled {
    opacity: 0.5;
}

.social-media-actions > .btn-upload > span {
    padding-right: 0.6875rem;
}

.social-media-actions > .btn-cancel:hover {
    color: #000;
}

.social-media-actions > .btn-upload:hover {
    color: #fff;
}
