@import '../global-variables.scss';

/*-------------------------------------------------------------
  Manage contact
-------------------------------------------------------------*/

.manage-contact {
    text-align: center;
    padding: 16px 0;

    .manage-contact__avatar {
        img {
            width: 120px;
            height: 120px;
            border-radius: 100px;
            margin-bottom: 16px;
        }
    }

    .manage-contact__title {
        font-size: 1.2em;
        font-weight: 600;
        line-height: 1.2;
        margin: 0 0 0.3em;
    }

    .manage-contact__subtitle {
        font-size: 0.9em;
        color: $black7;
    }
}
