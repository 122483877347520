@media screen and (min-width:850px) {
    .wrapper-1{
        max-width: 820px;
    }
}
@media screen and (min-width: 1140px){
    .wrapper{
        max-width: 1110px;
    }
}

@media  screen and (max-width: 1199px) {
    .seen-section .partner-item img{
        height: 60px;
        width: auto;
    }
}
@media  screen and (max-width: 991px) {
    .blue-section,
    .seen-section{
        padding: 60px 0
    }
}

@media  screen and (max-width: 767px) {
    .img-section .left-part .fs34{
        font-size: 25px;
    }
    .blue-section .fs21,
    .img-section .left-part .fs20 {
        font-size: 18px;
    }
    .img-section {
        padding: 60px 0;
    }
    .blue-section .fs45,
    .seen-section .fs45,
    .features-section .fs45{
        font-size: 35px;
    }
    .features-section .features-txt{
        line-height: 1.3;
    }
    .features-section .ractangle {
        margin-bottom: 40px;
    }
    .seen-section {
        padding: 60px 0;
    }
    .features-section {
        padding: 60px 0 30px;
    }
    .header .nav-item:not(:last-child) {
        margin-right: 0;
    }


}
@media  screen and (max-width: 575px) {
   /* .img-section {
        background:none;
    }*/
    .img-section .sign-up_form {
      max-width: none;
    }
    .img-section .left-part {
        padding: 30px 30px 0;
    }
    .img-section .sign-up-block {
         padding: 0;
    }
    .img-section .sign-up_form {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    .img-section .left-part .fs34 {
        font-size: 20px;
    }
    .img-section .left-part .fs20 {
        font-size: 16px;
    }
    .platform-block img {
      transform: translateY(0);
    }
    .platform-block {
        padding-top: 20px;
    }
    .features-section .fs17 {
        font-size:15px ;
    }
    .seen-section .partner-item img{
        height: 50px;
    }
    .features-section .features-box{
        max-width: 230px;
        width: 100%;
        margin: auto;
    }
}
