@import '../global-variables.scss';

/*-------------------------------------------------------------
  Import/export
-------------------------------------------------------------*/

.imports-section {
  padding-bottom: 16px;
  border-bottom: 1px solid $black1;
  margin-bottom: 24px;

  .import-steps {
    .import-steps__item {
      margin: 16px 0;

      .import-step-title {
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }
}

.exports-section {
  overflow-y: scroll;
  max-height: 500px;
  border: 1px solid $black3;
  border-radius: 4px;
  padding: 0 16px;

  .list {
    border-top: none;
    margin: 0 !important;

    .list__item:last-child {
      border: none;
    }

    .default-journal {
      padding: 3px 6px;
      border: 1px solid $black3;
      display: inline-block;
      border-radius: 4px;
      margin: 3px 0 5px;
    }
  }
}

/*
.imports-section {
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #e8e8e8;
}

.import-step-title {
  font-size: 0.9375rem;
  color: #000000;
  margin-bottom: 1rem;
}

.import-step-desc {
  font-size: 0.9375rem;
  font-weight: normal;
  color: #000000;
  margin-bottom: 1rem;
}

.btn-download-plugin {margin-bottom: 1.3125rem;}

.exports-section {
  padding-top: 1.875rem;
}

.export-journals-list {
  width: 570px;
  max-height: 260px;
  border-radius: 5px;
  border: 1px dashed #000000;
  overflow-y: scroll;
}

.export-journals-list {
  padding-left: 1.875rem;
  padding-top: 0.625rem;
  padding-right: 2rem;
  margin-bottom: 1.25rem !important;
  overflow-y: scroll;
}

.export-journals-list::-webkit-scrollbar {
  width: 0.3125rem;
  margin-right: 0.7188rem;
}

.export-journals-list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.25rem;
  margin: 0.625rem;
}

.export-journals-list::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 0.25rem;
}

.export-journals-list::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.75);
}

.export-journals-list > li {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.export-journal-title {
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0.375rem;
  display: block;
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.export-journal-description {
  font-size: 0.875rem;
  font-weight: normal;
  color: #868686;
  margin-bottom: 0;
}

.export-journals-list > li > span::before {
  color: #848484;
}

*/
