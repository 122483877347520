@import '../global-variables.scss';

/*-------------------------------------------------------------
  Social connect
-------------------------------------------------------------*/

.connected-account-section {
    .connected-account-content {
        display: flex;
        align-items: center;
        position: relative;
    }

    .connected-account-content .social-image {
        margin-right: 1.25rem;
        margin-left: 0.625rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .connected-account-content-text > h4 {
    }

    .connected-account-content-text > h4 > span {
        margin-left: 0.9375rem;
    }

    .auto-import-content-settings > label {
        font-size: 0.9375rem;
        font-weight: normal;
        width: auto;
    }
}

.available-accounts-section {
    .list__item {
        cursor: pointer;
    }
}
