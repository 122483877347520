@import '../global-variables.scss';

/*-------------------------------------------------------------
  Checkbox
-------------------------------------------------------------*/

.list--shipping-address,
.checkout-payment-info {
    border-top: none !important;
}

.add-new-item {
    width: fit-content;
    margin-top: 1em;

    .list__item {
        border-bottom: none !important;
        padding: 0 !important;
    }

    .list__img {
        display: none;
    }

    .list__title {
        margin: 0;
    }
}

.jrnl-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 0.3125rem;
    box-shadow: 0 0.8125rem 1.4375rem 0 rgba(0, 0, 0, 0.19);

    & > .label {
        padding: 5px;
        flex-grow: 1;
    }
}

.list__options {
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex: none;

    button {
        line-height: 0;
    }

    > * {
        margin-left: 8px;
    }

    > .material-dropdown-menu {
        margin-left: 4px;
        position: relative;

        div:last-child {
            top: 28px !important;
            right: 0;
            left: inherit !important;
            transform: none !important;
            z-index: 1;
            box-shadow: none;

            .MuiList-root {
                padding: 10px;
                border-radius: 4px;
                box-shadow: $dropdown-shadow;

                li {
                    padding: 4px 6px !important;
                    font-size: 14px;
                    transition: $trans1;
                    border-radius: 4px;

                    &:hover {
                        background-color: var(--primary1);
                        transition: $trans2;
                    }
                }
            }
        }
    }
}

.disable-behind {
    z-index: 1;
}
