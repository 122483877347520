@import '../global-variables.scss';

/*-------------------------------------------------------------
  Profile
-------------------------------------------------------------*/

.avatar-settings {
    display: flex;
    align-items: center;
    margin: 0 0 24px;

    .avatar-settings__img {
        width: 80px;
        height: 80px;
        border-radius: 100px;
    }

    .avatar-settings__btn {
        margin-left: 16px;
    }
}

.settings-inputs {
    display: flex;
    flex-direction: column;
}

.settings-input.name-input {
    width: auto !important;
}

.expiry-date-picker {
    width: 2rem !important;
    display: inline-block;
}

.close-account-button {
    margin-top: 20px;

    .btn-delete-account {
        background: none;
        color: #0008;
        border: none;
        font-size: 0.9em;

        &:hover {
            color: var(--red-color);
        }
    }
    .btn-warning {
        background: #ff9a04;
        float: right;
        color: #ffff;
    }
}
