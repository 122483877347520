@import '../global-variables.scss';

//-------------------------------------------------------------
//  Access
//-------------------------------------------------------------

.access {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
}

.access__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    flex: none;
    border-bottom: 1px solid $black1;
    margin-bottom: 2em;
    padding: 8px 16px 8px 0;
    box-sizing: border-box;
}

.access__logo {
    display: block;
}

.access__logo img {
    width: auto;
    height: 50px;
    display: block;
}

.access__link {
    text-decoration: none;
    color: $black6;
    transition: $trans1;
    display: flex;
    align-items: center;
}

.access__link:hover {
    transition: $trans1;
    color: var(--primary-color);
}

.access .btn {
    width: 100%;
}

.access__form {
    width: 100%;
    max-width: 320px;
    padding: 1em;
}

.access__title {
    font-size: 2em;
    font-weight: 700;
    margin: 0 0 1em;
}

.access__item {
    position: relative;
    padding: 0 0 20px;
}

.access__item label {
    display: block;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 8px;
    padding: 0px;
}

.access__item input {
    width: 100%;
    padding: 10px;
    font-size: 1.1em;
    box-sizing: border-box;
    border: 1px solid $black6;
    border-radius: 4px;
}

.access__item input:focus {
    border: 1px solid $black6;
}

.access__forgot {
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    color: $black6;
    float: right;
    margin-top: 8px;
}

.access__forgot:hover {
    color: var(--primary-color);
}

.access__error {
    color: var(--red-color);
    margin-top: 8px;
    font-size: 0.8em;
}

.access__btn {
    margin-top: 24px;
}

.access__or {
    display: block;
    text-align: center;
    color: $black6;
    padding: 8px 0;
    margin-top: 16px;
    position: relative;
}

.access__or div {
    position: relative;
    background: white;
    padding: 8px 0;
    z-index: 1;
    width: 20px;
    margin: auto;
}

.access__or span {
    position: absolute;
    height: 1px;
    width: 100%;
    background: $black1;
    display: block;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
}

.access__btn,
.btn-social {
    padding: 0.8rem 1rem !important;
    font-size: 1.1em !important;
}

.access__social .btn {
    margin: 16px 0;
}

.access__social .btn svg {
    margin-right: 8px;
    border-radius: 8px;
    width: 16px;
    height: 16px;
    padding: 6px;
    color: white;
}

.btn-social {
    background: white;
    box-shadow: 0 0 0 1px $black2, 0 2px 5px $black2;
}

.btn-social:hover {
    color: var(--primary-color);
}

.btn-social--facebook svg {
    background: #4267b2;
}

.btn-social--google svg {
    background: #4285f4;
}

.access__item input::placeholder {
    color: $black3;
}

.access__item input::-moz-placeholder {
    color: $black3;
}

.access__item input:-moz-placeholder {
    color: $black3;
}

.access__item input::-webkit-input-placeholder {
    color: $black3;
}

.access__item input:-ms-input-placeholder {
    color: $black3;
}

.access__subtitle {
    line-height: 1.4;
    margin: 0 0 2em;
    color: rgba(0, 0, 0, 0.8);
}

.access__main {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.access__return {
    display: block;
    margin: 2em auto 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    transition: all 0.1s ease-in-out;
}
