@import '../global-variables.scss';

/*-------------------------------------------------------------
  All about me
-------------------------------------------------------------*/

.sidenav-info {
    padding-top: 1em;
}

.categories-about-me {
    .container {
        @include x-sm {
            padding: 0;
        }
    }
}

.btn-back-timeline {
    width: 9.9375rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    box-shadow: -0.0688rem 0.2375rem 0.3125rem 0 rgba(0, 0, 0, 0.05);
    background-color: var(--primary-color);
    font-size: 0.9375rem;
    font-weight: 500;
    color: #fff;
}

.random-question sidenav-info {
    position: fixed;
    width: 100%;
    display: flex;
    top: 6.1875rem;
}

.help-nav {
    background-color: white;
    height: 3rem;
    margin-bottom: 2rem;
    position: absolute;
    top: 4.8rem;
    left: 0;
    z-index: 9999;
    width: 100vw;
}

.btn-wrapper {
    @media (min-width: 576px) and (max-width: 680.98px) {
        margin: 1em;
    }
    @media (min-width: 681px) {
        margin-bottom: 1em;
    }
}

.btn-answer-random {
    width: 100%;
    box-shadow: $shadow-tile;

    @include x-sm {
        border-radius: 0 !important;
    }
    @include sm-x {
        border-radius: 4px !important;
    }
}

.btn-random-question {
    max-width: 13.8125rem;
    width: 100%;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: #01b5cb;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.75rem;
    margin-right: 1.875rem;
}

.btn-random-question > span {
    padding-right: 0.5625rem;
}

.back-to-all-about-me-content {
    @extend .card-styles;
    display: flex;
    align-items: center;

    a {
        color: $black6;
        display: flex;
        align-items: center;
        transition: $trans1;
        font-size: 14px;
        padding: 12px;
        width: 100%;

        img {
            margin-right: 4px;
            transition: $trans1;
        }

        &:hover {
            color: var(--primary-color);
            transition: $trans2;

            img {
                filter: var(--filter-primary);
                transition: $trans2;
            }
        }
    }
}

.category-card {
    width: 100%;
    color: black;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @extend .card-styles;

    .category-card-content {
        display: flex;
        align-items: center;
    }

    .icon-arrow-right {
        font-size: 10px;
    }
}

.category-card > a > .icon-arrow-right::before {
    color: #1164fb;
}

.selected-category-card-img > img,
.category-card-content-img > img {
    margin-right: 22px;

    @include x-sm {
        width: 70px;
        height: 70px;
    }
    @include sm {
        width: 100px;
        height: 100px;
    }
    @include md-x {
        width: 120px;
        height: 120px;
    }
}

.selected-category-card,
.category-card {
    padding: 16px;
    display: flex;
    align-items: center;
    @extend .card-styles;

    @include x-sm {
        font-size: 90%;
    }

    .category-card-content-title {
        font-size: 1.4em;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .category-card-content-subtitle {
        @extend .small-gray;
    }

    .btn {
        margin-top: 10px;
    }
}

.all-about-me-banner-content > .banner-title {
    padding-top: 1.875rem;
}

.circle-icon {
    border-radius: 50%;
    border: 1px solid rgb(208, 208, 208);
    background-color: rgb(255, 253, 255);
    width: 2.625rem;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-about-me {
    background-color: rgb(230, 216, 56);
}

.bg-category-banner {
    background-color: #fff;
    height: auto;
    display: block;
    font-weight: bold;
}

.bg-category-banner img {
    margin-right: 10px;
}

.category-banner {
    margin-bottom: 0.625rem;
}

.question-list {
    margin-bottom: 0.625rem;
}

@media (min-width: 1200px) {
    .random-question .container {
        max-width: 100% !important;
    }
}

/* ANSWERING */

.questions-card {
    height: 100%;
    margin-bottom: 0;
}

.answering-card {
    height: auto;
    margin-bottom: 1.875rem;
}

.answering-card > .post-info {
    justify-content: start !important;
}

.answering-card-category {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.answering-card-category > span {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
}

.answering-card-category > p {
    margin-bottom: 0;
    color: rgb(13, 10, 10);
    font-size: 0.9375rem;
    font-weight: 500;
    font-size: 0.9375rem;
}

.answering-card-header {
    display: flex;
    align-items: center;
    margin: 0 16px;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    padding: 12px 14px 0 14px;
}

.answering-card-title {
    font-size: 1.375rem;
    font-weight: 500;
    color: #000000;
}

.answering-card-title > p {
    margin-bottom: 0;
}

.answer-question-section {
    align-items: flex-start;
    height: 8.75rem;
    border-bottom: 0.0625rem solid rgb(239, 239, 239);
}

.answer-question-section > textarea {
    font-size: 1.0625rem;
    font-weight: normal;
    line-height: 1.76;
}

.close-answer {
    font-size: 2rem;
    color: #05436c;
    cursor: pointer;
}

.pointer-cursor {
    cursor: pointer;
}

.question-card {
    padding: 16px;
    @extend .card-styles;

    .question-card-title {
        font-size: 1.2rem;
        line-height: 1.3;
        font-weight: 500;
        padding: 0 0 14px;
        margin: 0 0 16px;
        border-bottom: 1px solid $black1;
        width: 100%;
    }

    .question-card-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .question-card-state {
            @extend .small-gray;
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: fit-content;
            padding: 8px 12px;
            font-size: 13px !important;

            span {
                margin-left: 8px;
            }
        }

        .btn-all-answers {
            background-color: #ffffff;
            color: var(--primary-color);
        }

        .btn-answer {
            @extend .btn-gradient;
        }
    }
}

.question-category-img {
    widows: 2.1875rem;
    height: 2.1875rem;
}

.question-category-post {
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
    color: #000000;
    margin-bottom: 0.3125rem;
    padding-left: 0.9375rem;
}

.question-card-edit {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.question-card-completed {
    display: flex;
    justify-content: space-between;
    position: relative;

    .dropdown-container {
        position: relative;

        #questionDropdown,
        #questionDropdown:hover {
            color: #979797;
            padding-top: 0.625rem;
            cursor: pointer;
        }
    }
}

.question-dropdown-menu {
    width: 11.375rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding-left: 0;
    padding-right: 0;
}

.btn-answer-open {
    width: 8.5625rem !important;
    height: 2.125rem !important;
    border: solid 0.0625rem var(--primary-color);
    background-color: #ffffff;
    color: var(--primary-color);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
    font-size: 0.8125rem !important;
    line-height: 1;
}

.btn-answer-open:hover {
    color: var(--primary-color);
}
