@import '../global-variables.scss';

/*-------------------------------------------------------------
  Checkout modal
-------------------------------------------------------------*/

#checkoutBookModal {
    .modal-box {
        height: initial;
    }
}

.checkout-user-info,
.add-new-credit-card {
    padding: 1.5em;
    border-radius: 4px;
    box-shadow: $dropdown-shadow;

    .checkout__checkbox {
        margin-top: -1.5em;
        margin-bottom: 1em;

        .MuiButtonBase-root {
            margin-left: -10px;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: var(--primary-color);
        }

        .MuiIconButton-colorSecondary:hover {
            background: rgba(3 195 243 / 14%);
        }
    }
}

.payment-checkout-btn {
    margin-top: 1.5em;

    .btn {
        width: 100%;
    }
}

.checkout-payment-info {
    .form-group {
        margin: 0 !important;
    }
}
