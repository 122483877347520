.selector {
    width: 100%;
    outline: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0.125rem solid #8f8f8f;
    padding-bottom: 0.1875rem;
    font-family: 'Roboto';
    font-size: 1.0625rem;
    line-height: 1.5625rem;
    color: #000000;
    -webkit-appearance: none;
    background: url('../../images/chevron-down.svg') no-repeat right;
    background-position-x: 17.5rem;
    padding-right: 1.875rem;
}
