@import '../global-variables.scss';

/*-------------------------------------------------------------
  Sidebar calendar
-------------------------------------------------------------*/

.calendar {
    > .drawer__heading {
        margin-bottom: 24px !important;

        .btn-today {
            @extend .btn-outline;
            @extend .btn--xs;
            margin-right: 8px;
        }
        .clear_calendar_btn {
            margin-right: 0;
            margin-left: 1rem;
        }
    }
}

.simplecalendar {
    background: #fff;
    border-radius: 8px;
    border: solid #e7e7e7 1px;
    display: flex;
    flex-direction: column;
    flex-basis: 304px;
    width: 304px;
    padding: 20px;
    position: absolute;
    z-index: 200;
}

.calendar-month_header {
    display: flex;
    justify-content: space-around;
    font-family: 'Roboto';
    color: #595959;
    font-weight: 500;
    font-size: 16px;
}

.is-prev {
    background: white;
    width: 22px;
    height: 22px;
    outline: none;
    cursor: pointer;
    color: #1164fb;
    border: 0.0625rem solid #dadada;
    border-radius: 0.3125rem;
    padding: 0.2rem;
}

.is-next {
    background: white;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    width: 22px;
    height: 22px;
    outline: none;
    cursor: pointer;
    color: #1164fb;
    border: 0.0625rem solid #dadada;
    border-radius: 0.3125rem;
    padding: 0.2rem;
}

.calendar-days_of_week {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    display: flex;

    .calendar-days_of_week_day {
        flex-basis: 15%;
        padding: 8px 0;
    }
}

.calendar-week {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px 0;
    padding: 0 8px;

    .is-prev_month,
    .is-next_month {
        color: $black4;
    }

    .calendar-week-day {
        list-style-type: none;
        display: inline-block;
        text-align: center;
        padding: 8px 4px;
        font-size: 13px;
        line-height: 1.5;
        transition: $trans1;
        flex-basis: 14.2857%;
        max-width: 14.2857%;
        overflow: hidden;
        background: transparent;
        outline: none;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $black1;
        position: relative;

        &:hover {
            background: var(--primary1);
            color: var(--primary-color);
            transition: $trans2;
        }
    }

    .is-today {
        &:before {
            content: '\2022';
            position: absolute;
            bottom: 2px;
            width: 100%;
            height: 5px;
            font-size: 16px;
            line-height: 0;
            text-align: center;
        }
    }
}

.calendar-dropdown-menu {
    padding: 0 8px;

    .calendar-filters {
        border: none !important;
        margin: 0 0 4px !important;

        .nav-item {
            width: 50%;
            text-align: center;
            cursor: pointer;

            .nav-link {
                transition: $trans1;
                margin: 2px;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 600;
                box-shadow: inset 0 0 0 1px var(--primary-color);
                color: var(--primary-color);

                &:hover {
                    transition: $trans2;
                    background: var(--primary1);
                }

                &.active {
                    background: var(--primary-color);
                    color: white;
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            .calendar-list {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex-basis: 25%;
                    max-width: 25%;
                    list-style-type: none;
                    display: inline-block;
                    text-align: center;
                    padding: 8px 4px;
                    margin: 3px 0;
                    font-size: 12px;
                    line-height: 1.5;
                    transition: $trans1;
                    border-width: 0 0 1px;
                    border-style: solid;
                    border-color: $black1;
                    cursor: pointer;

                    &:hover {
                        color: var(--primary-color);
                        background: var(--primary1);
                    }

                    .active {
                        transition: $trans2;
                    }
                }

                .btn-decades {
                    background: transparent;
                    border: none;
                    outline: none;
                    width: 100%;
                }
            }

            .month-calendar-list {
                li {
                    text-transform: uppercase;
                }
            }

            .decades-calendar-list {
                overflow-y: scroll;
                max-height: 190px;
            }
        }
    }
}

.is-start_selection::before,
.is-end_selection::before {
    content: '';
}

.is-selected {
    background: var(--primary1) !important;
    border-radius: 0 !important;
}

.is-start_selection,
.is-end_selection {
    color: white !important;
}

.is-start_selection,
.is-today {
    background: var(--primary-color) !important;
    border-radius: 4px 0 0 4px !important;
    color: white;
}

.is-end_selection {
    background: var(--primary-color) !important;
    border-radius: 0 4px 4px 0 !important;
    color: white;
}

.is-end_selection.is-start_selection {
    border-radius: 4px !important;
    background: var(--primary-color) !important;
}

.is-selectable {
    cursor: pointer;
    position: relative;
}

.is-selectable:before {
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}

.calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px !important;

    li {
        .calendar-header__title {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;

            img {
                width: 8px;
                height: 8px;
                margin-left: 6px;
                transition: $trans1;
            }

            &:hover {
                img {
                    transition: $trans1;
                    filter: var(--filter-primary);
                }
            }
        }
    }

    .calendar-header__arrow {
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            transition: $trans2;

            &:hover {
                filter: var(--filter-primary);
                transition: $trans1;
            }
        }
    }

    .calendar-header__arrow--left {
        margin-left: 8px;
    }

    .calendar-header__arrow--right {
        margin-right: 8px;
    }
}

/*-------------------------------------------------------------
  Date picker calendar
-------------------------------------------------------------*/

.react-calendar {
    width: 100% !important;
    box-shadow: $dropdown-shadow;
    border: none;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    max-width: 280px;
    z-index: 1;

    .react-calendar__navigation {
        height: 34px;
        margin: 0;

        button {
            &[disabled] {
                background: transparent;
                color: $black2;
            }
        }

        .react-calendar__navigation__arrow {
            width: 24px;
            min-width: 24px;
            font-size: 22px;

            &:hover {
                background: transparent;
                color: var(--primary-color);
            }
        }

        .react-calendar__navigation__label {
            &:hover {
                background: transparent;
                color: var(--primary-color);
            }
        }
    }

    .react-calendar__viewContainer {
        .react-calendar__month-view {
            .react-calendar__month-view__weekdays {
                .react-calendar__month-view__weekdays__weekday {
                    font-size: 10px;
                }
            }
        }

        .react-calendar__tile {
            padding: 8px 4px;
            font-size: 13px;
            border-radius: 4px;
            transition: $trans1;

            &[disabled] {
                background: transparent;
                color: $black2;
            }

            &:hover {
                background: var(--primary1);
                color: var(--primary-color);
                transition: $trans2;
            }

            &.react-calendar__tile--now,
            &.react-calendar__tile--now {
                background: transparent !important;
                box-shadow: inset 0 0 0 1px var(--primary-color);
            }

            &.react-calendar__tile--active,
            &.react-calendar__tile--hasActive {
                background: var(--primary-color) !important;
                color: white;
                box-shadow: none !important;
            }
        }

        .react-calendar__month-view__days__day--weekend {
            color: black;
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            color: $black4;
        }

        .react-calendar__year-view__months__month {
            padding: 10px 4px;
            font-size: 13px;
        }
    }
}

.react-calendar__tile--now {
    background: var(--primary-color) !important;
}
