@import '../global-variables.scss';

/*-------------------------------------------------------------
  Billing
-------------------------------------------------------------*/

.settings-section {
    .container {
        padding: 0;
    }
}

.settings-card-info {
    width: 14.5rem;
}

.default-card-tag {
    padding: 0.2em 0.6em;
    border-radius: 0.3em;
    height: 19px;
    font-size: 0.7em;
    font-weight: 400;
    background-color: var(--primary-color);
    text-align: center;
    color: #ffffff;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    flex: none;
}

.card-subtitle-info {
    display: flex;
    line-height: 1.6 !important;
    align-items: center;
}

.your-cards-list > li > .icon-pencil {
    padding-left: 3.125rem;
}

.your-cards-list > li > .icon-trash,
.your-cards-list > li > .icon-pencil {
    font-size: 1rem !important;
    cursor: pointer;
}

.credit-card-modal-content {
    padding: 2.0938rem 3.125rem 2rem 3.15rem;
    margin-top: 12%;
    max-width: 23.375rem;
    border-radius: 0.3125rem;
    box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
    background-color: #fffdff;
}

.accepted-input-icon,
.rejected-input-icon {
    position: relative;
}

.accepted-input-icon::after {
    position: absolute;
    content: '\f058';
    color: var(--primary-color);
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    right: 0.3125rem;
    bottom: 1.5rem;
}

.rejected-input-icon::after {
    position: absolute;
    content: '\f06a';
    color: #fd1d1d;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    right: 0.3125rem;
    bottom: 1.5rem;
}

.settings-form > .settings-inputs > .settings-input {
    width: 16.5rem !important;
}

.settings-form > .form-group {
    margin-bottom: 0;
    color: #a1a0a1;
}

.form-group > .settings-label {
    padding-left: 0;
}

.settings-form > .form-group > .settings-input {
    border-bottom: 1px solid #e5e3e5;
}

.settings-form > .form-group > .settings-label {
    padding-left: 0;
}

.add-card-form > .settings-inputs > .settings-input {
    width: 16.5rem !important;
    border-bottom: 1px solid #e5e3e5;
}

.date-cvv-form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.expiry-date-form-group > select {
    width: 6.8312rem !important;
}

.expiry-date-form-group > .form-group-input {
    display: flex;
    justify-content: center;
    width: 90px;
    border-bottom: 1px solid #e5e3e5;
}

.expiry-date-form-group > .form-group-input > .expiry-date-picker {
    width: 50%;
}

.cvv-form-group {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5e3e5;
}

.cvv-form-group > .settings-input {
    width: 6.075rem !important;
}

.default-card-choice {
    display: flex;
    align-items: center;
}

.default-card-label {
    margin-left: 0.625rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0.5rem;
}

.form-card-actions {
    display: flex;
    align-items: center;
}

.form-card-actions > .btn-form-cancel {
    width: 5.875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem var(--primary-color);
}

.form-card-actions > .btn-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 5.875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #ee3232;
    color: #000000;
    background-color: #ffffff;
}

.form-card-actions > .btn-delete:hover {
    color: #000000;
}

.form-card-actions > .btn-delete > .icon-trash {
    padding-right: 0.625rem;
}
.btn-form-add-card {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 9.9375rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    line-height: 1;
    color: #ffffff;
}

.btn-form-add-card > span {
    font-size: 1.2rem;
    padding-right: 0.625rem;
}
