@import '../global-variables.scss';

/*-------------------------------------------------------------
  Timeline
-------------------------------------------------------------*/

body.app-bg {
    /* background-image: url("../images/timeline-images/timeline-bg.png");
background-repeat: no-repeat;
background-size: cover; */
    font-size: 100%;
    line-height: 1;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
    /* make scrollbar transparent */
}

div.full-view {
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.timeline-view-container {
    height: 100%;
    padding-top: 40px;
}

.timeline {
    height: 100%;
    margin-top: 0;
}

.bg-fill {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;

    .bg-fill__img {
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
    }
}

.img-bg.full-view {
    filter: brightness(70%);
}

.btn-go-up {
    position: fixed;
    width: 4.5rem;
    height: 4.5rem;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    bottom: 1.25rem;
    right: 20.625rem;
    border-radius: 50% !important;
    font-size: 5rem !important;
    cursor: pointer;
    z-index: 9999;
}

.second_sidebar {
    height: auto;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
    margin-top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second_sidebar_content {
    height: auto;
    margin-top: 11px;
    margin-bottom: 11px;
}

.second_sidebar_content_text {
    font-size: 22px;
}

.timeline-heading {
    background: white;
    @extend .card-styles;
    display: flex;
    align-items: center;
    padding: 12px 14px;

    .timeline-heading__icon {
        flex: none;
        margin-right: 10px;
        line-height: 1.2;
        width: 20px;
        height: 20px;
    }

    .timeline-heading__title {
        font-size: 16px;
        font-weight: 600;
    }

    .timeline-heading__btn {
        flex: none;
        @extend .btn--xs;
        margin-left: 8px;
    }
}

.no-more {
    margin: 20px auto;
    background: white;
    border-radius: 100px;
    width: fit-content;
    box-shadow: $shadow-tile;

    img {
        width: 50px;
        height: 50px;
    }
}
.caption-div {
    word-break: break-all;
}

.format_btn_active {
    .btn-icon {
        filter: var(--filter-primary);
        opacity: 1;
        transition: all 0.2s ease-in-out;
    }
}

/*-------------------------------------------------------------
  Timeline Single view
-------------------------------------------------------------*/

.page-container--single {
    .timeline-view-container {
        padding-top: 0;

        .container {
            padding: 0;
        }
    }

    .post {
        border-bottom: none;
    }

    .draftjs-content {
        max-height: none;
    }
}
