@import '../global-variables.scss';

/*-------------------------------------------------------------
  JRNL Guard
-------------------------------------------------------------*/

.info-block {
  margin: 0 0 24px;

  .info-block__title {
    font-size: 20px;
    color: var(--primary-color);
  }

  .info-block__list {
    li {
      margin: 16px 0;
      line-height: 1.4;
    }
  }
}
