@import '../global-variables.scss';

/*-------------------------------------------------------------
  Dropdowns
-------------------------------------------------------------*/

.dropdown-menu {
    right: 0;
    left: unset !important;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    box-shadow: $dropdown-shadow;
    border: none;
    min-width: 180px;

    /*
    &[x-placement^='top']::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 0.4375rem 0.5rem;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
    bottom: -0.8125rem;
    left: 5.125rem;
    border-bottom-color: var(--primary-color);
    transform: rotate(180deg);
    }
    */

    /*
    &[x-placement^='bottom']:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 0.4375rem 0.5rem;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
    top: -0.8125rem;
    left: 5.125rem;
    border-bottom-color: var(--primary-color);
    }
    */

    ul {
        margin-bottom: 0;

        > li {
            border-radius: 4px;
            transition: $trans1;
            border: none !important;
            padding: 4px 6px !important;
            font-size: 14px;
            line-height: 1.5;
            padding: 4px 6px;
            width: 100%;
            border-radius: 4px;
            cursor: pointer;
            transition: $trans1;

            &:hover {
                background: var(--primary1);
                transition: $trans2;
            }

            &:last-child {
                border: none;
            }

            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            button {
                background: transparent;
                border: none;
                outline: none;
            }

            a {
                color: #212529;
            }
        }
    }

    .selected-journal-fixed,
    .selected-tag-fixed {
        border-bottom: $border-line2;
        margin: 0 0 6px;
        padding: 0 0 8px;
        font-size: 13px;
        color: $black6;

        .fa {
            display: none;
        }
    }

    .dropdown-selected-list,
    .media-dropdown-list {
        li {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 1rem;
            font-weight: normal;
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;
            margin: 2px 0;
            transition: $trans1;

            .dropdown-selected-list__check {
                margin: 0 10px 0 0;
                border-radius: 100px;
                width: 16px;
                height: 16px;
                line-height: 14px;
                text-align: center;
                flex: none;
                box-shadow: inset 0 0 0 1px $black3;

                img {
                    width: 10px;
                    height: 10px;
                    opacity: 0.3;
                    margin: 0;
                }
            }

            .dropdown-selected-list__title {
                margin: 0;
                font-size: 14px;
            }

            &:hover {
                .dropdown-selected-list__icon {
                    filter: var(--filter-primary);
                }
            }

            &.active {
                .dropdown-selected-list__check {
                    box-shadow: inset 0 0 0 100px var(--primary-color);

                    img {
                        filter: var(--filter-white);
                        opacity: 1;
                    }
                }

                .dropdown-selected-list__icon {
                    filter: var(--filter-primary);
                }

                .dropdown-selected-list__title {
                    color: var(--primary-color);
                    font-weight: 600;
                }
            }
        }
    }

    .dropdown-selected-tags-list {
        &::-webkit-scrollbar {
            width: 0.3125rem;
            margin-right: 0.7188rem;
            height: 1.25rem !important;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0.25rem;
            margin: 0.625rem;
        }

        &::-webkit-scrollbar-thumb {
            background: #00416b;
            border-radius: 0.25rem;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(255, 255, 255, 0.75);
        }
    }
}

.dropdown-menu--detailed {
    > ul > li {
        padding: 8px 10px 10px !important;
    }
}

.dropdown-menu__title {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 0.1em;
}

.dropdown-menu__description {
    color: $black7;
    font-size: 13px;
    line-height: 1.3;
}

.dropdown-toggle--select {
    border: 1px solid $black4;
    padding: 8px 10px;
    border-radius: 4px;
    position: relative;

    img {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 12px;
        right: 8px;
        transition: $trans1;
    }

    &:hover {
        border-color: var(--primary-color);

        img {
            filter: var(--filter-primary);
            transition: $trans2;
        }
    }
}

.dropdown {
    .MuiPaper-elevation1 {
        border-radius: 4px;
        box-shadow: $dropdown-shadow;

        ul {
            padding: 10px;

            li {
                font-size: 14px;
                font-weight: 400;
                border-radius: 4px;
                transition: $trans1;
                padding: 4px 6px;
                min-height: inherit;

                &:hover {
                    background: var(--primary1);
                    transition: $trans2;
                }
            }
        }
    }
}

.MuiList-root {
    .MuiMenuItem-root {
        min-height: auto;
    }
}

.scrollable-dropdown-list {
    overflow: hidden;
    max-height: 14.6875rem;
    overflow-y: scroll;
}

.dropdown-toggle {
    transition: $trans1;

    &:hover {
        color: var(--primary-color);
        transition: $trans2;

        span:before {
            color: var(--primary-color);
            transition: $trans2;
        }
    }

    span {
        transition: $trans1;
    }
}

.dropdown-toggle::after {
    content: '';
    border: none !important;
    margin: 0;
}

.dropdown-journals-list > li {
    padding: 0.5rem;
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        img {
            filter: var(--filter-primary);
            transition: $trans2;
        }
    }
}

.dropdown-journals-list > li:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.dropdown-journals-list > li.active-item {
    color: var(--primary-color);

    img {
        filter: var(--filter-primary);
    }
}

.dropdown-tags-list > li {
    padding: 8px;
    margin: 2px 0;
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        img {
            filter: var(--filter-primary);
            transition: $trans2;
        }
    }
}

.dropdown-menu__divider {
    width: 100%;

    &:hover {
        background: none !important;
        cursor: default !important;
    }

    hr {
        border-top: 1px solid $black1;
        margin: 8px 0;
        width: 100%;
    }
}

.dropdown-menu--form {
    min-width: 280px;

    .form-group__item {
        padding-bottom: 0 !important;
    }

    @include x-lg {
        top: -230px;
    }
}

.dropdown-tags-list > li.active-item-list {
    border-radius: 0.3125rem;
    background-color: transparent;
    color: var(--primary);

    img {
        filter: var(--filter-primary);
    }
}

.dropdown-tags-list > li.active-item-list::after {
    content: '';
}

.dropdown-tags-list > li > span.remove {
    color: gray;
    font-family: FontAwesome;
    font-weight: bold;
}

.dropdown-tags-list > li:hover {
    color: var(--primary-color);
    cursor: pointer;
    background-color: var(--primary1);
    border-radius: 0.3125rem;
}

.dropdown-tags-list > li > span {
    margin-right: 8px;
}

.dropdown-tags-list > li > p {
    margin-bottom: 0;
    width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: inherit;
}

.dropdown .fa-ellipsis-v {
    color: rgba(0, 0, 0, 0.4);
    font-size: 20px;
}

/*-------------------------------------------------------------
  Notifications
-------------------------------------------------------------*/

.notification-options {
    display: flex;
    background: $black0;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 7px 10px;

    a,
    button {
        font-size: 12px;
        transition: $trans2;
        color: $black6;

        &:hover {
            color: var(--primary-color);
            transition: $trans2;
        }
    }
}

.dropdown-notifications-settings-list > li i {
    padding-right: 0.625rem;
    padding-left: 0;
}
.dropdown-notifications-settings-list > li i::before {
    color: #0365ff;
}
.notification-active-bullet-dropdown {
    position: absolute;
    background-color: var(--primary-color);
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 100px;
}

/*.dropdown-notifications::-webkit-scrollbar{*/
/*    width: 10px;*/
/*    height: 5px;*/
/*    border-radius: 8px;*/
/*    border: 2px solid #fff;*/
/*    background-color: rgba(0,0,0,.5)!important;*/
/*}*/

.dropdown-notifications::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.dropdown-notifications::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.dropdown-entry-list > li span {
    text-align: center;
    width: 2rem;
    padding-right: 0.9375rem;
}

.dropdown-entry-list > li > .icon-pencil::before {
    color: #1164fb;
}

.dropdown-entry-list > li {
    cursor: pointer;
}

.icon-closed-eye {
    font-size: 0.9rem;
}

.comments-dropdown-width {
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem);
    box-shadow: $dropdown-shadow;
    background-color: #ffffff;
    right: 0 !important;
    width: 150px;
    min-width: inherit;
}

/*-------------------------------------------------------------
  Settings menu
-------------------------------------------------------------*/

.dropdown-settings-menu {
    max-width: 17.875rem;
    width: 100%;
    border-radius: 0.625rem;
    background-color: #0064ff;
}

.dropdown-settings-options {
    padding-left: 0;
}

.dropdown-settings-options > li:first-child {
    margin-top: 1.25rem;
}

.dropdown-settings-options > li {
    color: #ffffff;
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-settings-options > li > i {
    width: 2rem;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
}

/*-------------------------------------------------------------
  Media dropdown menu
-------------------------------------------------------------*/

.dropdown-menu--media {
    min-width: 304px;

    @include x-lg {
        top: -276px !important;
    }

    li {
        line-height: 1.3;

        span {
            width: 30px;
            flex: none;
            line-height: inherit;
        }

        &.media-list-divider {
            border-top: 1px solid $black1;
            padding: 0;
            margin: 10px 0;
        }
    }
}

/*-------------------------------------------------------------
  Max height
-------------------------------------------------------------*/

.max-height--400 {
    max-height: 400px;
}
