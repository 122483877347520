@import '../global-variables.scss';

.dropdown-journals-list,
.dropdown-tags-list {
    max-height: 220px;
    @extend .scrollable;
}

/*-------------------------------------------------------------
  Journals
-------------------------------------------------------------*/

.post-journal,
.post-tags {
    display: flex;
    align-items: center;
}

.post-info {
    .post-tags {
        @include x-md {
            position: relative;
            top: 8px;
        }
    }
}

.post-info__date-journal {
    display: flex;
    font-size: 13px;

    @include x-md {
        flex-direction: column;

        > div:first-child {
            margin-bottom: 4px;
        }
    }

    .post-date {
        cursor: pointer;
        flex: none;
    }

    .post-journal {
        color: $black4;
        position: relative;

        .journal-used {
            @include x-xs {
                max-width: 170px;
            }

            @include xs {
                max-width: 200px;
            }

            @include sm-x {
                max-width: 270px;
            }
        }

        .dropdown {
            position: absolute;
            top: 18px;
            left: 12px;

            .journals-dropdown-menu {
                left: 0 !important;
                max-width: 280px;

                ul {
                    margin: 0;
                }
            }
        }
    }
}

.post-tags {
    justify-content: flex-end;
    font-size: 13px;
    align-items: center;

    .tags-used {
        line-height: 1.5;
        color: $black4;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include x-xs {
            max-width: 70px;
        }

        @include xs {
            max-width: 100px;
        }

        @include sm-x {
            max-width: 190px;
        }
    }

    .dropdown {
        margin-left: 0.5rem;
    }

    .tags-dropdown-menu {
        min-width: 190px;

        @include x-lg {
            bottom: 30px;
            top: inherit;
        }
        @include lg-x {
            top: 20px;
        }

        .form-input-btn {
            margin-top: 10px;

            .form-input-btn__input {
                border-width: 1px 0 1px 1px !important;
                border-style: solid;
                border-color: $black3;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                font-size: 14px;
            }
        }
    }
}

/*-------------------------------------------------------------
  Tags
-------------------------------------------------------------*/

.new-tag {
    color: rgb(183, 183, 183);
}

.new-tag > span {
    color: rgb(183, 183, 183);
}

.new-tag > input {
    background-color: transparent;
    width: 8.3rem !important;
}

.new-tag:hover {
    background-color: transparent !important;
}

.new-tag-active {
    color: var(--primary-color);
    background-color: rgb(240, 244, 244);
    border-radius: 0.3125rem;
}

.new-tag-active > span,
.new-tag-active > input {
    color: var(--primary-color);
}

.new-tag-active::after {
    content: '\f00c';
    color: var(--primary-color);
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}

/*-------------------------------------------------------------
  Editor
-------------------------------------------------------------*/

.post-title-section {
    display: flex;
    align-items: center;
    margin: 0 16px;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    padding: 12px 14px 0 14px;
}

.post-title-section > .post-title {
    font-weight: 500;
    font-size: 1.375rem;
    padding-left: 1rem;
    width: 100% !important;
}

.post-title-section .post-title::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
}

.post-title-section > .post-answer {
    font-size: 1.0625rem;
    color: rgb(0, 0, 0);
    width: 100%;
    margin-top: 0.625rem;
    line-height: 1.765;
}

/*-----------------------------
  Content area
-----------------------------*/

.MuiFormLabel-root.MuiInputLabel-root {
    padding-left: 16px;
}

figure {
    position: relative;
}

.draftjs-content {
    overflow-wrap: break-word;
    margin-top: 0.5rem;
    position: relative;
    margin: 0 2px 16px;
    padding: 0 14px;

    @media (max-height: 599.98px) {
        max-height: 240px;
    }

    @media (min-height: 600px) and (max-height: 799.98px) {
        max-height: 40vh;
    }

    @media (min-height: 700px) and (max-height: 899.98px) {
        max-height: 50vh;
    }

    @media (min-height: 900px) and (max-height: 1023.98px) {
        max-height: 60vh;
    }

    @media (min-height: 1024px) and (max-height: 1199.98px) {
        max-height: 65vh;
    }

    @media (min-height: 1200px) {
        max-height: 70vh;
    }

    .public-DraftEditor-content {
        > div {
            > div,
            ul,
            ol,
            blockquote,
            > figure {
                margin: 0 0 1rem;
                line-height: 1.5;
            }

            ul,
            ol {
                padding-left: 24px;
            }

            ul {
                list-style: disc;
            }

            ol {
                list-style: decimal;
            }

            blockquote {
                padding-left: 16px;
                border-left: 4px solid $black2;
            }

            figcaption {
                font-size: 0.8em;
                line-height: 1.3;
                color: $black6;
                margin: 2px 0 0;
            }
        }
    }

    //-----------------------------
    //	Images
    //-----------------------------

    .figure-action {
        position: absolute;
        justify-content: center;
        display: flex;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        .icon-pencil {
            color: var(--primary-color);

            &:before {
                color: var(--primary-color);
            }
        }
    }

    figure.editable-image-caption {
        box-shadow: 0 0 0 1px $black1, 0 2px 3px $black3;
        border-radius: 2px;
        min-width: 200px;
        margin: 0;
        padding: 2px;
        max-width: 100%;

        img.editable-image-caption {
            display: block;
            width: 100% !important;
        }

        .figure-action-btn {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black3;
            border: none;
            outline: none;
            position: absolute;
            bottom: 5px;
            transition: $trans1;
            padding: 0;
            box-shadow: none;

            span {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                filter: var(--filter-white);
                width: 16px;
                height: 16px;

                &:before {
                    display: none;
                }
            }

            &.btn-edit {
                right: 40px;

                &:hover {
                    background-color: var(--primary-color);
                }

                span {
                    background-image: url('/static/media/icon_edit.09f30ab8.svg');
                }
            }

            &.btn-trash {
                right: 5px;

                &:hover {
                    background-color: var(--red-color);
                }

                span {
                    background-image: url('/static/media/icon_delete.e5de3c0a.svg');
                }
            }

            &:hover {
                transition: $trans2;
            }
        }

        .figure-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: -12px;
            bottom: 45%;
            transform: none !important;

            i {
                &:before {
                    content: '' !important;
                    width: 24px;
                    height: 24px;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: block;
                    opacity: 0.3;
                    background-image: url('/static/media/icon_handle-vert.afeb742a.svg');
                }
            }
        }
    }

    .resizable-figure {
        overflow: hidden;
        resize: horizontal;
        min-width: 10.625rem;
    }

    .btn-resize-img {
        background-color: transparent !important;
        right: 0;
        bottom: 0;
    }
}

.draftjs-content p {
    padding: 0;
    margin: 0;
    line-height: 1.4;
}

.empty-body {
    height: auto !important;
    margin: 0 16px;
    padding: 16px 0 0;
    border-width: 0 2px 2px 2px !important;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 0 0 4px 4px;
    position: relative;
}

.icon-font-text:before,
.icon-facebook-text:before,
.icon-attachment-text:before,
.icon-map-marker-text:before,
.btn-link-attach .fa-link:before,
.post-tags .icon-tags,
.journal-used,
.post-info .date {
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-in-out;
}

.icon-font-text:hover:before,
.icon-facebook-text:hover:before,
.icon-attachment-text:hover:before,
.icon-map-marker-text:hover:before,
.btn-link-attach .fa-link:hover:before,
.post-tags .icon-tags:hover,
.journal-used:hover,
.post-info .date:hover,
.comments-used:hover {
    color: var(--primary-color);
    transition: all 0.2s ease-in-out;
}

/*-----------------------------
  Editor tools
-----------------------------*/

.draftJsToolbar__toolbar__dNtBH {
    border-width: 1px 0 0 !important;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 8px;
    margin-top: 4px;

    ul.post-editor > li {
        display: inline;
        color: $black4;
        position: relative;
        margin-right: 8px;
        line-height: 0;
        transition: $trans1;
        -webkit-appearance: none;
    }

    .word-count {
        text-align: right;
        font-size: 11px !important;
        line-height: 1;
        position: absolute !important;
        right: 3px;
        bottom: 16px;
        margin: 0;
        display: block;
    }
}

ul.post-editor {
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
}

ul.post-editor > li > div {
    left: -0.875rem;
}

ul.post-editor > li > .icon-font-text:hover::before,
ul.post-editor > li:hover {
    cursor: pointer;
    color: var(--primary);
    transition: $trans2;
}

.btn-font {
    padding-right: 0.5rem;
    color: rgb(182, 182, 182);
    font-size: 0.875rem;
    font-weight: normal;
}

.btn-font:hover {
    color: rgb(182, 182, 182);
}

/* zhang's change */
ul.post-editor > li.active {
    cursor: pointer;
    color: black;
}

.word-count,
.word-count:hover {
    display: none;
    color: $black4 !important;
    cursor: default !important;
}

.fa-caret-down {
    padding-left: 0.375rem;
}

.map-body {
    height: 10.1875rem;
}

.map-body > textarea {
    height: 7rem;
}

.map-img-small {
    width: 6rem;
    height: 6rem;
    position: relative;
    float: right;
}

.map-img-small > img {
    height: 100%;
}

.map-img-small::after {
    content: '\f041';
    color: var(--primary-color);
    font-size: 2rem;
    /* content: "\f058"; */
    position: absolute;
    font-family: FontAwesome;
    font-weight: normal;
    top: 1.0625rem;
    right: 2.25rem;
}

.entry-address {
    display: flex;
    align-items: center;
    transition: $trans1;
    cursor: pointer;
    padding: 8px 16px 0;
    margin-bottom: -2px;
    color: $black4;

    .entry-address__icon {
        transition: $trans1;
        margin-right: 4px;
        width: 12px;
        height: 12px;
    }

    .entry-address__title {
        line-height: 1.1;

        span {
            font-size: 12px;
        }
    }

    &:hover {
        transition: $trans2;
        color: var(--primary-color);

        .entry-address__icon {
            filter: var(--filter-primary);
            transition: $trans2;
            opacity: 1;
        }
    }
}

.align-right div {
    text-align: right;
}

.align-center div {
    text-align: center;
}

.align-left div {
    text-align: left;
}

.align-justify div {
    text-align: justify;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-justify {
    text-align: justify;
}

.purple-color::before,
.purple-color {
    color: #8228d2 !important;
}

.blue-color::before,
.blue-color {
    color: #1164fb !important;
}

.green-color::before,
.green-color {
    color: var(--primary-color) !important;
}

.gray-color {
    color: #d5d5d5 !important;
}

.btn-link-attach > span,
.btn-file-attach > span {
    /* height: 1.3125rem; */
    padding: 0;
    display: inline;
    color: var(--primary-color) !important;
    cursor: pointer;
}

.btn-link-attach > span:hover,
.btn-file-attach > span:hover {
    color: #1164fb !important;
}

.btn-link-attach > a {
    /* height: 1.3125rem; */
    padding: 0;
    display: inline;
    color: #1164fb !important;
    cursor: pointer;
    font-size: 1.2rem !important;
}

.btn-link-attach > a:hover {
    text-decoration: none !important;
}

/* Posted details */

.btn-fix-align {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}

.posted-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9375rem;
    border-bottom: 0.0625rem solid rgb(239, 239, 239);
    height: 2.0625rem;
    padding-left: 1rem !important;
}

.post-draft {
    display: flex;
    align-items: center;
}

.post-draft > i {
    padding-right: 0.625rem;
}

.post-draft-text {
    font-size: 1rem;
    font-family: 'Roboto';
    color: #1164fb;
    margin-bottom: 0;
}

.post-shared-entry {
    display: flex;
    align-items: center;
}

.post-shared-entry > .shared-entry {
    margin-left: 0.5rem;
    width: 6rem;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.9375rem;
    font-weight: normal;
    color: var(--primary-color);
    margin-bottom: 0;
}

.MuiInputLabel-root {
    position: absolute;
    top: 20px;
    z-index: 0;
    color: rgba(0, 0, 0, 0.4) !important;
    font-weight: 300 !important;
}

/*-------------------------------------------------------------
  Links
-------------------------------------------------------------*/

.link-attached.not-clicked,
.link-attached {
    font-size: 1.0625rem;
    font-weight: normal;
    text-align: left;
    color: #1852f5;
    cursor: pointer;
}

.link-attached.clicked,
.link-attached:hover {
    color: var(--primary-color);
}

.link-attached-list {
    margin-top: 0.75rem;
}

.bullet-link {
    position: relative;
    padding-left: 0.9375rem;
}

.bullet-link::before {
    position: absolute;
    content: '\2022';
    font-family: FontAwesome;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 1.5rem;
    left: 0;
    line-height: 0.6;
}

.link-attached-ordered-list {
    margin-left: 1rem;
}

.link-attached-ordered-list > li {
    font-size: 1.0625rem;
    font-weight: normal;
    color: #000000;
    padding-bottom: 0.625rem;
    margin-left: 1rem;
    counter-increment: list;
    list-style-type: none;
    position: relative;
}

.link-attached-ordered-list > li::before {
    color: var(--primary-color);
    content: counter(list) '.';
    left: -2rem;
    position: absolute;
    text-align: right;
    width: 1.625rem;
    font-size: 0.8125rem;
    font-weight: normal;
    bottom: 0.7rem;
}

.link-attached-list > li {
    font-size: 1.0625rem;
    font-weight: normal;
    color: #000000;
    padding-bottom: 0.625rem;
}

.actions-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-clear {
        .btn-icon {
            @include x-sm {
                margin-right: 0;
            }
        }

        .btn-text-shared {
            @include x-sm {
                display: none;
            }

            @include sm {
                max-width: 180px;
            }

            @include md-x {
                max-width: 260px;
            }
        }

        span {
            @include x-sm {
                display: none;
            }
        }

        .additional-shares {
            @include x-sm {
                display: none;
            }
        }
    }
}

.saving-text {
    margin-bottom: 0;
    font-size: 0.9375rem;
    color: rgb(0, 0, 0);
    display: flex;
    align-self: center;

    img {
        width: 15px;
        height: 15px;
        margin: 0 6px 0 0;
        filter: var(--filter-green);
    }
}

.saving-gif {
    background-image: url('../../images/timeline-images/saving-loader.svg');
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    display: inline;
}

/*-------------------------------------------------------------
  Post actions
-------------------------------------------------------------*/

.post-actions {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 4.0625rem;

    .btn-private {
        margin-right: 0.5rem;
    }
}

.social-connections {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    > li {
        display: flex;
        margin-right: 16px;
        cursor: pointer;
        position: relative;
    }
}

.sharing-dropdown-menu,
.comment-dropdown-menu {
    width: 16.3125rem;
    padding: 0;
}

.sharing-dropdown-menu {
    right: 14px !important;
    transform: none !important;

    @include x-lg {
        top: -200px !important;
    }
    @include lg-x {
        top: 33px !important;
    }
}

.dropdown-sharing-list,
.dropdown-comment-list {
    margin: 0;
    padding: 10px;

    li {
        width: 100%;

        &:hover {
            background-color: var(--primary1);
            cursor: pointer;
        }

        &:last-child {
            border: none;
        }

        .sharing-point-header,
        .comment-point-header {
            display: flex;
            align-items: center;
            padding: 6px 6px 0;

            > span {
                font-size: 0.8rem;
                width: 20px;
                text-align: center;
                margin-right: 8px;

                &:before {
                    color: var(--primary-color);
                }
            }

            .comment-point-title {
                font-size: 0.9375rem;
                font-weight: normal;
                color: #000;
                line-height: 1;
                margin-bottom: 0;
            }
        }

        .sharing-point-text,
        .comment-point-text {
            font-size: 0.8125rem;
            font-weight: 300;
            margin-bottom: 0;
            line-height: 1.3;
            color: $black7;
        }
    }
}

.dropdown-sharing-list {
    li {
        &:hover {
            img {
                filter: var(--filter-primary);
                opacity: 1;
            }
        }

        .sharing-point-header {
            padding: 6px 6px 0;
        }

        .sharing-point-text {
            padding: 6px 8px 8px 30px;
        }

        &.active-comment-item,
        &.active-comment-item:hover {
            background: var(--primary-color) !important;
            cursor: default;

            .comment-point-title,
            .comment-point-text,
            span:before {
                color: white !important;
            }
        }
    }
}

.dropdown-comment-list {
    > li {
        .comment-point-header {
            > img {
                width: 24px;
                height: 24px;
            }
        }

        .comment-point-text {
            padding: 1px 8px 8px 39px;
        }

        &.active-comment-item,
        &.active-comment-item:hover {
            cursor: default;
        }
    }

    .active-comment-item {
        box-shadow: 0 0 0 2px var(--primary-color);
    }
}

.sharing-point-title {
    line-height: 1;
    font-size: 0.9375rem;
    font-weight: normal;
    color: #000000;
    margin-bottom: 0;
    font-weight: 500;
}

.btn-sharing-setting {
    width: auto;
    font-size: 0.875rem !important;
    font-weight: normal;
    color: #000000;
    margin-left: 2.375rem;
    border-bottom: 1px solid #000000;
    line-height: 1;
    border-radius: 0 !important;
    padding: 0;
    margin-bottom: 0.9375rem;
}

.btn-sharing-setting:hover {
    color: #000000;
}

.post-body {
    word-break: break-word;
    text-align: left;
    text-justify: inter-word;
}

.btn-reply-comment {
    padding: 0;
    padding-right: 10px;
}

.btn-cancel {
    padding: 0;
}
