@import '../global-variables.scss';

/*-------------------------------------------------------------
  Buttons
-------------------------------------------------------------*/

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1;
    padding: 0.5rem 1rem;
    font-weight: 500;
    color: #000000;
    border-radius: 100px;
    border: none;
    outline: none;
    transition: $trans1;

    &:hover {
        filter: var(--hover);
        transition: $trans1;
    }

    &.disabled,
    &:disabled,
    &.disabled:hover,
    &:disabled:hover,
    &.disabled:active,
    &:disabled:active {
        opacity: 0.3;
        filter: grayscale(1);
        cursor: default;
        user-select: none;
    }

    object {
        width: 13px;
        height: 13px;

        &.left {
            margin-right: 8px;
        }

        &.right {
            margin-left: 8px;
        }
    }

    img {
        width: 12px;
        height: 12px;

        &.left {
            margin-right: 8px;
        }

        &.right {
            margin-left: 8px;
        }
    }

    span {
        transition: $trans1;
    }
}

.btn--sq {
    border-radius: 4px;
}

button {
    -webkit-appearance: none;
}

/* Button sizes ---------------------------------------*/

.btn--xs {
    font-size: 0.7em;
    padding: 0.3rem 0.6rem;

    img {
        width: 8px;
        height: 8px;
    }
}

.btn--sm {
    font-size: 0.85em;
    padding: 0.4rem 0.8rem;

    img {
        width: 10px;
        height: 10px;
    }
}

.btn--rg {
    font-size: 1em;
    padding: 0.5rem 1rem;

    object {
        width: 16px;
        height: 16px;
    }

    img {
        width: 12px;
        height: 12px;
    }
}

.btn--md {
    font-size: 1.2em;
    padding: 0.6rem 1.2rem;

    img {
        width: 15px;
        height: 15px;
    }
}

.btn--lg {
    font-size: 1.4em;
    padding: 0.7rem 1.4rem;

    img {
        width: 18px;
        height: 18px;
    }
}

.btn--xl {
    font-size: 1.8em;
    padding: 0.8rem 1.8rem;

    img {
        width: 20px;
        height: 20px;
    }
}

/* Icon Buttons ---------------------------------------*/

.btn-icon {
    background: transparent;
    outline: none;
    border: none;
    filter: var(--filter-black);
    cursor: pointer;
    transition: $trans1;
    line-height: 0;
    @extend .btn-icon--rg;

    &:hover {
        filter: var(--filter-primary);
        opacity: 1;
        transition: $trans2;
    }
}

.btn-xp {
    filter: var(--filter-primary) !important;
    opacity: 1 !important;
    transition: $trans2 !important;
}

.icon-static {
    background: transparent;
    outline: none;
    border: none;
    filter: var(--filter-black);
    @extend .btn-icon--rg;
}

.btn-icon--3 {
    opacity: 0.3;
}

.btn-icon--4 {
    opacity: 0.4;
}

.btn-icon--5 {
    opacity: 0.5;
}

.btn-icon--6 {
    opacity: 0.6;
}

.btn-icon--rg,
.btn-icon--rg img {
    width: 16px;
    height: 16px;
}

.btn-icon--sm,
.btn-icon--sm img {
    width: 12px;
    height: 12px;
}

.btn-icon--md,
.btn-icon--md img {
    width: 20px;
    height: 20px;
}

.btn-icon--lg,
.btn-icon--lg img {
    width: 24px;
    height: 24px;
}

/* Close button ---------------------------------------*/

.btn-close {
    opacity: 0.4;
    background: transparent;
    border: none;
    outline: none;
    transition: $trans1;

    img {
        width: 20px;
        height: 20px;
    }

    &:hover {
        opacity: 1;
        transition: $trans2;
    }
}

.btn-close--light {
    filter: var(--filter-black);

    &:hover {
        filter: var(--filter-primary);
    }
}

.btn-close--dark {
    filter: var(--filter-white);
}

/* Add button ---------------------------------------*/

.btn-add {
    @extend .btn;
    @extend .btn-outline;
    padding: 0;
    height: 20px;
    width: 20px;
}

/* Default buttons ---------------------------------------*/

.btn-gradient {
    @extend .brand-gradient;
    color: white;

    &:hover {
        color: white;
    }

    &:focus {
        box-shadow: inherit;
        color: white;
    }

    &:active {
        filter: var(--active);
    }

    img {
        filter: var(--filter-white);
    }
}

.btn-outline {
    box-shadow: inset 0 0 0 1px var(--primary-color);
    color: var(--primary-color);
    background-color: transparent;

    img {
        filter: var(--filter-primary);
    }

    &:hover {
        filter: brightness(1.5);
        color: var(--primary-color);
    }
}

.btn-outline--white {
    box-shadow: inset 0 0 0 1px $white6;
    color: white;
    background-color: transparent;

    img {
        filter: var(--filter-white);
    }

    &:hover {
        box-shadow: inset 0 0 0 1px white;
        color: white;
    }
}

.btn-delete--white {
    background-color: white;
    color: black;

    img {
        filter: var(--filter-black);
    }

    &:hover {
        filter: brightness(1.5);
        color: black;
    }
}

.btn-clear {
    &:hover {
        .btn-icon,
        .caret {
            filter: var(--filter-primary);
        }

        img {
            transition: $trans2;
        }
    }

    .comment-icon {
        width: 18px;
        height: 18px;
    }

    img {
        transition: $trans1;
    }

    .btn-icon {
        width: 14px;
        height: 14px;
    }

    .caret {
        width: 8px;
        height: 8px;
    }
}

.btn-white {
    background: white;
    color: var(--delta);

    &:active {
        filter: var(--active);
    }

    &:hover {
        filter: var(--hover);
        color: var(--primary-color);
    }
}

/* Social post ---------------------------------------*/

.btn-social-post {
    width: 8.7125rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem var(--primary-color);
    background-color: #fffdff;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #000000;
    margin-right: 0.625rem;

    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-social-post:hover {
    color: #000000;
}

/* Entry ---------------------------------------*/

.btn-entry {
    width: 8.6875rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    font-size: 0.9375rem;
    font-weight: 500;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-journal {
    background-color: rgb(1, 181, 203);
}

.btn-book {
    background-color: rgb(0, 65, 107);
}

.btn-publish,
.btn-download-plugin,
.btn-add-journals,
.btn-add-tags {
    @extend .btn-gradient;
}

.btn-publish,
.btn-download-plugin,
.btn-request-data {
    span {
        padding-right: 8px;
    }
}

.btn-upgrade-pro {
    @extend .btn-gradient;

    span {
        padding-right: 8px;
    }
}

.btn-change-wallpaper,
.btn-remove-wallpaper,
.btn-change-avatar,
.btn-new-book-journal {
    @extend .btn-outline;

    span {
        padding-right: 8px;
    }
}

.change-avatar-icon::before {
    color: var(--primary-color) !important;
}

.btn-add-contact {
    @extend .btn-gradient;
    border: none;
    border-radius: 100px;
}

.btn-add-contact {
    position: absolute;
    right: 2.1875rem;
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
}

/* Help button ---------------------------------------*/

.btn-help {
    border-radius: 0.3125rem;
    background-color: rgb(255, 253, 255);
    box-shadow: -0.07353rem 0.2563rem 0.3333rem 0 rgba(0, 0, 0, 0.05);
    color: black;
    font-weight: 600;
}

.btn-help:hover {
    color: black;
}

.btn-help > span {
    color: var(--primary-color);
}

.btn-delete {
    background-color: var(--red-color);
    color: white;

    &:hover {
        filter: brightness(1.5);
        color: white;
    }
}

.btn-delete--white {
    background-color: white;
    color: var(--red-color);

    &:hover {
        filter: brightness(1.5);
        color: var(--red-color);
    }
}

/* Alerts button ---------------------------------------*/

.btn-alerts {
    font-size: 1.5rem !important;
}

.btn-alerts.btn-active-alerts {
    border: none;
}

.btn-active-alerts .fa-bell,
.btn-alerts:hover .fa-bell,
.btn-alerts:focus .fa-bell {
    color: white;
}

.btn-alerts .badge {
    background: var(--gamma);
    position: absolute;
    border-radius: 50%;
    top: 4px;
    right: -1px;
    font-size: 12px;
    line-height: 1;
}

/* Map search ---------------------------------------*/

.btn-map-search {
    width: 1.1875rem;
    height: 1.1875rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b6b6b6;
    padding-right: 0.625rem;
    font-size: 1.2rem;
}

.btn-map-search:hover {
    color: #000000;
}

/* Select user ---------------------------------------*/

.btn-select-user {
    width: 20.6812rem;
    height: 3.0625rem;
    border-radius: 0.3125rem;
    background-color: var(--primary-color);
    font-size: 1.0625rem;
    font-weight: 500;
    color: #ffffff;
}

.btn-select-user > span {
    padding-right: 0.625rem;
}

.btn-select-user:hover {
    color: #ffffff;
}

/* Alerts ---------------------------------------*/

.btn-alerts {
    font-size: 1.5rem !important;
    color: var(--primary-color);
    width: 3.125rem;
    height: 3.125rem;
    margin-right: 1rem;
}

.btn-alerts:hover {
    color: var(--primary-color);
    border-color: transparent;
}

.btn-alerts:focus {
    color: var(--primary-color);
    border-color: transparent;
}

.btn-alerts.btn-active-alerts {
    color: #ffffff;
    border-color: transparent;
    position: relative;
}

.btn-active-alerts .fa-bell,
.btn-alerts:hover .fa-bell,
.btn-alerts:focus .fa-bell {
    color: #ffffff;
}

/* Add settings ---------------------------------------*/

.btn-add-settings {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50% !important;
    background-color: var(--primary-color);
    color: #ffffff;
    margin-right: 1rem;
    font-size: 1.5rem !important;
}

.btn-add-settings:hover {
    color: #ffffff;
}

/* Extras ---------------------------------------*/

.btn-book {
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14.2813rem;
    height: 2.5rem;
    border-radius: 0.3125rem;

    background-color: #00416b;
}

.btn-publish > span {
    padding-right: 0.5rem;
}

.btn-private-shared {
    border: 1px solid $black4;
    background: transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 6px 4px 10px;
    min-height: 34px;
    justify-content: flex-start;

    .left {
        margin-right: 8px;
    }

    .truncate {
        width: 50%;
        flex: auto;
    }
}

// .btn-private.dropdown-toggle {
//   color: black;
//   font-weight: 600;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 35px;
//   padding: 0 8px;

//   &:hover {color: var(--primary-color);}

//   .icon-shared {padding-top: 3px;}

//   .fa-caret-down {
//     padding-right: 0rem !important;
//     color: #3e3e3e;
//   }

//   span {
//     padding-right: 0.5rem;
//     line-height: 1;
//   }

//   .image-avatars {
//     flex: none;

//     @include x-sm {display: none;}
//   }

//   .btn-text-shared {
//     width: 100%;

//     @include sm-lg {max-width: 180px;}
//     @include lg-x {max-width: 220px;}

//     span {

//       @include x-sm {display: none;}
//     }
//   }
// }

.btn-wide {
    width: 100%;
}

.btn-help {
    border-radius: 0.3125rem;
    background-color: rgb(255, 253, 255);
    box-shadow: -0.07353rem 0.2563rem 0.3333rem 0 rgba(0, 0, 0, 0.05);
    color: black;
    font-size: 0.9375rem !important;
    font-weight: 500;
    width: 8.8125rem;
    height: 2.1875rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1 !important;
}

.btn-help:hover {
    color: black;
}

.btn-help > span {
    color: var(--primary-color);
}

.btn-random-question {
    max-width: 13.8125rem;
    width: 100%;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: #01b5cb;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 1.875rem;
}

.btn-random-question:hover {
    color: #ffffff;

    color: #ffffff;
}

.btn-random-question > span {
    padding-right: 0.5625rem;
}

.btn-update {
    font-size: 0.9375rem;
    color: #ffffff;
    background-color: var(--primary-color);
}

.btn-update:hover {
    color: #ffffff;
}

.btn-update > .fa-check-circle {
    padding-right: 0.625rem;
}

.btn-delete-journal {
    color: red;
}

.btn-delete-journal:hover {
    color: red;
}

.btn-expand {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: #ffffff;
    padding: 0;
    color: #a5a5a5;
    font-size: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-expand:hover {
    color: #a5a5a5;
}

.btn-collapse {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #dadada;
    background-color: var(--primary-color);
    padding: 0;
    color: #dadada;
    font-size: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-search {
    width: 3.125rem;
    height: 3.125rem;
    background-color: transparent;
    padding: 0;
    color: #ffffff;
    font-size: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-google-drive {
    width: 8.625rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: #1fa463;
    font-size: 0.9375rem;
    font-weight: 500;
    color: white;
    margin-right: 1.375rem;
}

.btn-dropbox {
    width: 6.75rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background-color: #007ee5;
    font-size: 0.9375rem;
    font-weight: 500;
    color: white;
}

.btn-primary {
    width: 100%;
    height: 1.875rem;
    border-radius: 0.3125rem;
    background-color: #0365ff;
    color: #ffffff;
    padding: 0 !important;
}

.btn-primary-borders {
    max-width: 7.5rem;
    width: 100%;
    height: 1.875rem;
    border-radius: 0.3125rem;
    border: solid 0.0625rem #0365ff;
    font-size: 0.875rem;
    color: #1852f5;
    padding: 0 !important;
}

.btn-primary-borders:hover {
    color: #1852f5;
}

.btn-modal-primary {
    max-width: 9.375rem;
    width: 100%;
    height: 2.1875rem;
    background: #1854f5;
    border-radius: 3.125rem !important;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    font-weight: normal;
    font-family: 'Roboto';
    letter-spacing: 0px;
    color: #ffffff;
}

.btn-modal-outline-primary {
    max-width: 9.375rem;
    width: 100%;
    height: 2.1875rem;
    border: 0.0625rem solid #1854f5 !important;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    font-weight: normal;
    font-family: 'Roboto';
    letter-spacing: 0rem;
    color: #1854f5 !important;
    border-radius: 3.125rem !important;
}

/* JRNL LINK MODALS */
.btn-primary-circled {
    max-width: 15.125rem;
    width: 100%;
    height: 2.6875rem;
    background: #1164fb;
    border-radius: 3.125rem !important;
    color: #ffffff;
}

.btn-primary-circled .icon-add-circle-filled {
    padding-right: 0.625rem;
}

.btn-primary-circled .icon-add-circle-filled::before {
    color: #ffffff;
}
