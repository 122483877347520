@import '../global-variables.scss';

/*-------------------------------------------------------------
  Add/edit/delete
-------------------------------------------------------------*/

.settings-select > select {
    width: 12rem !important;
    padding-bottom: 0.3125rem;
    /* padding-left: 0.625rem; */
    outline: none;
    border: none;
    border-bottom: 0.125rem solid #d5d5d5;
    font-size: 1.125rem;
    font-weight: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'  >  <path d='M7 10l5 5 5-5z'/  >  <path d='M0 0h24v24H0z' fill='none'/  >  </svg  >  ");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0rem;
    margin-right: 1.25rem;
    position: relative;
}

.custom-message-input > .settings-input {
    width: 100% !important;
}

.time-selection {
    margin-left: 1.875rem !important;
}
