.introjs-tooltip {
    min-width: 400px;
    max-width: 400px;
}

.introjs-button {
    border-radius: 100px;
    text-shadow: none;
}

.introjs-prevbutton {
    background: #ffffff !important;
    border: 1px solid #b4b4b4;
    box-sizing: border-box;
    color: #b4b4b4;
}

.introjs-nextbutton {
    background: linear-gradient(
        34.36deg,
        #8228d2 -12.18%,
        #007bff 51.54%,
        #51a5ff 84.92%
    );
    color: #ffffff !important;
}
