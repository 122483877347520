@import '../global-variables.scss';

/*-------------------------------------------------------------
  Security
-------------------------------------------------------------*/

.settings-password-settings {
  padding: 0 0 24px;
  margin: 0 0 24px;
  border-bottom: 1px solid $black1;
}

.error {
  padding-top: 1rem;
  font-size: 0.9rem;
  color: red;
  opacity: 0.7;
}

.btn-change-pass {
  @extend .btn-outline;

  .icon-pencil {
    padding-right: 8px;
  }
}

.btn-log-out-device {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #ffffff;
  width: 14.4375rem;
  height: 2.1875rem;
  border-radius: 0.3125rem;
  background-color: var(--primary-color);
}

.btn-log-out-device > span {
  padding-right: 0.625rem;
}
