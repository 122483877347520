@import '../global-variables.scss';

/*-------------------------------------------------------------
  Media modal
-------------------------------------------------------------*/

.media-modal {
    .media-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 16px;

        .media-modal__title {
            color: black;
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0;
            padding-left: 0 !important;
        }
    }
}

.picker-dialog {
    z-index: 100001 !important; // because of google drive picker , google drive picker z-index: 1001
}

.social-media-modal {
    /* display: none;  Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1000 !important; // because of google drive picker , google drive picker z-index: 1001
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.photos-modal-content {
    width: 36.3125rem;
    max-width: 36.3125rem;
    height: 100%;
    background-color: white;
    border-radius: 0.325rem;
    padding-left: 1.875rem;
}

.media-modal--social-media {
    margin-bottom: 2rem;
    background-color: #ffffff;
    width: 100%;
    max-width: 49.6375rem;
    border-radius: 0.3125rem;
    z-index: 999999;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);

    @include x-sm {
        margin-top: 0;
    }
    @include md-x {
        margin-top: 16.2063rem;
    }
}
