@import '../global-variables.scss';

/*-------------------------------------------------------------
  Date/time picker
-------------------------------------------------------------*/

#calendar-widget {
    padding: 10px;
}

.date-picker-modal-content {
    max-width: 22.5625rem;
    max-height: 28rem;
    width: 100%;
    background-color: white;
    border-radius: 0.325rem;
    padding: 1.875rem;
}

.MuiPaper-root {
    display: flex;
    justify-content: center;
    align-items: center;
}

.entry-date .MuiInputBase-input {
    width: 100% !important;
    border-bottom: 0.125rem solid rgb(213, 213, 213);
    cursor: pointer;
}

.MuiSelect-icon,
.MuiInput-underline:before,
.MuiInput-underline:after {
    display: none !important;
}

select::-ms-expand {
    display: none;
}
.dp-nav-table {
    width: 100%;
}

.dp-row {
    display: flex;
}

/*--------------------------------
  Calendar header
--------------------------------*/

.dp-header {
    padding: 10px;

    .dp-row {
        justify-content: space-between;
    }
}

.dp-nav-view {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding-right: 12px;
    position: relative;
    cursor: pointer;
    transition: $trans1;

    &:after {
        content: '';
        background-image: url('/static/media/icon_caret-down.2e15136e.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        right: 0;
        transition: $trans1;
    }

    &:hover {
        color: var(--primary-color);
        transition: $trans2;

        &:after {
            filter: var(--filter-primary);
            transition: $trans2;
        }
    }
}

.dp-nav-cell {
    @extend .btn-icon;
}

/*--------------------------------
  Calendar body
--------------------------------*/

.dp-body {
    padding: 0 5px;

    .dp-cell {
        width: 100%;
        text-align: center;
    }
}

.dp-week-day-names {
    margin: 8px 0;
}

.dp-week-day-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
}

.dp-week,
.dp-year-view,
.dp-decade-view {
}

.dp-day,
.dp-month,
.dp-year {
    font-size: 13px;
    padding: 12px 4px;
    transition: $trans1;
    cursor: pointer;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);

    &:hover {
        background: var(--primary1);
        color: var(--primary-color);
        transition: $trans2;
    }
}

.dp-month {
    text-transform: uppercase;
}

.dp-prev,
.dp-next {
    color: $black4;
}

.dp-current {
    background: var(--primary-color) !important;
    color: white !important;
    cursor: default !important;
}

/*--------------------------------
  Calendar footer
--------------------------------*/

.dp-footer {
    display: flex;
    justify-content: center;
}

.dp-today {
    font-size: 13px;
    transition: $trans1;
    cursor: pointer;
    padding: 6px;
    margin-top: 8px;

    &:hover {
        color: var(--primary-color);
        transition: $trans2;
    }
}
