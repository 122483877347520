#social-image-upload-dialog {
    & .paragraph {
        text-align: center;
    }

    & .modal__body {
        height: 32rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.importing-img-content {
    margin-top: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 34.375rem;
    height: 19.625rem;
    border: 0.0625rem solid #3e3e3e;
    border-radius: 3.125rem;
    background: linear-gradient(241deg, #3dc0f0 0%, #701dd2 100%);

    & .icon-jrnl-logo {
        margin-bottom: 1.25rem;
        font-size: 4.375rem;
        color: #ffffff;
    }
}

.importing-img-modal {
    /* display: none;  Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    border-radius: 0.325rem;
}

.importing-img-blurb {
    font-size: 0.875rem;
    color: #ffffff;
}
