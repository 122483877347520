@import '../global-variables.scss';

//-------------------------------------------------------------
//  Hero
//-------------------------------------------------------------

#subpage {
    .page-header {
        h1 {
            font-size: 2em;
            font-weight: 600;
            margin: 0.8em 0;
        }
    }
}
