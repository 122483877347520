@import '../global-variables.scss';

/*-------------------------------------------------------------
  Send comment modal
-------------------------------------------------------------*/

.send-comment-modal {
  display: none;
  /*  Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  border-radius: 0.325rem;
}

.send-comment-content {
  margin-top: 20%;
  max-width: 25rem;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0.9375rem 0.9375rem 0 rgba(0, 0, 0, 0.09);
  background-color: #fffdff;
  padding-left: 0;
  padding-right: 0;
}

.send-comment-content-title {
  font-size: 1.8125rem;
  font-weight: bold;
  padding-top: 1.7813rem;
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.send-comment-content-contacts {
  margin-top: 1.5625rem;
}

.send-comment-content-contacts-title {
  padding-left: 1.5rem;
  font-size: 0.9375rem;
  font-weight: normal;
  opacity: 0.8;
  margin-bottom: 0;
  padding-bottom: 0.75rem;
  border-bottom: $border-line2;
}

.send-comment-content-list > li {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1875rem;
  font-weight: normal;
  border-bottom: $border-line2;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.send-comment-content-list > li > img {
  width: 2.625rem;
  height: 2.625rem;
  margin-left: 1.25rem;
}

.send-comment-content-list > li > span {
  color: #d9d9d9;
  margin-right: 1.5625rem;
}

.comment-contact-name {
  padding-left: 1.125rem;
  margin-bottom: 0;
  line-height: 1;
  max-width: 18rem;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.7;
}

.deleted-user-display {
  display: flex !important;
}

.selected-contact {
  color: var(--primary-color) !important;
}

.send-comment-content-actions {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.send-comment-content-actions > .btn-cancel {
  margin-top: 0.6875rem;
  width: 20.6812rem;
  height: 3.0625rem;
  border-radius: 0.3125rem;
  border: solid 0.0625rem #dadada;
  background-color: #fffdff;
  font-size: 1.0625rem;
  font-weight: 500;
  color: var(--primary-color);
}