@import '../global-variables.scss';

/*-------------------------------------------------------------
  Clearfixes
-------------------------------------------------------------*/

.img-section .form-body .textfield-label.filled {
    bottom: 26px;
    left: 10px;
    font-size: 10px;
}

.img-section .form-body .error-box {
    background-color: #ffdbdb;
    padding: 8px 12px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.display-inline {
    display: inline;
}

.width-fix {
    width: 0;
}

ul {
    list-style-type: none;
    margin-bottom: 0;
}

li {
    text-decoration: none;
}

label {
    margin-bottom: 0;
    padding: 0.5rem;
}

.logo-img-app {
    width: 23.4375rem;
}

.nav-item-app > a {
    color: #a5a5a5;
    font-size: 1.0625rem;
    text-decoration: none !important;
    line-height: 1;
}

.nav-item-app > a > span::before {
    color: #a5a5a5;
    font-size: 19px;
}

.nav-item-app:hover {
    color: var(--primary-color);
    border-bottom: 0.1875rem solid var(--primary-color);
}

.nav-item-app:hover > a,
.nav-item-app:hover > a > span::before,
.nav-item-app.active > a > span::before {
    color: var(--primary-color);
}

.nav-item-app {
    height: 100%;
    border-bottom: 0.1875rem solid transparent;
    width: 7.5rem;
    padding-bottom: 0.5813rem;
}

.nav-item-app.active {
    color: #0064ff;
    border-bottom: 0.1875rem solid #0064ff;
    font-size: 1.0625rem;
    font-weight: 500;
}

.nav-item-app.active > a > span::before,
.nav-item-app.active > a {
    color: inherit;
}

.nav-item-app > a:hover,
.nav-item-app > a:hover::before {
    color: inherit;
}

a:hover {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

.nav-link-app {
    font-size: 1.5rem;
}

.svg-c-blue {
    filter: invert(25%) sepia(56%) saturate(4310%) hue-rotate(211deg)
        brightness(100%) contrast(109%);
}

.nav-link-app > span {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.5rem;
}

.fa-plus-circle {
    color: white;
}

.list-inline-item.push-icon {
    margin-right: 0.9rem;
}

.fa-bell {
    color: rgb(167, 167, 167);
    font-size: 1.75rem;
}
