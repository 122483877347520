@import '../global-variables.scss';

/*-------------------------------------------------------------
  Reminders
-------------------------------------------------------------*/

.enable-reminders-section {
    margin: 0 0 24px;
}

.reminder-list__email {
    display: block;
}
.reminder_journal_list ul {
    max-height: 400px;
}
