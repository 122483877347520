@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?17tz1q');
    src: url('fonts/icomoon.eot?17tz1q#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?17tz1q') format('truetype'),
        url('fonts/icomoon.woff?17tz1q') format('woff'),
        url('fonts/icomoon.svg?17tz1q#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-unlink:before {
    content: '\e98e';
}
.icon-emojii:before {
    content: '\e98f';
}
.icon-format-bold:before {
    content: '\e990';
}
.icon-format-italic:before {
    content: '\e991';
}
.icon-format-underline:before {
    content: '\e992';
}
.icon-indent-less:before {
    content: '\e993';
}
.icon-indent-more:before {
    content: '\e994';
}
.icon-list-bullets:before {
    content: '\e995';
}
.icon-list-numbered:before {
    content: '\e996';
}
.icon-quote:before {
    content: '\e997';
}
.icon-jrnl-logo:before {
    content: '\e98b';
}
.icon-color:before {
    content: '\e987';
    color: #0064ff;
}
.icon-print:before {
    content: '\e988';
    color: #0064ff;
}
.icon-sharing:before {
    content: '\e989';
    color: #0064ff;
}
.icon-dollars:before {
    content: '\e98a';
    color: #0064ff;
}
.icon-pages:before {
    content: '\e98c';
    color: #0064ff;
}
.icon-cover:before {
    content: '\e98d';
    color: #0064ff;
}
.icon-calendar-2:before {
    content: '\e985';
    color: #0365ff;
}
.icon-settings:before {
    content: '\e986';
}
.icon-arrow-long-left:before {
    content: '\e93a';
    color: #1164fb;
}
.icon-arrow-long-right:before {
    content: '\e982';
    color: #1164fb;
}
.icon-rotate-left:before {
    content: '\e983';
    color: #1164fb;
}
.icon-rotate-right:before {
    content: '\e984';
    color: #1164fb;
}
.icon-manage-entries:before {
    content: '\e90a';
    color: #0064ff;
}
.icon-image:before {
    content: '\e910';
    color: #0064ff;
}
.icon-share-group:before {
    content: '\e922';
    color: #0064ff;
}
.icon-trash:before {
    content: '\e92a';
    color: #ff7f7f;
}
.icon-check-circle-filled:before {
    content: '\e932';
    color: #1164fb;
}
.icon-check-circle:before {
    content: '\e93b';
    color: #0064ff;
}
.icon-new-entry:before {
    content: '\e90c';
    color: #fff;
}
.icon-move:before {
    content: '\e900';
    color: #1164fb;
}
.icon-closed-eye:before {
    content: '\e901';
    color: #1164fb;
}
.icon-notifications-active .path1:before {
    content: '\e902';
    color: rgb(255, 255, 255);
}
.icon-notifications-active .path2:before {
    content: '\e908';
    margin-left: -1.0185546875em;
    color: rgb(255, 0, 0);
}
.icon-books:before {
    content: '\e90b';
    color: #a5a5a5;
}
.icon-comments:before {
    content: '\e91e';
    color: #b3b3b3;
}
.icon-contacts-nav:before {
    content: '\e920';
    color: #a5a5a5;
}
.icon-journals:before {
    content: '\e921';
    color: #a5a5a5;
}
.icon-bars:before {
    content: '\e923';
    color: #a5a5a5;
}
.icon-nav-search:before {
    content: '\e97a';
    color: #a5a5a5;
}
.icon-timeline:before {
    content: '\e97b';
    color: #0064ff;
}
.icon-notifications:before {
    content: '\e97c';
    color: #a5a5a5;
}
.icon-memories:before {
    content: '\e97d';
    color: #a5a5a5;
}
.icon-media:before {
    content: '\e97e';
    color: #a5a5a5;
}
.icon-add-circle-filled:before {
    content: '\e97f';
    color: #0064ff;
}
.icon-check-circle-borders:before {
    content: '\e980';
    color: #0365ff;
}
.icon-alerts:before {
    content: '\e981';
    color: #0365ff;
}
.icon-pencil:before {
    content: '\e914';
    color: #fff;
}
.icon-invitations:before {
    content: '\e96a';
}
.icon-knowledgebase:before {
    content: '\e96b';
}
.icon-contact-support:before {
    content: '\e96c';
}
.icon-jrnl-blog:before {
    content: '\e96d';
}
.icon-exit:before {
    content: '\e96e';
}
.icon-ellipsis:before {
    content: '\e966';
    color: #0064ff;
}
.icon-draft:before {
    content: '\e969';
    color: #1164fb;
}
.icon-reminders:before {
    content: '\e96f';
}
.icon-account:before {
    content: '\e970';
}
.icon-billing:before {
    content: '\e971';
}
.icon-email-to-journal:before {
    content: '\e972';
}
.icon-jrnl-guard:before {
    content: '\e973';
}
.icon-contacts:before {
    content: '\e974';
}
.icon-notifications-settings:before {
    content: '\e975';
}
.icon-profile:before {
    content: '\e976';
}
.icon-security:before {
    content: '\e977';
}
.icon-social-connect:before {
    content: '\e978';
}
.icon-themes:before {
    content: '\e979';
}
.icon-location:before {
    content: '\e968';
    color: #1164fb;
}
.icon-error:before {
    content: '\e967';
    color: #d84315;
}
.icon-arrow-right-small:before {
    content: '\e965';
    color: #1164fb;
}
.icon-checkbox-red:before {
    content: '\e964';
    color: #f96156;
}
.icon-trash2:before {
    content: '\e963';
    color: #ed4c5c;
}
.icon-organization:before {
    content: '\e962';
    color: #07286f;
}
.icon-calendar:before {
    content: '\e95a';
    color: #f96156;
}
.icon-cloud-upload:before {
    content: '\e95b';
    color: #fff;
}
.icon-pdf-file:before {
    content: '\e95c';
    color: #ff2c2c;
}
.icon-new-member:before {
    content: '\e95d';
    color: #fff;
}
.icon-payments:before {
    content: '\e95e';
    color: #fff;
}
.icon-new-hires:before {
    content: '\e95f';
    color: #fff;
}
.icon-team:before {
    content: '\e960';
    color: #fff;
}
.icon-dashboard:before {
    content: '\e961';
    color: #f96156;
}
.icon-fat-arrow-right:before {
    content: '\e959';
    color: #1952f5;
}
.icon-google-drive-blue:before {
    content: '\e958';
    color: #1852f6;
}
.icon-hire-check:before {
    content: '\e957';
    color: #f96156;
}
.icon-global:before {
    content: '\e954';
    color: #f96156;
}
.icon-local:before {
    content: '\e955';
    color: #f96156;
}
.icon-hire:before {
    content: '\e956';
    color: #f96156;
}
.icon-long-arrow-right:before {
    content: '\e953';
}
.icon-job-seeker:before {
    content: '\e951';
    color: #6b7681;
}
.icon-employer:before {
    content: '\e952';
    color: #6b7681;
}
.icon-info:before {
    content: '\e950';
    color: #f96156;
}
.icon-linkedin .path1:before {
    content: '\e94a';
    color: rgb(255, 255, 255);
    opacity: 0.8;
}
.icon-linkedin .path2:before {
    content: '\e94b';
    margin-left: -1em;
    color: rgb(97, 97, 97);
}
.icon-facebook .path1:before {
    content: '\e94c';
    color: rgb(255, 255, 255);
    opacity: 0.8;
}
.icon-facebook .path2:before {
    content: '\e94d';
    margin-left: -1em;
    color: rgb(97, 97, 97);
}
.icon-twitter .path1:before {
    content: '\e94e';
    color: rgb(255, 255, 255);
    opacity: 0.8;
}
.icon-twitter .path2:before {
    content: '\e94f';
    margin-left: -1em;
    color: rgb(97, 97, 97);
}
.icon-valid:before {
    content: '\e948';
    color: #0064ff;
}
.icon-not-valid:before {
    content: '\e949';
    color: #ff8080;
}
.icon-pdf:before {
    content: '\e946';
    color: #1cb88c;
}
.icon-folder:before {
    content: '\e947';
    color: #1cb88c;
}
.icon-filter-nav:before {
    content: '\e945';
    color: #1164fb;
}
.icon-ellipsis-h:before {
    content: '\e944';
}
.icon-reply:before {
    content: '\e943';
    color: #1164fb;
}
.icon-comment-reorder:before {
    content: '\e942';
}
.icon-download:before {
    content: '\e941';
    color: #fff;
}
.icon-data:before {
    content: '\e940';
    color: #fff;
}
.icon-import-export:before {
    content: '\e93f';
    color: #1cb88c;
}
.icon-star-circle:before {
    content: '\e93e';
    color: #05416a;
}
.icon-memories-replay:before {
    content: '\e93c';
    color: #05416a;
}
.icon-map:before {
    content: '\e93d';
    color: #05416a;
}
.icon-open:before {
    content: '\e939';
    color: #05416a;
}
.icon-audiowave:before {
    content: '\e938';
    color: #1db5cc;
}
.icon-media-fullscreen:before {
    content: '\e937';
}
.icon-media-img:before {
    content: '\e933';
    color: #05416b;
}
.icon-media-camera:before {
    content: '\e935';
    color: #05416b;
}
.icon-media-upload:before {
    content: '\e936';
    color: #05416b;
}
.icon-media-calendar:before {
    content: '\e934';
    color: #05416b;
}
.icon-edit:before {
    content: '\e931';
    color: #05416a;
}
.icon-shared-alt-arrow:before {
    content: '\e930';
    color: #1cb88c;
}
.icon-map-marker-text:before {
    content: '\e92e';
    color: #1cb88c;
}
.icon-facebook-text:before {
    content: '\e92d';
}
.icon-font-text:before {
    content: '\e92b';
    color: #1cb88c;
}
.icon-attachment-text:before {
    content: '\e92c';
    color: #1cb88c;
}
.icon-instagram-text:before {
    content: '\e92f';
    color: #1cb88c;
}
.icon-mail-blank:before {
    content: '\e928';
    color: #00426b;
}
.icon-mobile:before {
    content: '\e929';
    color: #00426b;
}
.icon-green-caret-down:before {
    content: '\e927';
    color: #2fc69a;
}
.icon-zoom-out:before {
    content: '\e925';
}
.icon-zoom-in:before {
    content: '\e926';
}
.icon-fullscreen-capture:before {
    content: '\e915';
    color: #fff;
}
.icon-text-capture:before {
    content: '\e918';
    color: #fff;
}
.icon-logout:before {
    content: '\e91a';
    color: #064269;
}
.icon-cropped-capture:before {
    content: '\e91b';
    color: #fff;
}
.icon-cart-arrow-down:before {
    content: '\e90d';
}
.icon-share-arrow:before {
    content: '\e912';
}
.icon-emoticon:before {
    content: '\e911';
}
.icon-arrow-alt-circle:before {
    content: '\e90e';
    color: #fff;
}
.icon-arrow-alt-circle-right:before {
    content: '\e90f';
    color: #00416b;
}
.icon-close:before {
    content: '\e909';
    color: #fffdff;
}
.icon-shared:before {
    content: '\e907';
    color: #00b88c;
}
.icon-arrow-left:before {
    content: '\e906';
    color: #05416b;
}
.icon-eye:before {
    content: '\e904';
    color: #1cb88c;
}
.icon-arrow-right:before {
    content: '\e905';
    color: #05416b;
}
.icon-feather-book:before {
    content: '\e913';
}
.icon-tags:before {
    content: '\e903';
}
.icon-volume-off:before {
    content: '\f026';
}
.icon-volume-down:before {
    content: '\f027';
}
.icon-volume-up:before {
    content: '\f028';
}
.icon-map-marker:before {
    content: '\f041';
}
.icon-play:before {
    content: '\f04b';
}
.icon-pause:before {
    content: '\f04c';
}
.icon-expand:before {
    content: '\f065';
}
.icon-compress:before {
    content: '\f066';
}
.icon-list-ul:before {
    content: '\f0ca';
}
.icon-list-ol:before {
    content: '\f0cb';
}
.icon-envelope:before {
    content: '\f0e0';
}
.icon-location-arrow:before {
    content: '\f124';
}
.icon-share-alt:before {
    content: '\f1e0';
}
.icon-file-upload:before {
    content: '\f574';
}
.icon-volume-mute:before {
    content: '\f6a9';
}
.icon-circle:before {
    content: '\f111';
}
.icon-file:before {
    content: '\f15b';
}
.icon-google-drive:before {
    content: '\f3aa';
}
.icon-credit-card:before {
    content: '\f09d';
}
.icon-exclamation-circle:before {
    content: '\f06a';
}
.icon-question-circle:before {
    content: '\f059';
}
.icon-info-circle:before {
    content: '\f05b';
}
.icon-upload:before {
    content: '\f093';
}
.icon-caret-down:before {
    content: '\f0d7';
}
.icon-caret-up:before {
    content: '\f0d8';
}
.icon-circle1:before {
    content: '\f112';
}
.icon-clone:before {
    content: '\f24d';
}
.icon-arrow-up:before {
    content: '\f062';
}
.icon-briefcase:before {
    content: '\f0b1';
}
.icon-envelope1:before {
    content: '\f0e1';
}
.icon-user-circle:before {
    content: '\f2bd';
}
.icon-file1:before {
    content: '\f15c';
}
.icon-linkedin-in:before {
    content: '\f0e2';
}
.icon-arrow-to-top:before {
    content: '\f341';
}
.icon-link:before {
    content: '\f0c1';
}
.icon-long-arrow-up:before {
    content: '\f176';
}
.icon-times-circle:before {
    content: '\f057';
}
.icon-help:before {
    content: '\e916';
}
.icon-library:before {
    content: '\e917';
}
.icon-user:before {
    content: '\e919';
}
.icon-clock:before {
    content: '\e91c';
}
.icon-mail:before {
    content: '\e91d';
}
.icon-brush:before {
    content: '\e91f';
}
.icon-mailbox:before {
    content: '\e924';
}
.icon-compress-alt:before {
    content: '\f422';
}
.icon-search:before {
    content: '\f002';
}
.icon-times-light:before {
    content: '\f00f';
}
.icon-chevron-down:before {
    content: '\f078';
}
.icon-question-circle1:before {
    content: '\f05a';
}
.icon-chevron-left:before {
    content: '\f053';
}
.icon-chevron-right:before {
    content: '\f054';
}
.icon-file2:before {
    content: '\f15d';
}
.icon-filter:before {
    content: '\f0b0';
}
