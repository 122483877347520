@import '../global-variables.scss';

/*-------------------------------------------------------------
  iOS install banner
-------------------------------------------------------------*/

.app-banner-frame {
    width: 100%;
    position: relative;
    box-shadow: 0 0 5px #0005;
    margin: -8px 0 0 -16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #f7f7f7 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #f7f7f7 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 );
}

.app-reviews-stars {
    width: 70px;
    height: auto;
}

/* MOBILE BANNER STYLING */
.navbar-toggler-mobile-banner {
    color: black !important;
    border: none !important;
}

/* IOS INSTALL */
.ios-banner-install {
    @extend .app-banner-frame;
}

.ios-app-store {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ios-app-desc {
    img {
        @extend .app-reviews-stars;
    }
}

.ios-close {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #0006;
}

.ios-app-logo {
    margin-right: 0.75rem;

    img {
        width: 100%;
        height: auto;
        max-width: 60px;
        box-shadow: 0 1px 2px #0002;
        border-radius: 16px;
    }
}

.ios-app-title {
    font-size: 0.8rem;
    margin-bottom: 0;
    font-weight: 700;
}

.ios-app-subtitle {
    font-size: 0.625rem;
    color: rgb(50, 50, 50);
    margin: 0;
    display: none;
}

.ios-app-msg {
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-size: 0.625rem;
    color: rgb(50, 50, 50);
}

.ios-link {
    font-size: 1rem;
    color: rgb(0, 128, 220);
    margin-right: 0.5em;
}

/* ANDROID INSTALL */
.android-banner-install {
    @extend .app-banner-frame;
    display: none;
}

.android-app-desc {
    flex-grow: 1;

    img {
        @extend .app-reviews-stars;
    }
}

.android-app-logo {
    margin-right: 0.75rem;

    img {
        width: 100%;
        height: auto;
        max-width: 60px;
        box-shadow: 0 1px 2px #0002;
        border-radius: 4px;
    }
}

.android-app-title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
}

.btn-get-app {
    padding: 0;
    margin-right: 0.5em;

    img {
        width: auto;
        height: 35px;
        display: block;
    }
}

.android-close {
    font-size: 1.5rem;
    position: relative;
    top: -3px;
    color: #0006;
    margin-right: 0.75rem;
}

.intro-heading-banner {
    background-color: rgba(255, 255, 255, 0.851);
    border-radius: 0.9375rem;
    text-align: center;
}

.intro-heading-banner > h3 {
    color: rgb(0, 65, 106);
    font-size: 1.375rem;
    margin-bottom: 1rem;
}

.intro-heading-banner > p {
    color: rgb(0, 0, 0);
    font-size: 1.125rem;
}

.app-plan-free,
.app-plan-pro {
    background-color: white;
    border-radius: 0.9375rem;
}

.app-plan-heading {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.625rem;
    font-weight: 600;
    color: rgb(0, 65, 106);
    text-transform: uppercase;
    width: 100%;
}

.app-plan-subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-plan-desc {
    font-size: 0.875rem;
    width: 50%;
}

.app-plan-price-desc {
    color: rgb(0, 65, 106);
    font-size: 0.6875rem;
    font-style: italic;
    width: 25%;
}

.app-plan-desc-fix {
    font-weight: 600;
    font-size: 0.875rem;
}

.app-plan-free-features-title {
    font-size: 0.875rem;
    color: black;
}

.app-plan-pro-features-title {
    font-size: 0.875rem;
    color: black;
    font-weight: 600;
}

.app-plan-features-list {
    font-size: 0.875rem;
    color: black;
}

.app-plan-features-list > li {
    padding-bottom: 1rem;
}

.app-plan-features-list > li::before {
    content: '\2022';
    color: rgb(0, 65, 106);
    font-weight: bold;
    display: inline-block;
    width: 1em;
}

.btn-try {
    background-color: var(--primary-color);
    color: white;
    font-size: 1.3125rem !important;
    border-radius: 0.4375rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn-upgrade {
    border-radius: 0.4375rem;
    border-color: var(--primary-color);
    color: black;
    font-size: 1.3125rem !important;
    border-radius: 0.4375rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn-upgrade:hover,
.btn-upgrade:focus {
    color: black;
}
