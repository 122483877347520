@import '../global-variables.scss';

/*-------------------------------------------------------------
  Search bar
-------------------------------------------------------------*/

.search-bar {
    display: flex;
    align-items: center;
    padding: 16px;

    .search-bar__btn,
    .search-bar__close {
        flex: none;
        line-height: 0;
    }

    .search-bar__btn {
        margin-right: 8px;
    }

    .search-bar__input {
        flex: auto;
    }

    .search-bar__input::placeholder {
        color: $black4;
    }
    .search-bar__input::-moz-placeholder {
        color: $black4;
    }
    .search-bar__input:-moz-placeholder {
        color: $black4;
    }
    .search-bar__input::-webkit-input-placeholder {
        color: $black4;
    }
    .search-bar__input:-ms-input-placeholder {
        color: $black4;
    }

    .search-bar__close {
    }
}
